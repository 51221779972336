var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"is-submit-btn":false,"full-screen":true}},[_c('template',{slot:"header"},[(_vm.item)?_c('span',{staticStyle:{"font-size":"30px","font-weight":"500","color":"#000000"}},[_vm._v("Job: "+_vm._s(_vm.item.wo_number)+" - "+_vm._s(_vm.item.project)+"; "+_vm._s(_vm.item.project_floor))]):_vm._e(),_c('v-col',{staticClass:"text-end"},[_c('span',{staticStyle:{"font-size":"30px","font-weight":"500","color":"#000000"}},[_vm._v("Total Items: "+_vm._s(_vm.items.length))])])],1),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":-1,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.cut_height",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.cut_height ? item.cut_height : '-')+" ")])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.location ? item.location.name : '')+" ")])]}},{key:"item.inventory_location",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.inventory_location ? item.inventory_location.name : '')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item && item.status)?_c('v-chip',_vm._g({attrs:{"small":"","color":item.status.color ? item.status.color : '#000000',"text-color":"white"},on:{"mouseover":function($event){return _vm.getHistory(item)}}},on),[_vm._v(" "+_vm._s(item.status.name)+" ")]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"pt-3 d-flex",staticStyle:{"width":"400px","flex-direction":"column","gap":"12px"}},_vm._l((_vm.finishedGoodHistories),function(history){return _c('div',{key:history.id,staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"width":"50%"}},[(history && history.status)?_c('v-chip',{attrs:{"small":"","color":history.status.color ? history.status.color : '#000000',"text-color":"white"}},[_vm._v(" "+_vm._s(history.status.name)+" ")]):_vm._e()],1),_c('span',[_vm._v(" At: "+_vm._s(history.created_at))])])}),0)])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [(item.notes && item.notes.length > 0)?_c('span',{staticStyle:{"white-space":"nowrap","color":"rgb(255, 152, 0)"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openNoteForm(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1),_c('span',[_vm._v(_vm._s(item.notes && item.notes.length > 0 ? item.notes[0].text : ''))])],1):_c('v-btn',{attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.openNoteForm(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)})],1)],1)],1),_c('NoteForm',{ref:"noteForm",attrs:{"simple":true},on:{"success":function($event){return _vm.get(_vm.item, _vm.statusId)}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }