<template>
  <v-container
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="5"
      >
        <v-dialog
          v-model="filterDialog"
          width="500"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              large
              block
              color="success"
              outlined
              dark
              v-bind="attrs"
              v-on="on"
            >
              FILTERS <span>{{ activeFilterCount }}</span>
            </v-btn>
          </template>

          <v-card
            class="pa-5"
            style="border-radius: 7px;"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <span style="font-size: 22px !important; font-weight: bold;">Filters</span>
                  </v-col>
                  <v-col class="text-end pr-1">
                    <v-btn
                      icon
                      :style="{ color: isDarkMode ? '' : '#000000' }"
                      @click="filterDialog = !filterDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="filterLeaveTypeId"
                      label="Leave Type"
                      :items="leaveTypes"
                      hide-details="true"
                      item-value="id"
                      item-text="name"
                      clearable
                    >
                      <template #item="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item ? item.color : ''"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                      <template #selection="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="filterStatusId"
                      label="Status"
                      :items="statuses"
                      hide-details="true"
                      item-value="id"
                      item-text="name"
                      clearable
                      :disabled="disableFilterStatus"
                    >
                      <template #item="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item ? item.color : ''"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                      <template #selection="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      @click="filterDialog = !filterDialog"
                    >
                      <span style="font-size: 14px !important;">Close</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col
        cols="7"
      >
        <v-btn
          color="primary"
          large
          block
          @click="openRequestForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Request
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
    >
      <template v-if="timeOffs && timeOffs.length > 0">
        <v-col
          v-for="timeOff in timeOffs"
          :key="timeOff.id"
          cols="12"
        >
          <v-card>
            <v-card-text class="pb-0">
              <v-row>
                <v-col
                  cols="4"
                  class="d-flex justify-center align-center text-center pb-0"
                >
                  <span class="text-subtitle-1 font-weight-bold">
                    Days Requested
                  </span>
                </v-col>
                <template v-if="isHalfDay(timeOff)">
                  <v-col
                    cols="8"
                    class="d-flex flex-column justify-center align-center text-center pb-0"
                  >
                    <v-row
                      align="end"
                      justify="center"
                    >
                      <span class="text-subtitle-1 font-weight-bold">
                        <text-highlight :queries="search">
                          Date: {{ timeOff.start_date }}
                        </text-highlight>
                      </span>
                    </v-row>
                    <v-row
                      justify="center"
                      align="center"
                      class="mt-0"
                    >
                      <v-col
                        cols="auto"
                        class="d-flex justify-center align-center text-center"
                      >
                        <span class="text-subtitle-1 font-weight-bold">
                          Start Time
                        </span>
                      </v-col>
                      <v-col
                        cols="auto"
                        class="d-flex justify-center align-center text-center"
                      >
                        <span class="text-subtitle-1 font-weight-bold">
                          End Time
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <template v-else>
                  <v-col
                    cols="4"
                    class="d-flex justify-center align-center text-center pb-0"
                  >
                    <span class="text-subtitle-1 font-weight-bold">
                      Start On
                    </span>
                  </v-col>
                  <v-col
                    cols="4"
                    class="d-flex justify-center align-center text-center pb-0"
                  >
                    <span class="text-subtitle-1 font-weight-bold">
                      Back On
                    </span>
                  </v-col>
                </template>
              </v-row>
              <v-row>
                <v-col
                  cols="4"
                  class="d-flex justify-center align-center text-center"
                >
                  <span
                    id="days-requested"
                    :style="isSingleDigit(timeOff.days_requested) ? 'font-size: 7rem' : 'font-size: 5rem'"
                  >
                    <text-highlight :queries="search">
                      {{ timeOff.days_requested }}
                    </text-highlight>
                  </span>
                </v-col>
                <v-col
                  cols="8"
                >
                  <v-row
                    v-if="isHalfDay(timeOff)"
                    justify="center"
                    align="center"
                  >
                    <div
                      style="background-color: #EDEDED; border-radius: 10px; min-width: 90%"
                      class="d-flex justify-center align-center"
                    >
                      <v-col
                        cols="6"
                        class="d-flex justify-center align-center text-center"
                        style="position: relative"
                      >
                        <span class="text-subtitle-1">
                          <text-highlight :queries="search">
                            {{ timeFormatter(timeOff.start_time) }}
                          </text-highlight>
                        </span>
                        <v-icon
                          style="position: absolute; right: -13px;"
                        >
                          mdi-arrow-right
                        </v-icon>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex justify-center align-center text-center"
                      >
                        <span class="text-subtitle-1">
                          <text-highlight :queries="search">
                            {{ timeFormatter(timeOff.end_time) }}
                          </text-highlight>
                        </span>
                      </v-col>
                    </div>
                  </v-row>
                  <v-row
                    v-else
                    justify="center"
                    align="center"
                  >
                    <div
                      style="background-color: #EDEDED; border-radius: 10px; min-width: 90%;"
                      class="d-flex justify-center align-center"
                    >
                      <v-col
                        cols="6"
                        class="d-flex justify-center align-center text-center"
                        style="position: relative"
                      >
                        <span class="text-subtitle-1">
                          <text-highlight :queries="search">
                            {{ timeOff.start_date }}
                          </text-highlight>
                        </span>
                        <v-icon
                          style="position: absolute; right: -13px;"
                        >
                          mdi-arrow-right
                        </v-icon>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex justify-center align-center text-center"
                      >
                        <span class="text-subtitle-1">
                          <text-highlight :queries="search">
                            {{ timeOff.end_date }}
                          </text-highlight>
                        </span>
                      </v-col>
                    </div>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="d-flex justify-center align-center pb-0"
                    >
                      <span class="font-weight-bold">
                        Req. Type
                      </span>
                    </v-col>
                    <v-col
                      cols="6"
                      class="d-flex justify-center align-center pb-0"
                    >
                      <span class="font-weight-bold">
                        Req. Status                      </span>
                    </v-col>
                    <v-col
                      cols="6"
                      class="d-flex justify-center align-center pa-0"
                    >
                      <v-tooltip
                        v-if="timeOff.status.id === statusIdReadyForSupervisor || timeOff.status.id === statusIdReadyForHr"
                        top
                        open-on-click
                      >
                        <template #activator="{ on }">
                          <v-chip
                            large
                            class="ma-2"
                            text-color="white"
                            style="cursor: pointer; height: 100%; justify-content: center; font-size: .6rem;'"
                            :color="timeOff.status ? timeOff.status.color : ''"
                            v-on="on"
                          >
                            SUBMITTED
                          </v-chip>
                        </template>
                        <span>
                          <text-highlight :queries="search">
                            {{ timeOff.status ? timeOff.status.name : '' }}
                          </text-highlight>
                        </span>
                      </v-tooltip>
                      <v-chip
                        v-else
                        large
                        class="ma-2"
                        text-color="white"
                        style="cursor: pointer; height: 100%; justify-content: center;"
                        :style="timeOff.status && (timeOff.status.id === statusIdSubmitted || timeOff.status.id === statusIdApproved || timeOff.status.id === statusIdCanceled || timeOff.status.id === statusIdCancelApproval) ? 'fontSize: .6rem' : ''"
                        :color="timeOff.status ? timeOff.status.color : ''"
                      >
                        <text-highlight :queries="search">
                          {{ timeOff.status ? timeOff.status.name : '' }}
                        </text-highlight>
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="6"
                      class="d-flex justify-center align-center pa-0"
                    >
                      <v-chip
                        large
                        class="ma-2"
                        text-color="white"
                        style="cursor: pointer; height: 100%; justify-content: center;"
                        :style="timeOff.leave_type && (timeOff.leave_type.id === leaveTypeIdBereavement) ? 'fontSize: .6rem' : ''"
                        :color="timeOff.leave_type ? timeOff.leave_type.color : ''"
                      >
                        <text-highlight :queries="search">
                          {{ timeOff.leave_type ? timeOff.leave_type.name : '' }}
                        </text-highlight>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mt-2">
              <v-col
                cols="4"
              >
                <v-btn
                  :disabled="isCancelDisabled(timeOff)"
                  block
                  color="error"
                  style="width: 100%;"
                  outlined
                  @click="cancelTimeOffRequest(timeOff)"
                >
                  CANCEL
                </v-btn>
              </v-col>
              <v-col
                cols="4"
              >
                <v-btn
                  block
                  color="warning"
                  style="width: 100%;"
                  outlined
                  @click="openNoteForm(timeOff)"
                >
                  NOTES
                </v-btn>
              </v-col>
              <v-col
                cols="4"
              >
                <v-btn
                  block
                  color="primary"
                  style="width: 100%;"
                  @click="viewTimeOffForm(timeOff)"
                >
                  VIEW
                </v-btn>
              </v-col>
            </v-card-actions>
            <v-card-text
              class="py-1"
            >
              <v-row
                justify="center"
              >
                <v-col
                  :cols="timeOff.approved_by_full_name ? '6' : 'auto'"
                  class="d-flex justify-center"
                >
                  Submitted On: {{ timeOff.submitted_at_short ? timeOff.submitted_at_short : '' }}
                </v-col>
                <v-col
                  v-if="timeOff.approved_by_full_name"
                  cols="6"
                  class="d-flex justify-center"
                >
                  Approved By: {{ timeOff.approved_by_full_name }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <template v-else-if="timeOffs && timeOffs.length === 0">
        <v-row
          justify="center"
          align="center"
        >
          <p>No time off requests found...</p>
        </v-row>
      </template>
    </v-row>
    <time-off-request-form
      ref="timeOffRequestForm"
      :view-form="viewForm"
      :approve-form="approveForm"
      @close-form="closeForm"
      @success="get"
    />
    <note-form ref="noteForm" />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import TimeOffRequestForm from '@/components/forms/timeOffs/TimeOffRequestForm';
import NoteForm from '@/components/forms/notes/NoteForm';

export default {
  components: {
    TimeOffRequestForm,
    NoteForm,
  },
  props: {

  },
  data: () => ({
    timeOffs: null,
    roles: [],
    users: [],
    leaveTypes: [],
    statuses: [],
    supervisees: [],

    timeOffUserOnly: false,
    userRoleIdAdmin: 3,
    userRoleIdHR: 25,
    userRoleIdTimeOffUser: 27,
    userRoleIdTimeOffSupervisor: 28,
    userRoleIdTimeOffAccountant: 30,
    total: 0,
    viewForm: false,
    approveForm: false,
    statusIdNew: 1,
    statusIdSubmitted: 2,
    statusIdApproved: 3,
    statusIdDenied: 4,
    statusIdCanceled: 5,
    statusIdCancelApproval: 6,
    statusIdReadyForSupervisor: 7,
    statusIdReadyForHr: 8,
    leaveTypeIdUnpaid: 1,
    leaveTypeIdPaid: 2,
    leaveTypeIdBereavement: 3,
    loading: true,
    filterDialog: false,
    filterUserId: null,
    filterLeaveTypeId: null,
    filterStatusId: null,
    disableFilterStatus: false,
    noteTypeIdTimeOff: 2,

  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
      search: (state) => state.search,
      timeOffState: (state) => state.timeOffState,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
    timeOffSupervisorUsersFilter() {
      const users = this.users.filter((user) => this.timeOffSupervisorUsers.includes(user.id));
      return users;
    },
    activeFilterCount() {
      let count = 0;
      if (this.filterLeaveTypeId !== null) count += 1;
      if (this.filterStatusId !== null) count += 1;
      if (count) {
        return `(${count})`;
      }
      return '';
    },
    isAdmin() {
      return this.roles.includes(this.userRoleIdAdmin);
    },
    isHr() {
      return this.roles.includes(this.userRoleIdHr);
    },
    isAccountant() {
      return this.roles.includes(this.userRoleIdTimeOffAccountant);
    },

    isTimeOffSupervisor() {
      return this.roles.includes(this.userRoleIdTimeOffSupervisor);
    },
  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
    filterLeaveTypeId(value) {
      this.storeState('filterLeaveTypeId', value);
      this.get();
    },
    filterStatusId(value) {
      this.storeState('filterStatusId', value);
      this.get();
    },
  },
  async created() {
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
    if (this.isTimeOffSupervisor) {
      const supervisees = this.user.supervisees.map((x) => x.id);
      supervisees.push(this.user.id);
      this.timeOffSupervisorUsers = supervisees;
    }
    await this.init();
    if (this.timeOffState) {
      if (this.timeOffState.filterUserId
        && (
          this.roles.includes(this.userRoleIdTimeOffSupervisor)
          || this.roles.includes(this.userRoleIdTimeOffAccountant)
          || this.roles.includes(this.userRoleIdHr)
          || this.roles.includes(this.userRoleIdAdmin))
      ) {
        this.filterUserId = this.timeOffState.filterUserId;
      }
      if (this.timeOffState.filterLeaveTypeId) {
        this.filterLeaveTypeId = this.timeOffState.filterLeaveTypeId;
      }
      if (this.timeOffState.filterStatusId) {
        this.filterStatusId = this.timeOffState.filterStatusId;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {

    init() {
      this.get();
      this.getUsers();
      this.getLeaveTypes();
      this.getStatuses();
    },

    async get() {
      this.$bus.$emit('loading');
      this.items = [];
      this.total = 0;

      const submitModel = {
        filters: {
          leave_type_id: this.filterLeaveTypeId,
          status_id: this.filterStatusId,
        },
        pagination: this.pagination,
        search: this.search,
      };

      const res = await this.$api.timeOffRequest.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError');
        this.timeOffs = [];
        this.total = 0;
        return;
      }
      this.$bus.$emit('loading-stop');
      this.timeOffs = res.data;
      this.total = res.total;
    },

    async getUsers() {
      const submitModel = {
        filters: {
          is_username: false,
        },
      };
      const res = await this.$api.user.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (res.length <= 0) {
        this.employees = [];
        return;
      }
      this.users = res;
    },

    async getLeaveTypes() {
      this.leaveTypes = await this.$api.timeOffLeaveType.get();
    },

    async getStatuses() {
      this.statuses = await this.$api.timeOffStatus.get();
    },

    openRequestForm() {
      this.viewForm = false;
      this.approveForm = false;
      this.$refs.timeOffRequestForm.open();
    },

    openEditForm(item) {
      this.$refs.timeOffRequestForm.open(item.id);
    },

    viewTimeOffForm(item) {
      this.viewForm = true;
      this.$refs.timeOffRequestForm.open(item.id);
    },

    approveTimeOffForm(item) {
      this.approveForm = true;
      this.$refs.timeOffRequestForm.open(item.id);
    },

    closeForm() {
      this.viewForm = false;
      this.approveForm = false;
    },

    async softDelete(data) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.timeOffRequest.softDelete(data.id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    openNoteForm(data) {
      this.$refs.noteForm.open(data.id, this.noteTypeIdTimeOff);
    },

    async cancelTimeOffRequest(data) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to cancel this record? Unapproved records will be deleted.',
      });
      if (!confirm) return;
      const res = await this.$api.timeOffRequest.get(`cancel/${data.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    isHalfDay(timeOffRequest) {
      return timeOffRequest.start_date === timeOffRequest.end_date && timeOffRequest.start_time && timeOffRequest.end_time;
    },

    timeFormatter(time) {
      const placeholder = '2000-01-01T';
      return time
        ? moment(placeholder + time).format('hh:mm a')
        : '';
    },

    endTimeFormatter(timeOffRequest) {
      const placeholder = '2000-01-01T';
      return timeOffRequest.end_time
        ? moment(placeholder + timeOffRequest.end_time).format('hh:mm a')
        : '';
    },

    isSingleDigit(number) {
      const numberString = number.toString();
      const numberWithoutDecimal = numberString.replace('.', '');
      return numberWithoutDecimal.length === 1;
    },

    isCancelDisabled(timeOff) {
      return timeOff.status_id === this.statusIdCancelApproval || timeOff.status_id === this.statusIdCanceled || timeOff.status_id === this.statusIdDenied;
    },

    storeState(property, value) {
      const state = this.timeOffState;
      state[property] = value;
      this.$store.commit('storeTimeOffState', state);
    },

  },
};
</script>

<style scoped>
/deep/#days-requested {
    /* font-size: 7rem !important; */
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif !important;
}
/deep/span.v-chip__content {
  max-width: none !important;
}
</style>
