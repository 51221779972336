var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-inst":false,"is-glass":false,"is-commercial":false,"is-vwd-glass":false,"is-panels":false,"is-sid":false,"is-equipment":false,"is-patio-door":false},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-col',{staticClass:"text-end mt-4 pb-0"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Limit / Caution / Danger Level ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.inventory_class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-0 mb-0",attrs:{"small":"","color":item.item && item.item.classes ? item.item.classes.color : 'white',"text-color":"white"}},[_vm._v(" "+_vm._s(item.item && item.item.classes ? item.item.classes.name : '')+" ")])]}},{key:"item.item_full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.item_full_name)+" ")])]}},{key:"item.location_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.location_name)+" ")])]}},{key:"item.length_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.length_name)+" ")])]}},{key:"item.color_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.color_code ? item.color_code : item.color_name)+" ")])]}},{key:"item.uom_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.uom_name)+" ")])]}},{key:"item.material_property_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.material_property_name)+" ")])]}},{key:"item.finishing_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.finishing_name)+" ")])]}},{key:"item.manufacturer_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.manufacturer_name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEditForm(item.id)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('inventory-caution-danger-level-form',{ref:"form",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }