<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :secondary-submit="submitUndo"
    :submit-btn-text="item.status_id === statusIdNew ? 'Move to CUTTING' : 'Move to TEMPERING'"
    :is-submit-btn="item.status_id === statusIdNew || item.status_id === statusIdCutting"
    :is-secondary-btn="item.status_id != statusIdNew && item.status_id != statusIdProduced && item.status_id != statusIdShipped && item.status_id != statusIdReceived && item.status_id != statusIdShippedToSite && item.status_id != statusIdReceviedOnSite && item.status_id != statusIdPShippedToSite && item.status_id != statusIdPReceviedOnSite"
    :secondary-btn-text="item.status_id == statusIdCutting ? 'Undo CUTTING' : 'UNDO TEMPERING'"
    :max-width="700"
  >
    <template slot="header">
      <div
        style="width: 100%;"
        class="d-flex justify-space-between align-center pa-3"
      >
        <span
          v-if="item"
          class="f6 fw600"
        >Batch# - {{ item.batch_number }}</span>
        <div
          class="d-flex"
          style="gap: 6px;"
        >
          <v-chip
            :color="item && item.class ? item.class.color : 'black'"
            text-color="white"
          >
            {{ item && item.class ? item.class.name : '' }}
          </v-chip>
          <v-chip
            :color="item && item.status ? item.status.color : 'black'"
            text-color="white"
          >
            {{ item && item.status ? item.status.name : '' }}
          </v-chip>
        </div>
      </div>
    </template>
    <template slot="body">
      <div
        v-if="item"
        class="d-flex flex-column ga-6 pa-3 pb-6 pt-0"
      >
        <div class="d-flex flex-column ga-1">
          <span class="title-span">Job</span>
          <div class="desc-div">
            <span
              class="desc-span"
              style="font-weight: bold;"
            >{{ item.job_code ? `${item.job_code}` : '' }}</span> - <span class="desc-span">{{ item.job_name ? item.job_name : item.job_name }}</span> <span
              class="desc-span"
              style="color: #1976D2;"
            >{{ item.job_reference ? `; ${item.job_reference}` : '' }}</span>
          </div>
        </div>

        <div class="d-flex ga-3 align-center">
          <div class="outer-div">
            <span class="title-span">PO#</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.po_number }}</span>
            </div>
          </div>

          <div class="outer-div">
            <span class="title-span">Glass Code</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.g_code }}</span>
            </div>
          </div>
        </div>

        <div class="d-flex ga-3 align-center">
          <div class="outer-div">
            <span class="title-span">Glass Thickness</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.glass_thickness }}</span>
            </div>
          </div>
          <div class="outer-div">
            <span class="title-span">Quantity</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.total_glass }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <v-row>
        <v-col cols="auto">
          <span style="font-size: 18px;">Class</span>
        </v-col>
        <v-col>
          <v-chip
            small
            :color="item && item.class ? item.class.color : 'black'"
            text-color="white"
          >
            {{ item && item.class ? item.class.name : '' }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <span style="font-size: 18px;">Job</span>
        </v-col>
        <v-col>
          <span style="font-weight: bold;">{{ item.job_code ? `${item.job_code}` : '' }}</span> - {{ item.job_name ? item.job_name : item.job_name }} <span style="color: #1976D2;">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <span style="font-size: 18px;">PO#</span>
        </v-col>
        <v-col>
          <span style="color: #000; font-size: 18px;">{{ item.po_number }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <span style="font-size: 18px; font-size: 18px;">Glass Code</span>
        </v-col>
        <v-col>
          <span style="color: #000; font-size: 18px;">{{ item.g_code }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <span style="font-size: 18px;">Glass Thickness</span>
        </v-col>
        <v-col>
          <span style="color: #000; font-size: 18px;">{{ item.glass_thickness }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <span style="font-size: 18px;">Quantity</span>
        </v-col>
        <v-col>
          <span style="color: #000; font-size: 18px;">{{ item.total_glass }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <span style="font-size: 18px;">Status</span>
        </v-col>
        <v-col>
          <v-chip
            small
            :color="item && item.status ? item.status.color : 'black'"
            text-color="white"
          >
            {{ item && item.status ? item.status.name : '' }}
          </v-chip>
        </v-col>
      </v-row> -->
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    statusIdNew: 1,
    statusIdCutting: 8,
    statusIdTempering: 9,
    statusIdProduced: 3,
    statusIdShipped: 5,
    statusIdReceived: 7,
    statusIdShippedToSite: 10,
    statusIdReceviedOnSite: 11,
    statusIdPShippedToSite: 12,
    statusIdPReceviedOnSite: 13,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(batchCode) {
      const submitModel = {
        filters: {
          batch_number: batchCode,
        },
      };
      const res = await this.$api.glassBatch.read(submitModel);
      if (!res || !res.data || res.data.length === 0) {
        this.item = null;
        return;
      }
      const firstIndex = 0;
      this.item = res.data[firstIndex];
      if (this.item.status_id !== this.statusIdNew && this.item.status_id !== this.statusIdCutting && this.item.status_id !== this.statusIdTempering) {
        this.$bus.$emit('showError', 'Batch cannot be changed. Please check the status of the batch.');
      }
    },

    async submit() {
      const submitModel = {
        batch_id: this.item.id,
        status_id: this.item.status_id === this.statusIdNew ? this.statusIdCutting : this.statusIdTempering,
      };
      const res = await this.$api.glassBatch.store(submitModel, 'toggle-stage');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async submitUndo() {
      const submitModel = {
        batch_id: this.item.id,
        status_id: this.item.status_id === this.statusIdCutting ? this.statusIdNew : this.statusIdCutting,
      };
      const res = await this.$api.glassBatch.store(submitModel, 'toggle-stage');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async open(batchCode) {
      if (batchCode) {
        await this.get(batchCode);
        if (this.item === null) {
          this.$bus.$emit('showError', 'Unrecognized Batch QR Code');
          return;
        }
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>

<style scoped>
.outer-div{
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

}
.title-span
{
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.desc-div
{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #BDBDBD;
  border-radius: 6px;
  background-color: #F9F9FA;
}

.desc-span
{
  color: #000;
  font-size: 20px;
}
</style>
