<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :max-width="maxWidth + 'px'"
      :max-height="maxHeight + 'px'"
      :fullscreen="fullScreen"
      scrollable
      :persistent="persistent"
    >
      <v-form
        ref="form"
        autocomplete="off"
        @submit.prevent="submitFunc"
      >
        <v-card>
          <v-card-title>
            <slot name="header" />
          </v-card-title>
          <v-card-text>
            <slot name="body" />
          </v-card-text>
          <v-card-actions>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-if="isReportIssue"
                  class="mr-2 elevation-0"
                  style="background-color: transparent;"
                  v-on="on"
                  @click="openReportIssue"
                >
                  <v-icon
                    style="font-size: 30px; color: #757575;"
                    class="pr-1"
                  >
                    mdi-alert-outline
                  </v-icon>
                </v-btn>
              </template>

              <span>Report Issue/Bug</span>
            </v-tooltip>

            <span
              v-if="warningText"
              style="color: red;"
              class="pl-5"
            >{{ warningText }}</span>
            <v-spacer />
            <v-btn
              v-if="isCancelBtn"
              color="normal"
              class="mr-2"
              @click="close"
            >
              {{ cancelBtnText }}
            </v-btn>
            <v-btn
              v-if="isSecondaryBtn"
              color="secondary"
              :loading="btnLoading"
              @click="secondarySubmitFunc"
            >
              {{ secondaryBtnText }}
            </v-btn>
            <v-btn
              v-if="isSubmitBtn"
              color="primary"
              :loading="btnLoading"
              :disabled="submitBtnDisabled"
              @click="submitFunc"
            >
              {{ submitBtnText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <report-issue-form
        v-show="false"
        ref="reportIssueForm"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import ReportIssueForm from '@/components/forms/reportIssues/ReportIssueForm';

export default {
  components: { ReportIssueForm },
  props:
    {
      maxWidth: {
        type: Number,
        default: 500,
      },
      maxHeight: {
        type: Number,
        default: 1000,
      },
      submit: {
        type: Function,
        default: () => false,
      },
      secondarySubmit: {
        type: Function,
        default: () => false,
      },
      submitBtnText: {
        type: String,
        default: 'Create',
      },
      cancelBtnText: {
        type: String,
        default: 'Cancel',
      },
      secondaryBtnText: {
        type: String,
        default: '',
      },
      warningText: {
        type: String,
        default: '',
      },
      fullScreen: {
        type: Boolean,
        default: false,
      },
      isSubmitBtn: {
        type: Boolean,
        default: true,
      },
      isCancelBtn: {
        type: Boolean,
        default: true,
      },
      isSecondaryBtn: {
        type: Boolean,
        default: false,
      },
      persistent: {
        type: Boolean,
        default: false,
      },
      btnLoading: {
        type: Boolean,
        default: false,
      },
      submitBtnDisabled: {
        type: Boolean,
        default: false,
      },
      isReportIssue: {
        type: Boolean,
        default: true,
      },
    },
  data: () => ({
    dialog: false,
  }),
  methods: {

    async submitFunc() {
      if (!await this.$refs.form.validate()) return;
      const res = await this.submit();
      if (!res) return;
      this.close();
    },

    async secondarySubmitFunc() {
      const res = await this.secondarySubmit();
      if (!res) return;
      this.close();
    },

    open() {
      if (this.$refs.form) { this.$refs.form.reset(); }
      this.dialog = true;
    },

    close() {
      if (this.$refs.form) { this.$refs.form.reset(); }
      this.dialog = false;
    },

    openReportIssue() {
      this.$refs.reportIssueForm.open();
    },
  },
};
</script>
