<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
    :full-screen="true"
  >
    <template slot="header">
      <span>Finished goods</span>
    </template>
    <template slot="body">
      <v-row align="center">
        <v-col
          v-for="tab, idx in tabs"
          :key="idx"
          cols="auto"
          :style="`cursor: pointer; border-bottom: ${JSON.stringify(selectedTab) == JSON.stringify(tab) ? '2px solid #1976d2' : 'none'};`"
          class="ml-2 mr-2 pt-6"
          @click="tabChange(tab)"
        >
          <span :style="`font-size: 16px !important; color: ${JSON.stringify(selectedTab) == JSON.stringify(tab) ? '#1976d2' : isDarkMode ? '' : 'rgba(0,0,0,0.8)'}; font-weight: ${JSON.stringify(selectedTab) == JSON.stringify(tab) ? 'bold' : 'normal'};`">{{ tab.name }}</span>
        </v-col>
        <v-spacer />
        <v-col
          cols="2"
          class="pt-2 pb-4"
        >
          <v-autocomplete
            v-model="filderProjectId"
            :items="projects"
            item-text="name"
            item-value="id"
            hide-details
            label="Project"
            clearable
          />
        </v-col>
        <v-col
          cols="2"
          class="pt-2 pb-4"
        >
          <v-text-field
            v-model="search"
            hide-details
            label="Search"
          />
        </v-col>
      </v-row>
      <v-row v-if="groupedData">
        <v-col>
          <v-card
            elevation="0"
            outlined
            style="height: calc(100vh - 290px);
              position: relative;
              overflow: auto;"
          >
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
            />
            <table
              style="border-collapse: collapse; width: 100%;"
            >
              <tbody>
                <tr
                  v-for="(group, index) in groupedData"
                  :key="index"
                  style="display: flex; flex-direction: column;"
                >
                  <td
                    style="width: 100%; background-color: #E9F5E9; display: flex; align-items: center; gap: 6px;"
                    class="pa-3"
                  >
                    <!-- <v-icon
                      style="font-size: 30px; font-weight: bold; color: #000"
                      @click="closeLocation(group)"
                    >
                      {{ closedLocations.includes(group.name) ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                    </v-icon> -->
                    <span style="font-size: 26px; font-weight: bold">{{ group.name }}</span>
                  </td>
                  <td v-if="!closedLocations.includes(group.name)">
                    <table style="border-collapse: collapse; width: 100%;">
                      <tbody>
                        <tr
                          v-for="(projectGroup, projectIndex) in group.elements"
                          :key="projectIndex"
                          style="display: flex; flex-direction: column;"
                        >
                          <td
                            class="pt-3 pb-1 pr-3 pl-12"
                            style="width: 100%; background-color: #F5F5F5; padding: 12px; display: flex; align-items: center; gap: 6px;"
                          >
                            <v-icon
                              style="font-size: 22px; font-weight: bold; color: #000"
                              @click="closeProject(projectGroup)"
                            >
                              {{ closedProjects.includes(projectGroup.name) ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                            </v-icon>
                            <span style="font-size: 18px; font-weight: bold">{{ projectGroup.name }}</span>
                          </td>
                          <td v-if="!closedProjects.includes(projectGroup.name)">
                            <table style="border-collapse: collapse; width: 100%;">
                              <thead style="width: 100%; background-color: #F5F5F5;">
                                <tr>
                                  <th
                                    class="finished-goods-th"
                                    style="padding-left: 75px;"
                                  >
                                    Serial#
                                  </th>
                                  <th class="finished-goods-th">
                                    Tag Id
                                  </th>
                                  <th class="finished-goods-th">
                                    Tag Number
                                  </th>
                                  <th class="finished-goods-th">
                                    Work Order
                                  </th>
                                  <th class="finished-goods-th">
                                    Reference
                                  </th>
                                  <th class="finished-goods-th">
                                    Cut Width
                                  </th>
                                  <th class="finished-goods-th">
                                    Cut Height
                                  </th>
                                  <th class="finished-goods-th">
                                    Sqft
                                  </th>
                                  <th class="finished-goods-th">
                                    Location
                                  </th>
                                  <th class="finished-goods-th">
                                    Shelf
                                  </th>
                                  <th class="finished-goods-th">
                                    Status
                                  </th>
                                  <th style="text-align: left;">
                                    Note
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(finishedGood, finishedGoodIndex) in projectGroup.projectElements"
                                  :key="finishedGoodIndex"
                                  style="border-bottom: 1px solid #E0E0E0;"
                                >
                                  <td
                                    style="padding-left: 75px"
                                    class="finished-goods-td"
                                  >
                                    <span>
                                      {{ finishedGoodIndex + 1 }}
                                    </span>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.tag_id }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.tag_number }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.work_order }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.floor_number }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.cut_width }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.cut_height }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.sqft }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.location_name }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <text-highlight
                                      :queries="search === null ? '' : search"
                                    >
                                      {{ finishedGood.inventory_location_name }}
                                    </text-highlight>
                                  </td>
                                  <td class="finished-goods-td">
                                    <v-chip
                                      v-if="finishedGood && finishedGood.status"
                                      small
                                      :color="finishedGood.status.color ? finishedGood.status.color : '#000000'"
                                      text-color="white"
                                    >
                                      {{ finishedGood.status.name }}
                                    </v-chip>
                                  </td>
                                  <td>
                                    <span
                                      v-if="finishedGood.notes && finishedGood.notes.length > 0"
                                      style="white-space: nowrap; color: rgb(255, 152, 0);"
                                    >
                                      <v-btn
                                        icon
                                        small
                                        @click="openNoteForm(finishedGood.id)"
                                      >
                                        <v-icon
                                          small
                                        >
                                          mdi-eye
                                        </v-icon>
                                      </v-btn>
                                      <span>{{ finishedGood.notes && finishedGood.notes.length > 0 ? finishedGood.notes[0].text : '' }}</span>
                                    </span>
                                    <v-btn
                                      v-else
                                      color="primary"
                                      small
                                      icon
                                      @click="openNoteForm(finishedGood.id)"
                                    >
                                      <v-icon small>
                                        mdi-pencil
                                      </v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
          <div
            style="display: flex; border: 1px solid #E0E0E0; gap: 12px;"
            class="justify-end align-center pa-5"
          >
            <span style="font-size: 16px;">Rows per page: {{ pagination.page === 1 ? '1' : pagination.itemsPerPage * pagination.page - 1 }} - {{ pagination.itemsPerPage * pagination.page }} of {{ total }}</span>
            <div>
              <v-icon
                style="font-size: 40px; cursor: pointer;"
                @click="previousPage()"
              >
                mdi-chevron-left
              </v-icon>
              <v-icon
                style="font-size: 40px; cursor: pointer;"
                @click="nextPage()"
              >
                mdi-chevron-right
              </v-icon>
            </div>
          </div>
        </v-col>
      </v-row>
      <NoteForm
        v-show="false"
        ref="noteForm"
        :simple="true"
        @success="init()"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';
import NoteForm from '@/components/forms/notes/NoteForm.vue';

export default {
  components: {
    NoteForm,
  },
  props: {

  },
  data: () => ({
    groupedData: [],
    locations: [],
    tabs: [],
    projects: [],
    pagination: {
      itemsPerPage: 500,
      page: 1,
    },
    total: 0,
    loading: false,
    selectedTab: null,
    filterJobSubClass: null,
    filterLocationId: null,
    filderProjectId: null,
    search: null,
    closedLocations: [],
    closedProjects: [],
    noteTypeIdFinishedGood: 3,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    filderProjectId() {
      this.init();
    },
    search: debounce(function () {
      this.init();
    }, 500),
  },
  created() {
  },
  methods: {

    async init() {
      this.groupedData = [];
      this.pagination.page = 1;
      this.closedProjects = [];
      this.closedLocations = [];
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      const submitModel = {
        filters: {
          job_sub_class_id: this.filterJobSubClass,
          location_id: this.filterLocationId,
          project_id: this.filderProjectId,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.finishedGood.read(submitModel, null, 'read-finished-goods');
      this.loading = false;
      if (!res || !res.grouped) return;
      // res.grouped.forEach((element) => {
      //   const locationIndex = this.groupedData.findIndex((x) => x.name === element.name);
      //   if (locationIndex === -1) {
      //     this.groupedData.push(element);
      //   } else {
      //     element.elements.forEach((projectElement) => {
      //       const projectIndex = this.groupedData[locationIndex].elements.findIndex((x) => x.name === projectElement.name);
      //       if (projectIndex === -1) {
      //         this.groupedData[locationIndex].elements.push(projectElement);
      //       } else {
      //         projectElement.projectElements.forEach((finishedGood) => {
      //           this.groupedData[locationIndex].elements[projectIndex].projectElements.push(finishedGood);
      //         });
      //       }
      //     });
      //   }
      // });
      this.groupedData = res.grouped;
      this.total = res.total;
    },

    async getLocations() {
      this.tabs = [];
      this.locations = await this.$api.location.getList();

      const locationId99Vinyl = 3;
      const locationId141Adesso = 8;
      const locationId300Chrislea = 2;
      this.tabs.push(...this.locations.filter((x) => x.id === locationId99Vinyl || x.id === locationId141Adesso || x.id === locationId300Chrislea));
      this.tabs.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        x.type = 'location';
      });
      this.tabs.push({
        id: 2, name: 'CORNER POST', color: '#E53935', type: 'sub-class',
      }, {
        id: 3, name: 'EURO DOOR', color: '#FDD835', type: 'sub-class',
      });
    },

    async getProjects() {
      const submitModel = {
        filters: {},
      };

      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (!res || !res.data) {
        this.projects = [];
        return;
      }
      this.projects = res.data;
    },

    // handleScroll(event) {
    //   if (this.loading) return;
    //   const container = event.target;
    //   const scrollPosition = container.scrollTop + container.clientHeight;
    //   const scrollThreshold = container.scrollHeight - 50; // Trigger 50px before the bottom

    //   if (scrollPosition >= scrollThreshold) {
    //     this.pagination.page += 1;
    //     this.get();
    //   }
    // },

    nextPage() {
      if (this.loading || this.total <= this.pagination.page * this.pagination.itemsPerPage) return;
      this.pagination.page += 1;
      this.get();
    },

    previousPage() {
      if (this.loading || this.pagination.page === 1) return;
      this.pagination.page -= 1;
      this.get();
    },

    open() {
      this.init();
      this.getLocations();
      this.getProjects();
      this.$refs.dialogBox.open();
    },

    openNoteForm(itemId) {
      this.$refs.noteForm.open(itemId, this.noteTypeIdFinishedGood);
    },

    tabChange(item) {
      if (this.selectedTab === item) { this.selectedTab = null; this.filterJobSubClass = null; this.filterLocationId = null; this.init(); return; }
      this.selectedTab = item;
      if (item.type === 'sub-class') {
        this.filterJobSubClass = item.id;
        this.filterLocationId = null;
      } else if (item.type === 'location') {
        this.filterLocationId = item.id;
        const jobSubClass4800Windows = 1;
        const tab300Chrislea = 2;
        if (item.id === tab300Chrislea) {
          this.filterJobSubClass = jobSubClass4800Windows;
        } else { this.filterJobSubClass = null; }
      }
      this.init();
    },

    closeLocation(group) {
      if (this.closedLocations.includes(group.name)) {
        this.closedLocations = this.closedLocations.filter((x) => x !== group.name);
      } else {
        this.closedLocations.push(group.name);
      }
    },

    closeProject(projectGroup) {
      if (this.closedProjects.includes(projectGroup.name)) {
        this.closedProjects = this.closedProjects.filter((x) => x !== projectGroup.name);
      } else {
        this.closedProjects.push(projectGroup.name);
      }
    },
  },
};
</script>

<style scoped>

.finished-goods-th{
  text-align: left;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.finished-goods-td{
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
</style>
