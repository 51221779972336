<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-spacer />
      <v-col
        cols="4"
        class="d-flex justify-end pr-6"
      >
        <v-autocomplete
          v-model="jobFilter"
          :items="jobs"
          item-text="name"
          item-value="id"
          hide-details
          placeholder="Filter by Job"
          :clearable="true"
          @change="fetchBoms"
        >
          <template #selection="{ item }">
            <span
              class="black--text"
            >JOB #{{ item.id }} ({{ item.address_simple }})</span>
          </template>
          <template #item="{ item }">
            <span
              class="black--text"
            >JOB #{{ item.id }} ({{ item.address_simple }})</span>
          </template>
        </v-autocomplete>
        <v-btn
          v-if="canEdit"
          color="primary"
          outlined
          class="d-flex align-self-end ml-4"
          @click="
            () => {
              $refs.bomForm.openDialog(type);
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new {{ type }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="bomsHeaders"
          :items="boms"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template #[`item.code`]="{ item }">
            <span style="font-weight: bold;">{{ item.code }}</span>
          </template>
          <template #[`item.worker`]="{ item }">
            <text-highlight :queries="search">
              {{ item.worker != ' ' ? item.worker : '-' }}
            </text-highlight>
          </template>
          <template #[`item.job_address`]="{ item }">
            <text-highlight :queries="search">
              {{ item.job_address }}
            </text-highlight>
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip
              small
              :color="item && item.status_id === 1 ? 'light-blue' : 'green'"
              text-color="white"
            >
              {{ item && item.status }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="item.status === 'Released'"
              class="ml-1 mt-1 mb-1"
              color="success"
              outlined
              small
              @click="showBom(item)"
            >
              View
            </v-btn>
            <template v-else>
              <v-btn
                v-if="canEdit"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="showBom(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteBom(item)"
              >
                DELETE
              </v-btn>
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <BomForm
      ref="bomForm"
      @bom-creation="notifyBomCreated"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import BomForm from '../../components/forms/BomForm';
import BomRmasApi from '../../api/BomRmasApi';
import Bus from '../../bus';

export default {
  name: 'Boms',
  components: {
    BomForm,
  },
  filters: {},
  data: () => ({
    type: 'bom',
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    bomsHeaders: [
      { text: 'Bom Code', value: 'code' },
      { text: 'Address', value: 'job_address' },
      { text: 'Worker', value: 'worker' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Total Items', value: 'items_num' },
      { text: 'Total Quantity', value: 'items_qty' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
      {
        text: 'Actions', value: 'actions', width: '15%', align: 'end',
      },
    ],
    boms: [],
    jobFilter: null,
    jobs: [],
    canEdit: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  created() {
    this.canEdit = this.$store.getters.userCanEdit;
    // this.initialize();
  },
  methods: {
    async initialize(type, jobId = null) {
      this.type = type;
      this.jobFilter = jobId;
      if (this.type === 'bom') {
        this.bomsHeaders[0].text = 'BOM Code';
      } else {
        this.bomsHeaders[0].text = 'RMA Code';
      }

      await Promise.all([this.fetchBoms(), this.fetchJobs()]);
    },
    openBomForm(item) {
      this.$refs.bomForm.openDialog(this.type, item);
    },
    showBom(item) {
      const bom = {
        id: item.id,
        code: item.code,
        status: item.status,
        worker: item.worker,
      };
      this.$emit('open-bom', bom, this.type);
    },
    async deleteBom(bom) {
      if (!confirm('Are you sure?')) {
        return;
      }

      const res = await BomRmasApi.softDelete(bom.id);
      if (res.err) {
        if (res.errDesc === 'bom_released') {
          Bus.$emit('alert', 'error', 'Error!');
        }
      } else if (res === true) {
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchBoms();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    async notifyBomCreated(res) {
      // Redirect to BOM page and show new BOM
      await this.fetchBoms();
      const bom = this.boms.find((x) => x.id === res.id);
      if (bom) {
        this.showBom(bom);
      }
    },
    async fetchBoms() {
      this.loading = true;
      // Cast potential undefined to null
      this.jobFilter = this.jobFilter || null;
      this.boms = await BomRmasApi.getList(this.type, this.jobFilter);
      this.loading = false;
    },
    async fetchJobs() {
      const readModel = {
        filters:
        {
          is_manual: 0,
        },
      };
      this.jobs = await this.$api.job.read(readModel);
    },
  },
};
</script>
<style scoped></style>
