<template>
  <v-container
    fluid
  >
    <v-row align="center">
      <v-col
        class="pb-0 pt-0"
        cols="auto"
      >
        <v-tabs
          v-model="selectedTab"
        >
          <v-tab>
            ACTIVE
          </v-tab>
          <v-tab>
            ARCHIVED
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer />
      <v-col
        v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)"
        class="text-end pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Pick List
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-select
          v-model="statusId"
          :items="statuses"
          label="Status"
          item-text="name"
          item-value="id"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : ''"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="projectId"
          :items="projects"
          item-value="id"
          item-text="name"
          label="Project"
          clearable
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="departmentId"
          :items="departments"
          item-value="id"
          label="Department"
          item-text="name"
          clearable
        />
      </v-col>
      <v-col cols="2">
        <v-menu
          ref="menuFrom"
          v-model="dateMenuFrom"
          :return-value.sync="filterDateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDateFrom"
              label="Requested At"
              readonly
              v-bind="attrs"
              v-on="on"
              @change="$refs.menuFrom.save(filterDateFrom)"
            />
          </template>
          <v-date-picker
            v-model="filterDateFrom"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuFrom = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDateFrom = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @update:options="updateOptions"
          >
            <template #[`item.id`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-weight: bold;"
              >
                {{ item.id }}
              </text-highlight>
            </template>
            <template #[`item.project_name`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-weight: bold;"
              >
                {{ item.project_name }}
              </text-highlight>
            </template>
            <template #[`item.department_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.department_name }}
              </text-highlight>
            </template>
            <template #[`item.created_at`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.created_at }}
              </text-highlight>
            </template>
            <template #[`item.user_full_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.user_full_name }}
              </text-highlight>
            </template>
            <template #[`item.supplied_at`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.supplied_at }}
              </text-highlight>
            </template>
            <template #[`item.supplier_full_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.supplier_full_name }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                v-if="item.status"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item && item.status ? item.status.color : 'white'"
              >
                {{ item && item.status ? item.status.name : '' }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdPicker))"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openPick(item.id)"
              >
                PICK
              </v-btn>
              <v-btn
                v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)) && item.status_id != statusIdSupplied"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEdit(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)"
                class="ml-1 mt-1 mb-1 mr-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
              <v-menu
                v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)"
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mt-1 mb-1"
                    elevation="0"
                    fab
                    outlined
                    x-small
                    v-bind="attrs"
                    tile
                    color="primary"
                    style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  class="pa-3"
                >
                  <v-list-item
                    @click="toggle(item)"
                  >
                    <v-list-item-title :style="`color: purple; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          {{ item.is_archived === 0 ? 'ARCHIVE' : 'ACTIVATE' }}
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <part-pick-list-form
      ref="partForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import PartPickListForm from '@/components/forms/partPickLists/PartPickListForm';
import { mapState } from 'vuex';

export default {
  components: {
    PartPickListForm,
  },
  data: () => ({
    items: [],
    projects: [],
    selectedLocationId: 0,
    projectId: null,
    departmentId: null,
    headers: [
      { text: 'List#', value: 'id', width: '3%' },
      { text: 'Project Name', value: 'project_name', width: '20%' },
      { text: 'Department', value: 'department_name', width: '10%' },
      { text: 'Records#', value: 'total_items', width: '3%' },
      { text: 'Request At', value: 'created_at', width: '10%' },
      { text: 'Requested By', value: 'user_full_name', width: '8%' },
      { text: 'Supplied At', value: 'supplied_at', width: '10%' },
      { text: 'Supplied By', value: 'supplier_full_name', width: '8%' },
      {
        text: 'Status', value: 'status', width: '10%', align: 'center',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '20%', sortable: false,
      },
    ],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    sort: {
      order_by_first: 'id',
      direction_sort: true,
    },
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    loading: false,
    departments: [],
    total: 0,
    windowHeight: 1000,
    topSpaceHeight: 280,
    roles: [],
    statuses: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdRequester: 15,
    userRoleIdPicker: 23,
    statusIdNew: 1,
    statusIdPartiallySupplied: 2,
    statusIdSupplied: 3,
    selectedTab: 0,
    statusId: 0,
    dateMenuFrom: false,
    filterDateFrom: null,
    pickState: true,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      partPickListState: (state) => state.partPickListState,
    }),
  },
  watch: {
    projectId(value) {
      this.storeState('project_id', value);
      this.get();
    },
    departmentId(value) {
      this.storeState('department_id', value);
      this.get();
    },
    statusId(value) {
      this.storeState('status_id', value);
      this.get();
    },
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
    selectedTab() {
      this.get();
    },
    filterDateFrom(value) {
      this.$refs.menuFrom.save(value);
      this.storeState('created_at', value);
      this.get();
    },
  },
  created() {
    if (this.partPickListState) {
      if (this.partPickListState.pagination) { this.pagination = this.partPickListState.pagination; }
      if (this.partPickListState.status_id) { this.statusId = this.partPickListState.status_id; }
      if (this.partPickListState.project_id) { this.projectId = this.partPickListState.project_id; }
      if (this.partPickListState.department_id) { this.departmentId = this.partPickListState.department_id; }
      if (this.partPickListState.created_at) { this.filterDateFrom = this.partPickListState.created_at; }
    }
    this.roles = this.user.roles.map((x) => x.id);
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.get();
      this.getProjects();
      this.getDepartments();
      this.getStatuses();
    },

    storeState(property, value) {
      const state = this.partPickListState;
      state[property] = value;
      this.$store.commit('storePartPickListState', state);
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          project_id: this.projectId,
          department_id: this.departmentId,
          is_archived: this.selectedTab,
          created_at: this.filterDateFrom,
          status_id: this.statusId,
        },
        pagination: this.pagination,
        search: this.search,
        sort: this.sort,
      };
      const res = await this.$api.partPickList.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      this.loading = false;
      if (!res || !res.data) {
        this.items = [];
        return;
      }
      this.items = res.data;
      this.total = res.total;
    },

    async getProjects() {
      this.loading = true;
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.projects = [];
        return;
      }
      this.projects = res.data;
      this.loading = false;
    },

    async getDepartments() {
      this.departments = await this.$api.department.get();
    },

    async getStatuses() {
      const res = await this.$api.partPickListStatus.get();
      this.statuses.push({ name: 'ALL', id: 0, color: '#000000' });
      this.statuses.push(...res);
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.partPickList.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.partForm.open();
    },

    openEdit(id) {
      this.$refs.partForm.open(id);
    },

    openPick(id) {
      this.$refs.partForm.open(id, this.pickState);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updateOptions(newOptions) {
      // if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
      //   this.pagination = pagination;
      //   this.storeState('pagination', pagination);
      // }
      this.pagination = newOptions;
      this.sort = {
        order_by_first: newOptions.sortBy[0],
        direction_sort: newOptions.sortDesc[0],
      }
    },

    async toggle(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure you want to ${item.is_archived === 0 ? 'Archive' : 'Active'} this record?`,
      });
      if (!confirm) return;
      const isActive = item.is_archived === 1 ? 0 : 1;
      const res = await this.$api.partPickList.get(`toggle/${item.id}/${isActive}`);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.get();
    },
  },
};
</script>
