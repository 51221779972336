<template>
  <v-container
    v-if="!isMobile"
    fluid
    class="pr-0 pb-0"
  >
    <v-row>
      <v-col
        id="leftHalf"
        cols="6"
        class=" pa-0"
      >
        <v-row>
          <v-col
            class="pb-0 pl-16"
            style="position: absolute; margin-top: 30px !important;"
          >
            <v-img
              v-if="isDarkMode"
              src="@/assets/balanced-logo-white.svg"
              style="max-width: 300px;"
            />
            <v-img
              v-else
              src="@/assets/balanced-logo.svg"
              style="max-width: 300px;"
            />
          </v-col>
        </v-row>
        <v-container fill-height>
          <v-row>
            <v-col class="d-flex justify-center align-center pa-0">
              <v-form ref="loginForm">
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pt-0"
                    style="width: 350px;"
                  >
                    <v-row>
                      <v-col
                        class="pb-0 pt-0"
                      >
                        <span style="font-size: 30px; font-weight: 500;">Login</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span style="font-size: 18px; font-weight: 500;">Welcome to {{ organisationName }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <v-text-field
                          v-model="username"
                          label="Username"
                          prepend-icon="fas fa-user"
                          type="text"
                          color="black"
                          :rules="[(v) => !!v || 'Username is required']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <v-text-field
                          id="password"
                          v-model="password"
                          prepend-icon="fas fa-lock"
                          type="password"
                          label="Password"
                          :rules="[v => !!v || 'Password is required']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#008E40"
                          dark
                          style="border-radius: 7px;"
                          class="pl-6 pr-6 pt-5 pb-5"
                          block
                          :loading="submitted"
                          @click="login()"
                        >
                          <span style="font-size: 16px;">Login</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <span style="font-weight: bold; ">BALANCED+ ERP APP</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 pb-0">
                        <v-divider style="border: #000 solid 1px;" />
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col
                        cols="6"
                        class="pr-0 pl-0 pt-0"
                      >
                        <a
                          href="https://play.google.com/store/apps/details?id=com.zita.erp"
                          target="_blank"
                        ><v-img
                          src="@/assets/google-play-badge.png"
                        /></a>
                      </v-col>
                      <v-col
                        cols="6"
                        class="pl-0 pr-2 text-center pt-0"
                        style="margin-top: 6px;"
                      >
                        <a
                          href="https://apps.apple.com/tt/app/zita-erp/id1599364763"
                          target="_blank"
                        ><img
                          width="160"
                          src="@/assets/download-on-the-app-store.png"
                        ></a>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0 pt-0">
                        <span style="font-weight: bold; ">BALANCED+ PRODUCTION QR APP</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 pb-0">
                        <v-divider style="border: #000 solid 1px;" />
                      </v-col>
                    </v-row>
                    <v-row
                      class="align-center mr-1 ml-0 mt-5"
                      style="border: 2px solid #000; border-radius: 6px; cursor: pointer; width: 155px; background-color: #000;"
                      @click="downloadProductionQr()"
                    >
                      <v-col
                        cols="auto"
                        class="pr-0 pl-1 pb-0 pt-1"
                      >
                        <img
                          src="@/assets/plus.png"
                          width="30"
                          height="30"
                        >
                      </v-col>
                      <v-col class="pa-3">
                        <v-row>
                          <v-col
                            class="pt-0 pb-0"
                            style="height: 20px;"
                          >
                            <span style="font-size: 12px; font-weight: 500; color: #fff; height: 10px !important; display: inline-block;">Download</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            class="pt-0 pb-0"
                          >
                            <span style="font-size: 18px; font-weight: bold; color: #fff;">PROD QR</span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col
            cols="auto"
            class="pa-0 pl-16"
            style="margin-top: -30px;"
          >
            <span>v{{ appVersion }} </span>
          </v-col>
          <v-col
            cols="auto"
            class="pa-0 pl-6"
            style="margin-top: -30px;"
          >
            <span>{{ currentYear }} © </span><a
              style="text-decoration: none;"
              href="https://www.balanced.plus/software-development/"
              target="_blank"
            >BALANCED+</a>
          </v-col>
          <v-spacer />
          <v-col
            cols="auto"
            class="text-end pa-0 pr-16"
            style="margin-top: -48px;"
          >
            <theme-switch />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        id="rightHalf"
        cols="6"
        class="pa-0"
      >
        <!-- <img
          src="@/assets/login-building.jpg"
          lazy-src="@/assets/login-building.jpg"
          :style="`height: 100vh; width: ${$vuetify.breakpoint.name !== 'xl' ? '50vw' : ''}; display: block !important; margin: 0px !important;`"
        > -->
      </v-col>
    </v-row>
  </v-container>

  <!-- MOBILE VIEW -->
  <v-container
    v-else
    fluid
    class="d-flex flex-column justify-space-between"
    style="height: 100vh;"
  >
    <v-row
      justify="center"
      align="start"
      class="mt-2"
    >
      <v-img
        v-if="isDarkMode"
        src="@/assets/balanced-logo-white.svg"
        max-height="70px"
        max-width="240px"
        contain
      />
      <v-img
        v-else
        src="@/assets/balanced-logo.svg"
        max-height="70px"
        max-width="240px"
        contain
      />
    </v-row>
    <v-row
      justify="center"
      align="center"
    >
      <v-form ref="loginForm">
        <v-col
          class="pt-0"
          style="width: 350px;"
        >
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="auto"
              class="pt-0"
            >
              <v-img
                src="@/assets/AWD_logo_RGB.png"
                style="max-width: 300px;"
              />
            </v-col>
          </v-row>
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="auto"
              class="pb-0 pt-0"
            >
              <span style="font-size: 30px; font-weight: 500;">Login</span>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            align="center"
          >
            <v-col>
              <span style="font-size: 18px; font-weight: 500;">Welcome to {{ organisationName }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-text-field
                v-model="username"
                label="Username"
                prepend-icon="fas fa-user"
                type="text"
                color="black"
                :rules="[(v) => !!v || 'Username is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                id="password"
                v-model="password"
                prepend-icon="fas fa-lock"
                type="password"
                label="Password"
                :rules="[v => !!v || 'Password is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="#008E40"
                dark
                style="border-radius: 7px;"
                class="pl-6 pr-6 pt-5 pb-5"
                block
                :loading="submitted"
                @click="login()"
              >
                <span style="font-size: 16px;">Login</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <span style="font-weight: bold; ">BALANCED+ ERP APP</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-divider style="border: #000 solid 1px;" />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="6"
              class="pr-0 pl-0 pt-0"
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.zita.erp"
                target="_blank"
              ><v-img
                src="@/assets/google-play-badge.png"
              /></a>
            </v-col>
            <v-col
              cols="6"
              class="pl-0 pr-2 text-center pt-0"
              style="margin-top: 6px;"
            >
              <a
                href="https://apps.apple.com/tt/app/zita-erp/id1599364763"
                target="_blank"
              ><img
                width="160"
                src="@/assets/download-on-the-app-store.png"
              ></a>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0">
              <span style="font-weight: bold; ">BALANCED+ PRODUCTION QR APP</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-divider style="border: #000 solid 1px;" />
            </v-col>
          </v-row>
          <v-row
            class="align-center mr-1 ml-0 mt-5"
            style="border: 2px solid #000; border-radius: 6px; cursor: pointer; width: 155px; background-color: #000;"
            @click="downloadProductionQr()"
          >
            <v-col
              cols="auto"
              class="pr-0 pl-1 pb-0 pt-1"
            >
              <img
                src="@/assets/plus.png"
                width="30"
                height="30"
              >
            </v-col>
            <v-col class="pa-3">
              <v-row>
                <v-col
                  class="pt-0 pb-0"
                  style="height: 20px;"
                >
                  <span style="font-size: 12px; font-weight: 500; color: #fff; height: 10px !important; display: inline-block;">Download</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="pt-0 pb-0"
                >
                  <span style="font-size: 18px; font-weight: bold; color: #fff;">PROD QR</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-row>
    <v-row
      justify="center"
      align="end"
      class="mb-2"
    >
      <v-col
        cols="auto"
        class="pb-0"
        style="margin-top: -30px;"
      >
        <span>v{{ appVersion }} </span>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0"
        style="margin-top: -30px;"
      >
        <span>{{ currentYear }} © </span><a
          style="text-decoration: none;"
          href="https://www.balanced.plus/software-development/"
          target="_blank"
        >BALANCED+</a>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0"
        style="margin-top: -48px;"
      >
        <theme-switch />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ThemeSwitch from '@/components/elements/ThemeSwitch';
import { saveAs } from 'file-saver';

export default {
  name: 'Login',
  components: { ThemeSwitch },
  data: () => ({
    valid: false,
    appTitle: 'ERP',
    username: '',
    password: '',
    submitted: false,
    organisationName: '',
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
    ...mapGetters([
      'isLoggedIn',
      'getUserRoute',
    ]),
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    isMobile() {
      if (window.innerWidth) {
        return window.innerWidth <= 768;
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getOrganisation();
    },
    async getOrganisation() {
      this.org = await this.$api.organization.getList();
      this.organisationName = this.org[0].name;
    },
    async login() {
      const valid = await this.$refs.loginForm.validate();

      if (!valid) return;

      this.submitted = true;
      this.$store.dispatch('login', { username: this.username, password: this.password })
        .then(() => {
          this.submitted = false;
        })
        .catch(() => {
          this.submitted = false;
          this.$bus.$emit('showError', 'Username or Password is Incorrect');
        });
    },

    async downloadProductionQr() {
      this.loading = true;
      const res = await this.$api.helper.get('production-qr-app', { responseType: 'blob' });
      const file = new Blob([res], {
        type: 'application/vnd.android.package-archive',
      });
      saveAs(file, 'ProductionQR.apk');
      this.loading = false;
    },
  },
};
</script>

<style scoped>
#rightHalf
{
  background: url("../assets/login-building.jpg");
   width: 50%;
   position: absolute;
   right: 0px;
   height: 100%;
   background-position: bottom;
   background-size: cover;
}

#leftHalf {
   width: 50%;
   position: absolute;
   left: 0px;
   height: 100%;
}
</style>
