<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="(billOfLadingQr) ? submitQr : submit"
    :submit-btn-text="submitBtnText()"
    :is-submit-btn="isSubmitBtn()"
    :full-screen="true"
    :persistent="true"
  >
    <template slot="header">
      <span>{{ headlineText() }}</span>
    </template>
    <template slot="body">
      <!-- Bill Of Lading QR -->
      <v-row v-if="billOfLadingQr">
        <v-col
          v-if="item && item.status"
          class="text-center"
        >
          <h3 class="h3">
            Status
          </h3>
          <v-chip
            medium
            :color="item && item.status ? item.status.color : 'black'"
            text-color="white"
            class="mt-1"
          >
            {{ item && item.status ? item.status.name : '' }}
          </v-chip>
        </v-col>
        <v-col
          v-if="item"
          class="text-center"
        >
          <h3 class="h3">
            Job Ref
          </h3>
          <span class="mt-1 font-weight-bold">{{ item.job_code ? `${item.job_code}` : '' }}</span> - {{ item.job_name ? item.job_name : item.job_name }} <span style="color: #1976D2;">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.truck && item.truck.type"
          class="text-center"
        >
          <h3 class="h3">
            Truck Type
          </h3>
          <v-chip
            medium
            :color="item && item.truck && item.truck.type ? item.truck.type.color : 'black'"
            text-color="white"
            class="mt-1"
          >
            {{ item && item.truck && item.truck.type ? item.truck.type.name : '' }}
          </v-chip>
        </v-col>
        <v-col
          v-if="item && item.truck"
          class="text-center"
        >
          <h3 class="h3">
            Truck Number
          </h3>
          <span class="mt-1 font-weight-bold">{{ item && item.truck ? item.truck.truck_number : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.truck"
          class="text-center"
        >
          <h3 class="h3">
            Plate Number
          </h3>
          <span class="mt-1 font-weight-bold">{{ item && item.truck ? item.truck.plate_number : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.shipped_at"
          class="text-center"
        >
          <h3 class="h3">
            Shipped Date
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? formatDateTime(item.shipped_at) : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.shipped_by_full_name"
          class="text-center"
        >
          <h3 class="h3">
            Shipped By
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.shipped_by_full_name : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.received_at"
          class="text-center"
        >
          <h3 class="h3">
            Received Date
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? formatDateTime(item.received_at) : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.received_by_full_name"
          class="text-center"
        >
          <h3 class="h3">
            Received By
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.received_by_full_name : '' }}</span>
        </v-col>
        <v-col
          class="text-center"
        >
          <h3 class="h3">
            Total Weight
          </h3>
          <span class="mt-1 font-weight-bold">{{ getAllTotalWeightKilograms().toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}kg</span>
        </v-col>
      </v-row>
      <!-- Bill Of Lading -->
      <v-row v-if="!billOfLadingQr">
        <v-col
          cols="3"
          class="text-start pr-0"
        >
          <v-text-field
            v-model="tagQr"
            autofocus
            :disabled="isOpen"
            @keydown.enter.native.prevent
            @input="qrCodeValueChange"
          />
        </v-col>
        <v-col
          cols="auto"
          class="mt-4 pb-0"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-on="!tagQr ? on : ''">
                <v-btn
                  v-bind="attrs"
                  color="primary"
                  outlined
                  :disabled="!tagQr || isOpen"
                  @click="handleTagAction"
                  @keyup.enter="handleTagAction"
                >
                  {{ `${returnItem ? 'Return' : 'Add'} FG Tag` }}
                </v-btn>
              </div>
            </template>
            <span> {{ `${returnItem ? 'Return' : 'Add'} Finished Good Tag` }} </span>
          </v-tooltip>
        </v-col>
        <v-spacer />
        <v-col
          class="mt-4 pb-0"
          cols="auto"
        >
          <span
            style="font-size: 60px; font-weight: bold;"
            :style="{color: getTotalWeightColor()}"
          >Total Weight: {{ getAllTotalWeightKilograms().toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}kg ({{ getAllTotalWeightPounds().toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}lb)</span>
        </v-col>
        <v-col
          class="mt-4 pb0-"
          cols="auto"
        >
          <span
            style="font-size: 60px; font-weight: bold;"
            :style="{color: getTotalWeightColor()}"
          >Total Quantity: {{ item.bill_of_lading_items.length }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="item.bill_of_lading_items"
              hide-default-footer
              :items-per-page="-1"
              fixed-header
              :height="tableHeight"
              dense
              :item-class="itemRowBackground"
            >
              <template
                v-for="h in headers"
                #[`header.${h.value}`]="{ header }"
              >
                <v-tooltip
                  v-if="h.fullText"
                  :key="h.value"
                  top
                >
                  <template #activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>{{ header.fullText }}</span>
                </v-tooltip>
                <span
                  v-else
                  :key="h.value"
                >
                  {{ h.text }}
                </span>
              </template>
              <template #[`item.finished_good.tag_id`]="{ item }">
                <span style="font-weight: bold">
                  {{ item && item.finished_good && item.finished_good.tag_id ? item.finished_good.tag_id : '-' }}
                </span>
              </template>
              <template #[`item.serial_number`]="{ item }">
                {{ item && item.finished_good && item.finished_good.tag_id ? extractSerialNumber(item) : '-' }}
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item && item.status"
                  small
                  :color="item.status.color ? item.status.color : '#000000'"
                  text-color="white"
                >
                  {{ item.status.name }}
                </v-chip>
              </template>
              <template #[`item.finished_good.sqft`]="{ item }">
                {{ item && item.finished_good && item.finished_good.sqft ? `${item.finished_good.sqft.toFixed(2)} sqft` : '-' }}
              </template>
              <template #[`item.finished_good.width`]="{ item }">
                {{ item && item.finished_good && item.finished_good.width ? `${displayInchesWithFraction(item.finished_good.width)}` : '-' }}
              </template>
              <template #[`item.finished_good.height`]="{ item }">
                {{ item && item.finished_good && item.finished_good.height ? `${displayInchesWithFraction(item.finished_good.height)}` : '-' }}
              </template>
              <template #[`item.finished_good.weight`]="{ item }">
                <span v-if="item.finished_good.sqft">
                  {{ parseFloat(getWeight(item)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}
                </span>
                <span v-else>
                  <v-tooltip left>
                    <template #activator="{ on }">
                      <span v-on="on" style="color: red; font-weight: bold;">!</span>
                    </template>
                    <span> Missing Sqft; Unable to calculate weight </span>
                  </v-tooltip>
                </span>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="!returnItem && !isOpen"
                  class="ml-1 mt-1 mb-1"
                  color="error"
                  outlined
                  small
                  @click="removeTag(item)"
                >
                  REMOVE
                </v-btn>
                <v-btn
                  v-if="returnItem"
                  class="ml-1 mt-1 mb-1"
                  color="error"
                  outlined
                  small
                  @click="toggleReturnTag(item)"
                >
                  {{ item.is_return || (item.status_id === finishedGoodStatusIdReturned) ? 'UNDO RETURN' : 'RETURN' }}
                </v-btn>
              </template>
              <template slot="body.append">
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th class="text-end pr-1">
                    <span style="color: #000000; font-weight: bold; font-size: 14px;">Totals: </span>
                  </th>
                  <th class="text-end">
                    <span style="font-weight: bold; font-size: 14px;">{{ parseFloat(getTotalSqft()).toLocaleString('en-US', { maximumFractionDigits: 2 }) }} sqft</span>
                  </th>
                  <th class="text-end">
                    <span style="font-weight: bold; font-size: 14px;">{{ displayInchesWithFraction(getTotalWidth()) }}</span>
                  </th>
                  <th class="text-end">
                    <span style="font-weight: bold; font-size: 14px;">{{ displayInchesWithFraction(getTotalHeight()) }}</span>
                  </th>
                  <th class="text-end">
                    <span style="font-weight: bold; font-size: 14px;">{{ parseFloat(getTotalWeightKilograms()).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}</span>
                  </th>
                  <th />
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <bill-of-lading-extra-item-form
            ref="billOfLadingExtraItemForm"
            :return-item="returnItem"
            :bill-of-lading-qr="billOfLadingQr"
            :is-bill-of-lading-open="isOpen"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!billOfLadingQr"
        align="center"
        justify="center"
        style="background-color: #f9f9f9"
        class="mt-6"
      >
        <v-col
          cols="1"
          class="mt-4 pb-0"
        >
          <v-text-field
            v-model="item.delivery_number"
            :disabled="returnItem || isOpen"
            :rules="[(v) => !v || v.length <= 45 || 'Max 45 characters']"
          >
            <template slot="label">
              Delivery #
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="2"
          class="mt-4 pb-0"
        >
          <v-select
            v-model="truckTypeId"
            label="Truck Type"
            :items="truckTypes"
            item-text="name"
            item-value="id"
            :disabled="returnItem || isOpen"
            clearable
            @change="handleTruckTypeChange"
          >
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : 'white'"
              >
                {{ item ? item.name : '' }}
              </v-chip>
              <span class="ml-1">
                {{ item && item.weight_limit ? `(${item.weight_limit} kg)` : '(Weight Limit Not Available)' }}
              </span>
            </template>
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : 'white'"
              >
                {{ item ? item.name : '' }}
              </v-chip>
              <span class="ml-1">
                {{ item && item.weight_limit ? `(${item.weight_limit} kg)` : '(Weight Limit Not Available)' }}
              </span>
            </template>
            <template slot="label">
              Truck Type
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="2"
          class="mt-4 pb-0"
        >
          <v-autocomplete
            v-model="item.truck_schedule_truck_id"
            :disabled="!truckTypeId || returnItem || isOpen"
            :items="trucks"
            :item-text="
              (item) =>
                `${item.name} (${item.truck_number ? item.truck_number : '-'})`
            "
            item-value="id"
            :loading="loadingTrucks"
            clearable
          >
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : 'white'"
              >
                {{ item ? item.name : '' }}
              </v-chip>
              <span class="ml-1">
                {{ item && item.truck_number ? `(${item.truck_number})` : '(Truck Number Not Available)' }}
              </span>
            </template>
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : 'white'"
              >
                {{ item ? item.name : '' }}
              </v-chip>
              <span class="ml-1">
                {{ item && item.truck_number ? `(${item.truck_number})` : '(Truck Number Not Available)' }}
              </span>
            </template>
            <template slot="label">
              Truck Number
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="3"
          class="mt-4 pb-0"
        >
          <v-autocomplete
            v-model="item.job_id"
            label="Job"
            :items="jobs"
            :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
            item-value="id"
            :loading="loadingJobs"
            :disabled="returnItem || item.bill_of_lading_items.length > 0 || isOpen"
            :rules="[(v) => !!v || 'Job is required']"
            clearable
            :single-select="false"
          >
            <template #selection="{ item }">
              <v-chip
                v-if="item.sub_class"
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item && item.sub_class ? item.sub_class.color : 'white'"
              >
                {{ item && item.sub_class ? item.sub_class.name : '' }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <template #item="{ item }">
              <v-chip
                v-if="item.sub_class"
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item && item.sub_class ? item.sub_class.color : 'white'"
              >
                {{ item && item.sub_class ? item.sub_class.name : '' }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <template slot="label">
              Job<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="returnItem"
          cols="3"
          class="mt-4 pb-0"
        >
          <v-text-field
            v-model="item.return_reason"
            :rules="[(v) => !!v || 'Return Reason is required', (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
          >
            <template slot="label">
              Return Reason<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          v-else
          cols="3"
          class="mt-4 pb-0"
        >
          <vuetify-google-autocomplete
            id="google"
            ref="google"
            v-model="item.ship_to_address"
            :disabled="returnItem || isOpen"
            :rules="[(v) => !!v || 'Ship To Address is required']"
            classname="form-control"
            :country="'ca'"
            @placechanged="getAddressData"
          >
            <template slot="label">
              Ship To Address<RedAsterisk />
            </template>
          </vuetify-google-autocomplete>
        </v-col>
      </v-row>
      <confirm-form ref="confirmForm" />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BillOfLadingExtraItemForm from '@/components/forms/billOfLadings/BillOfLadingExtraItemForm';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    BillOfLadingExtraItemForm,
    VuetifyGoogleAutocomplete,
  },
  props: {
  },
  data: () => ({
    item: null,
    itemDto: {
      // id: 0,
      job_id: null,
      truck_schedule_truck_id: null,
      bill_of_lading_items: [],
      bill_of_lading_extra_items: [],
      ship_to_address: null,
      return_reason: null,
      delivery_number: null,
    },
    tagQr: null,
    truckTypeId: null,

    truckTypes: [],
    trucks: [],
    jobs: [],
    extraItems: [],

    loading: false,
    loadingTruckTypes: false,
    loadingTrucks: false,
    loadingJobs: false,
    uomSwitch: true,
    returnItem: false,
    billOfLadingQr: false,
    isShippedState: false,
    isReceivedState: false,
    isReturnedState: false,
    isOpen: false,

    finishedGoodStatusIdShipped: 9,
    finishedGoodStatusIdReceived: 10,
    finishedGoodStatusIdToBeReturned: 11,
    finishedGoodStatusIdReturned: 12,
    billOfLadingStatusIdShipped: 1,
    billOfLadingStatusIdReceived: 2,
    billOfLadingStatusIdReturned: 3,
    timeoutId: null,
    finishedGoodStatusShipped: {
      code: 'SHIPPED',
      color: '#FBC02D',
      id: 9,
      name: 'SHIPPED',
    },
    finishedGoodStatusReceived: {
      code: 'RECEIVED',
      color: '#388E3C',
      id: 10,
      name: 'RECEIVED',
    },
    finishedGoodStatusToBeReturned: {
      code: 'TO BE RETURNED',
      color: '#B21CB7',
      id: 11,
      name: 'TO BE RETURNED',
    },
    tableHeight: 600,
    topSpaceHeight: 240,
  }),
  watch: {
    'this.$refs.billOfLadingExtraItemForm.items': {
      handler(newItems) {
        this.totalWeightKilograms(newItems);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([]),
    headers() {
      const headers = [
        { text: 'Tag ID', value: 'finished_good.tag_id', sortable: false },
        {
          text: 'Tag #', fullText: 'Window Number on Drawing', value: 'finished_good.tag_number', sortable: false,
        },
        {
          text: 'WO Serial#', fullText: 'Line Serial Number on Work Order', value: 'serial_number', sortable: false,
        },
        { text: 'Work Order', value: 'finished_good.work_order', sortable: false },
        { text: 'Reference', value: 'finished_good.floor_number', sortable: false },
        {
          text: 'Square Feet', value: 'finished_good.sqft', align: 'end', width: '10%', sortable: false,
        },
        {
          text: 'Width (inches)', value: 'finished_good.width', align: 'end', width: '10%', sortable: false,
        },
        {
          text: 'Height (inches)', value: 'finished_good.height', align: 'end', width: '10%', sortable: false,
        },
        {
          text: 'Weight (kg)', value: 'finished_good.weight', align: 'end', width: '10%', sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
      ];

      if (this.hasReturnedItem) {
        headers.push({
          text: 'Return Reason',
          value: 'return_reason',
          sortable: false,
        });
      }

      if (!this.billOfLadingQr) {
        headers.push({
          text: 'Actions',
          value: 'actions',
          align: 'end',
          sortable: false,
        });
      }

      return headers;
    },

    hasReturnedItem() {
      if (!this.item || !this.item.bill_of_lading_items || this.item.bill_of_lading_items.length === 0) {
        return false;
      }

      return this.item.bill_of_lading_items.some(
        (item) => item.status_id === this.finishedGoodStatusIdReturned,
      );
    },
  },
  watch: {
  },
  created() {
    this.item = JSON.parse(JSON.stringify(this.itemDto));
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {
      if (this.truckTypes.length === 0) {
        this.getTruckTypes();
      }
      if (this.jobs.length === 0) {
        this.getJobs();
      }
      // this.getPoItems();
    },

    async open(id = null, returnItem = false, billOfLadingQr = false, isOpen = false) {
      this.$bus.$emit('loading');
      this.$refs.dialogBox.open(); // for some reason need to add await, otherwise user's location won't be set in v-autocomplete by default
      this.returnItem = returnItem;
      this.billOfLadingQr = billOfLadingQr;
      this.isOpen = isOpen;
      if (this.billOfLadingQr) {
        id = this.extractIdFromQrCode(id);
        if (!id) {
          this.$refs.dialogBox.close();
          this.$bus.$emit('loading-stop');
          this.$bus.$emit('showError', 'QR code is incorrect');
          return;
        }
      }
      if (this.jobs.length === 0) {
        this.getJobs();
      }
      if (id) {
        const res = await this.get(id);
        if (!res) {
          this.$refs.dialogBox.close();
          this.$bus.$emit('loading-stop');
          this.$bus.$emit('showError', 'Bill of lading not found');
          return;
        }
      } else {
        this.item = JSON.parse(JSON.stringify(this.itemDto));
      }
      this.setFormState();
      this.$bus.$emit('loading-stop');
      this.$nextTick(async () => {
        if (this.$refs.billOfLadingExtraItemForm) {
          this.$refs.billOfLadingExtraItemForm.items = [];
          if (id) {
            this.$refs.billOfLadingExtraItemForm.get(id);
          }
          this.$refs.billOfLadingExtraItemForm.getExtraItemTypes();
        }
      });
    },

    setFormState() {
      if (!this.item) return;
      this.isShippedState = false;
      this.isReceivedState = false;
      this.isReturnedState = false;
      if (this.item.status_id === this.billOfLadingStatusIdShipped) {
        this.isShippedState = true;
      } else if (this.item.status_id === this.billOfLadingStatusIdReceived) {
        this.isReceivedState = true;
      } else if (this.item.status_id === this.billOfLadingStatusIdReturned) {
        this.isReturnedState = true;
      }
    },

    async get(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.billOfLading.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || res.data.length === 0) {
        return false;
      }
      const firstIndex = 0;
      this.item = JSON.parse(JSON.stringify(res.data[firstIndex]));

      this.truckTypeId = this.item.truck && this.item.truck.truck_type_id ? this.item.truck.truck_type_id : null;

      if (this.item.bill_of_lading_items.length > 0) {
        this.item.bill_of_lading_items.forEach((item, index) => {
          this.item.bill_of_lading_items[index].index = index;
        });
      }

      this.getTrucks();

      this.$nextTick(async () => {
        if (this.$refs.billOfLadingExtraItemForm) {
          this.$refs.billOfLadingExtraItemForm.get(id);
          this.$refs.billOfLadingExtraItemForm.isOpen = true;
        }
      });

      return true;
    },

    async getTruckTypes() {
      this.loadingTruckTypes = true;

      const res = await this.$api.truckScheduleTruckType.get();
      if (!res || !res.length) {
        this.$bus.$emit('showError', 'Unable to retrieve truck types');
        this.loadingTruckTypes = false;
        return;
      }

      this.truckTypes = res;

      this.loadingTruckTypes = false;
    },

    async getTrucks() {
      this.loadingTrucks = true;

      const submitModel = {
        filters: {
          truck_type_id: this.truckTypeId,
        },
      };

      const res = await this.$api.truckScheduleTruck.read(submitModel);
      if (!res) {
        this.$bus.$emit('showError', 'Unable to retrieve trucks');
        this.loadingTrucks = false;
        return;
      }

      this.trucks = res;

      this.loadingTrucks = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: {
          // inventory_class_id: this.inventoryClassId,
          // is_sub_class: 1
          is_manual: 0,
        },
      };
      const res = await this.$api.job.readDataSecondary(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
      this.loadingJobs = false;
    },

    async submit() {
      // uncomment when truck is mandatory
      if (!this.returnItem && this.item.truck_schedule_truck_id && this.isTruckOverweight()) {
        this.$bus.$emit('showError', 'Truck is overweight');
        return;
      }
      this.$bus.$emit('loading');
      let res = false;

      if (this.returnItem) {
        res = await this.$api.billOfLading.store(this.item, 'return-window');
        if (!res) {
          this.$bus.$emit('loading-stop');
          this.$bus.$emit('showError', 'Error returning windows');
          return false;
        }
      } else {
        if (this.$refs.billOfLadingExtraItemForm) {
          this.item.bill_of_lading_extra_items = this.$refs.billOfLadingExtraItemForm.items;
        }

        if (this.item.id) {
          res = await this.$api.billOfLading.update(this.item);
        } else {
          res = await this.$api.billOfLading.store(this.item);
        }
        if (!res) {
          this.$bus.$emit('loading-stop');
          this.$bus.$emit('showError', 'Error submitting bill of lading');
          return false;
        }
      }

      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return true;
    },

    async submitQr() {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${this.isShippedState ? 'receive' : 'undo receive'} this bill of lading?`,
      });
      if (!confirm) return false;
      this.$bus.$emit('loading');
      const res = await this.$api.billOfLading.get(`toggle/${this.item.id}`);
      if (!res) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return false;
      }
      this.$bus.$emit('loading');
      this.$bus.$emit('showSuccess');
      return true;
    },

    handleTruckTypeChange() {
      this.getTrucks();
      this.item.truck_schedule_truck_id = null;
    },

    handleTagAction() {
      this.returnItem ? this.returnTag() : this.addTag();
    },

    async addTag() {
      this.$bus.$emit('loading');

      const submitModel = {
        filters: {
          tag_id: this.tagQr,
        },
      };
      const res = await this.$api.finishedGood.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || res.length === 0) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Tag is not found or incorrect', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }

      const finishedGood = res[0];
      if (this.isScanned(finishedGood)) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Tag has already been scanned', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }
      if (
        this.item.bill_of_lading_items.length > 0
        && !this.isSameJob(finishedGood)
      ) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Tag is from a different project', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }

      const billOfLadingId = await this.isTagAssigned(finishedGood);
      if (billOfLadingId === undefined) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Error determining if tag is assigned to another bill of lading', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }
      if (billOfLadingId) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', `Window is under - Bill Of Lading #${billOfLadingId}`, null, 'xl', 2500);
        this.tagQr = null;
        return;
      }
      if (finishedGood.status_id === this.finishedGoodStatusIdShipped) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Tag has already been shipped', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }
      if (finishedGood.status_id === this.finishedGoodStatusIdReceived) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Tag has already been received', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }
      if (!finishedGood.type) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Finished good type is not set - unable to calculate weight', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }

      this.item.bill_of_lading_items.unshift({ finished_good: finishedGood, status: finishedGood.status });

      this.item.job_id = finishedGood.production_schedule && finishedGood.production_schedule.job_id ? finishedGood.production_schedule.job_id : null;

      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.tagQr = null;
    },

    async returnTag() {
      this.$bus.$emit('loading');
      const submitModel = {
        filters: {
          tag_id: this.tagQr,
        },
      };
      const res = await this.$api.finishedGood.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || res.length === 0) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Tag is not found or incorrect', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }

      const finishedGood = res[0];

      if (this.isReturned(finishedGood)) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Tag has already been returned', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }

      const billOfLadingId = await this.isTagAssigned(finishedGood);
      if (billOfLadingId === undefined) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Error determining if tag is assigned to a bill of lading', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }
      if (!billOfLadingId) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Window has not been assigned to a Bill Of Lading', null, 'xl', 2500);
        this.tagQr = null;
        return;
      }

      if (!this.item.id) {
        await this.get(billOfLadingId);
      }

      const billOfLadingItemIndex = this.item.bill_of_lading_items.findIndex((x) => x.finished_good_id === finishedGood.id);

      this.$set(this.item.bill_of_lading_items[billOfLadingItemIndex], 'is_return', 1);
      // set old status to current status
      this.item.bill_of_lading_items[billOfLadingItemIndex].old_status = this.item.bill_of_lading_items[billOfLadingItemIndex].status;
      this.setBillOfLadingItemStatus(billOfLadingItemIndex, this.finishedGoodStatusIdToBeReturned);

      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.tagQr = null;
    },

    // addExtraItem(item) {
    //   if (!item) return;
    //   console.log('here: ', item);
    //   const index = this.item.bill_of_lading_extra_items.findIndex((x) => x.temp_id === item.temp_id);
    //   if (index >= 0) {
    //     this.item.bill_of_lading_extra_items.splice(index, 1, item);
    //   } else {
    //     this.item.bill_of_lading_extra_items.push(item);
    //   }
    // },

    // removeExtraItem(item) {
    //   if (!item) return;
    //   console.log('removeExtraItem: ', item);
    //   let index;
    //   if (item.temp_id) {
    //     index = this.item.bill_of_lading_extra_items.findIndex((x) => x.temp_id === item.temp_id);
    //   } else {
    //     index = this.item.bill_of_lading_extra_items.findIndex((x) => x.id === item.id);
    //   }
    //   this.item.bill_of_lading_extra_items.splice(index, 1);
    // },

    openExtraItemForm() {
      this.$refs.billOfLadingExtraItemForm.open();
    },

    isScanned(item) {
      const index = this.item.bill_of_lading_items.findIndex(
        (x) => x.finished_good.id === item.id,
      );
      return index >= 0;
    },

    isSameJob(item) {
      const firstItem = this.item.bill_of_lading_items[0];

      const firstItemJobId = firstItem.finished_good.production_schedule.job_id;
      const addedItemJobId = item.production_schedule.job_id;

      return firstItemJobId === addedItemJobId;
    },

    async isTagAssigned(item) {
      // check if tag is already under a bill of lading and is shipped or received (could be under a bill of lading, but returned - in which case we can still add it to another bill of lading)
      const readModel = {
        filters: {
          finished_good_id: item.id,
          status_id: [this.finishedGoodStatusIdShipped, this.finishedGoodStatusIdReceived],
        },
      };

      const res = await this.$api.billOfLadingItem.read(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return undefined;
      if (!res || !res.data) {
        return undefined;
      }

      if (res.total >= 1) {
        const firstIndex = 0;
        return res.data[firstIndex].bill_of_lading_id;
      }

      return null;
    },

    isReturned(item) {
      return item.status_id === this.finishedGoodStatusIdReturned;
    },

    async removeTag(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (!confirm) return;
      if (item.is_return) {
        const itemIndex = this.item.bill_of_lading_items.findIndex((x) => x.finished_good.id === item.finished_good_id);
        if (itemIndex >= 0) {
          this.item.bill_of_lading_items[itemIndex].is_return = 0;
        }
      } else {
        const itemIndex = this.item.bill_of_lading_items.findIndex((x) => x.finished_good.id === item.finished_good.id);
        if (itemIndex >= 0) {
          this.item.bill_of_lading_items.splice(itemIndex, 1);
        }
      }
    },

    async toggleReturnTag(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure you want to ${item.is_return ? 'undo return?' : 'return this record?'}`,
      });
      if (!confirm) return;
      const billOfLadingItemIndex = this.item.bill_of_lading_items.findIndex((x) => x.finished_good.id === item.finished_good_id);
      if (billOfLadingItemIndex < 0) {
        this.$bus.$emit('showError', 'Unable to find item');
        return;
      }
      if (item.is_return) {
        // if a BOL exists with this item as shipped or received do not allow undo return
        const readModel = {
          filters: {
            finished_good_id: this.item.bill_of_lading_items[billOfLadingItemIndex].finished_good_id,
            status_id: [this.finishedGoodStatusIdShipped, this.finishedGoodStatusIdReceived],
          },
        };
        const res = await this.$api.billOfLadingItem.read(readModel);
        if (!res || !res.data) {
          this.$bus.$emit('showError', 'Unable to determine if this finished good exists in another Bill Of Lading as Shipped or Received');
          return;
        }
        if (res.data.length > 0) {
          const billOfLadingItems = res.data;
          const shippedOrReceivedBillOfLadingItemExists = billOfLadingItems.some((billOfLadingItem) => billOfLadingItem.id !== item.id);
          if (shippedOrReceivedBillOfLadingItemExists) {
            const billOfLadingItemStatus = res.data[0].status.name;
            const billOfLadingCode = res.data[0].bill_of_lading.code;
            this.$bus.$emit('showError', `This finished good is ${billOfLadingItemStatus} in Bill Of Lading #${billOfLadingCode}`);
            return;
          }
        }
      }
      if (item.status_id === this.finishedGoodStatusIdReturned) {
        if (!this.item.bill_of_lading_items[billOfLadingItemIndex].old_status) {
          this.item.bill_of_lading_items[billOfLadingItemIndex].old_status = this.item.bill_of_lading_items[billOfLadingItemIndex].status;
        }
        if (this.item.status_id === this.billOfLadingStatusIdReceived) {
          this.setBillOfLadingItemStatus(billOfLadingItemIndex, this.finishedGoodStatusIdReceived);
        } else if (this.item.status_id === this.billOfLadingStatusIdShipped) {
          this.setBillOfLadingItemStatus(billOfLadingItemIndex, this.finishedGoodStatusIdShipped);
        }
        this.$set(this.item.bill_of_lading_items[billOfLadingItemIndex], 'is_return', 0);
      } else if (item.old_status && item.old_status.id === this.finishedGoodStatusIdReturned) {
        this.item.bill_of_lading_items[billOfLadingItemIndex].status = this.item.bill_of_lading_items[billOfLadingItemIndex].old_status;
        this.item.bill_of_lading_items[billOfLadingItemIndex].status_id = this.item.bill_of_lading_items[billOfLadingItemIndex].old_status.id;
        delete this.item.bill_of_lading_items[billOfLadingItemIndex].is_return;
      } else if (item.is_return) {
        this.item.bill_of_lading_items[billOfLadingItemIndex].status = this.item.bill_of_lading_items[billOfLadingItemIndex].old_status;
        this.item.bill_of_lading_items[billOfLadingItemIndex].status_id = this.item.bill_of_lading_items[billOfLadingItemIndex].old_status.id;
        this.$set(this.item.bill_of_lading_items[billOfLadingItemIndex], 'is_return', 0);
      } else {
        // if no old status, then set old status to status from db else do nothing
        if (!this.item.bill_of_lading_items[billOfLadingItemIndex].old_status) {
          this.item.bill_of_lading_items[billOfLadingItemIndex].old_status = this.item.bill_of_lading_items[billOfLadingItemIndex].status;
        }
        this.$set(this.item.bill_of_lading_items[billOfLadingItemIndex], 'is_return', 1);
        this.setBillOfLadingItemStatus(billOfLadingItemIndex, this.finishedGoodStatusIdToBeReturned);
      }
    },

    setBillOfLadingItemStatus(index, statusId) {
      const item = this.item.bill_of_lading_items[index];
      let status = null;

      switch (statusId) {
        case this.finishedGoodStatusIdToBeReturned:
          status = this.finishedGoodStatusToBeReturned;
          break;
        case this.finishedGoodStatusIdReceived:
          status = this.finishedGoodStatusReceived;
          break;
        case this.finishedGoodStatusIdShipped:
          status = this.finishedGoodStatusShipped;
          break;
        default:
          return;
      }

      item.status_id = statusId;
      item.status = { ...status };
    },

    extractSerialNumber(item) {
      const tagId = item.finished_good.tag_id;
      const serialNumber = tagId.match(/(\d+)$/);
      return serialNumber ? serialNumber[0] : '-';
    },

    extractIdFromQrCode(qrCode) {
      const match = qrCode.match(/^b(0*[^0]\d*)/i);
      return match ? match[1] : false;
    },

    qrCodeValueChange() {
      if (!this.tagQr) return;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.handleTagAction();
      }, 700);
    },

    headlineText() {
      if (this.item.id) {
        return 'Update';
      }
      return 'Create';
    },

    submitBtnText() {
      if (this.billOfLadingQr && this.isReceivedState) {
        return 'Undo Receive';
      }
      if (this.billOfLadingQr && this.isShippedState) {
        return 'Receive';
      }
      if (this.returnItem) {
        return 'Submit';
      }
      if (this.item.id) {
        return 'Update';
      }
      return 'Submit';
    },

    isSubmitBtn() {
      if (this.isOpen || this.isReturnedState) {
        return false;
      }
      return true;
    },

    getWeight(item) {
      return item.finished_good.sqft * item.finished_good.type.weight_ratio;
    },

    getTotalSqft() {
      return this.item.bill_of_lading_items.reduce((acc, item) => acc + parseFloat(item.finished_good.sqft ? item.finished_good.sqft : 0), 0);
    },

    getTotalWidth() {
      return this.item.bill_of_lading_items.reduce((acc, item) => acc + parseFloat(item.finished_good.width ? item.finished_good.width : 0), 0);
    },

    getTotalHeight() {
      return this.item.bill_of_lading_items.reduce((acc, item) => acc + parseFloat(item.finished_good.height ? item.finished_good.height : 0), 0);
    },

    getTotalWeightKilograms() {
      return this.item.bill_of_lading_items.reduce((acc, item) => acc + (parseFloat(item.finished_good.type.weight_ratio ? item.finished_good.type.weight_ratio : 0) * parseFloat(item.finished_good.sqft ? item.finished_good.sqft: 0)), 0);
    },

    getAllTotalWeightKilograms() {
      let total = this.getTotalWeightKilograms();
      const extraItemsTotalWeight = this.$refs.billOfLadingExtraItemForm ? this.$refs.billOfLadingExtraItemForm.getTotalWeightKilograms() : 0;
      total += extraItemsTotalWeight;
      return total;
    },

    getAllTotalWeightPounds() {
      return this.getAllTotalWeightKilograms() * 2.20462;
    },

    getTotalWeightColor() {
      let truckWeight = 0;

      if (this.item.truck_schedule_truck_id) {
        const truck = this.trucks.find((truck) => truck.id === this.item.truck_schedule_truck_id);
        if (!truck) return 'red';
        truckWeight = truck.type.weight_limit;
      } else if (this.truckTypeId) {
        const truckType = this.truckTypes.find((truckType) => truckType.id === this.truckTypeId);
        if (!truckType) return 'red';
        truckWeight = parseFloat(truckType.weight_limit);
      } else {
        return '#000';
      }

      return truckWeight > this.getAllTotalWeightKilograms() ? '#000' : 'red';
    },

    isTruckOverweight() {
      let truckWeight = 0;

      if (this.item.truck_schedule_truck_id) {
        const truck = this.trucks.find((truck) => truck.id === this.item.truck_schedule_truck_id);
        if (!truck) return 'red';
        truckWeight = truck.type.weight_limit;
      }

      if (this.getAllTotalWeightKilograms() > truckWeight) {
        return true;
      }
      return false;
    },

    convertMillimetersToInches(millimeters) {
      return millimeters / 25.4;
    },

    displayInchesWithFraction(inches) {
      // Convert height to the raw number with rounding
      const raw = Math.round(inches / 0.0625) * 0.0625;

      // Extract the whole number part
      const wholeNumber = Math.floor(raw);

      // Calculate the decimal part
      const decInput = parseFloat((raw - wholeNumber).toFixed(4));

      // Initialize numerator and denominator
      let numerator = 1;
      let denominator = 1;

      // Calculate the fraction
      let decimalFraction = numerator / denominator;
      while (decimalFraction !== decInput) {
        if (decimalFraction < decInput) {
          numerator += 1;
        } else {
          denominator += 1;
          numerator = Math.floor(decInput * denominator);
        }
        decimalFraction = numerator / denominator;
      }

      // Create the final output string
      let result = '';
      if (wholeNumber > 0) {
        result += `${wholeNumber}"`;
      }

      if (numerator > 0) {
        result += `-${numerator}/${denominator}''`;
      }

      return result;
    },

    itemRowBackground(item) {
      return item.is_return || (item.status_id === this.finishedGoodStatusIdReturned) ? 'red-background' : '';
    },

    headlineText() {
      if (this.returnItem) {
        return this.item.id ? `Return Items: Bill Of Lading #${this.item.id}` : 'Return Items';
      }
      return this.item.id ? `Bill Of Lading #${this.item.id}` : 'Create Bill Of Lading';
    },

    formatDateTime(dateTime) {
      return FormatHelpers.convertDateTimeFormat(dateTime);
    },

    onResize() {
      this.tableHeight = Math.floor((window.innerHeight * (0.75))) - this.topSpaceHeight;
    },

    async getAddressData(addressData, placeData) {
      // console.log('addressData: ', addressData);
      // console.log('placeData: ', placeData);
      this.item.ship_to_address = placeData.formatted_address;
      // this.submitData.address.addressLine1 = `${addressData.street_number ? `${addressData.street_number} ` : ''}${addressData.route}`
      // if (addressData.postal_code) {
      //   this.submitData.address.postalCode = addressData.postal_code
      // } else if (placeData && placeData.address_components && placeData.address_components.some(x => x.types && x.types.some(y => y === 'postal_code' || y === 'postal_code_prefix'))) {
      //   this.submitData.address.postalCode = placeData.address_components.find(x => x.types.some(y => y === 'postal_code' || y === 'postal_code_prefix')).long_name
      // }
      // this.submitData.address.cityName = addressData.locality
      // this.submitData.address.province = addressData.administrative_area_level_1 // gives 2 character province code
      // this.submitData.address.googlePlaceId = addressData.place_id
      // this.submitData.address.latitude = addressData.latitude
      // this.submitData.address.longitude = addressData.longitude
      // this.submitData.address.country = addressData.country
    },
  },
};
</script>
<style scoped>
/deep/.red-background {
  background-color: #ffe3e3;
}
</style>
