<template>
  <div class="pa-2">
    <v-row>
      <v-col cols="3" />
      <v-col
        cols="6"
        md="6"
        sm="12"
        class="pb-0 pt-0"
        style="font-size: 30px;"
        align="center"
      >
        {{ title }}
      </v-col>
      <v-col
        cols="3"
        md="3"
        sm="12"
        align="end"
      >
        <v-btn
          v-if="!isTyping"
          color="primary"
          align="start"
          class="pa-4"
          outlined
          style="font-size: 1.1em;"
          @click="changeCamera"
        >
          Switch Back/Front Camera
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0 pb-0"
        align="center"
      >
        {{ secondTitle }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="3"
      />
      <v-col
        cols="6"
        align="center"
        md="6"
        sm="12"
      >
        <v-card
          elevation="0"
          height="400px"
          width="700px"
          :style="{backgroundColor: isDarkMode ? '#121212' : ''}"
        >
          <v-row v-if="isTyping">
            <v-col>
              <v-row>
                <v-col
                  class="ml-1 pb-1"
                  style="margin-top: 130px;"
                >
                  <h3 class="mb-5">
                    {{ textfieldTitle }}
                  </h3>

                  <v-row>
                    <v-col
                      cols="3"
                      class="pa-0"
                    />
                    <v-col
                      cols="6"
                      class="pa-0"
                    >
                      <v-text-field
                        v-if="isTyping"
                        v-model="qrCode"
                        outlined
                        style="font-size: 2em;"
                        :type="qrInputType"
                      />
                    </v-col>
                    <v-col cols="1" />
                    <v-col
                      cols="2"
                      class="pa-0"
                    >
                      <v-btn
                        class="pa-7"
                        style="font-size: 1.3em;"
                        color="success"
                        @click="manualEntered()"
                      >
                        Enter
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <qrcode-stream
            v-else
            :camera="camera"
            :track="paintBoundingBox"
            @decode="processResult"
            @init="onInit"
          >
            <canvas
              id="square-canvas"
              class="overlay-line"
            />
          </qrcode-stream>
        </v-card>
      </v-col>
      <v-col
        cols="3"
      />
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="auto">
        <v-btn
          class="pa-8"
          style="font-size: 1.5em;"
          color="primary"
          :outlined="isTyping ? true : false"
          @click="isTyping = false"
        >
          Scan
        </v-btn>
      </v-col>
      <v-col
        style="width: 50px !important;"
        cols="auto"
      />
      <v-col cols="auto">
        <v-btn
          class="pa-8"
          style="font-size: 1.5em;"
          color="primary"
          :outlined="isTyping ? false : true"
          @click="isTyping = true"
        >
          Type
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <canvas
      id="myCanvas"
      style="z-index: 100;"
    />
    <inventory-qr-form
      ref="tagForm"
    />
    <inventory-upc-form
      ref="upcForm"
    />
    <inventory-upc-new-form
      ref="newUpcForm"
      :entered-tag="qrCode"
      @submitQRCodeTag="submitQRCodeTag"
    />
    <inventory-qr-new-form
      ref="newQrForm"
      :entered-tag="qrCode"
      :is-show-p-o="true"
      :is-show-location="true"
      @submitQRCodeTag="submitQRCodeTag"
    />
    <InventoryMPQRForm
      ref="mpqrForm"
    />
    <glass-batch-qr-form
      ref="batchForm"
    />
    <glass-move-qr-form
      ref="glassMoveQr"
    />
    <window-qr-form
      ref="windowQr"
    />
    <bill-of-lading-form
      ref="billOfLadingForm"
    />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import { mapGetters, mapState } from 'vuex';
import InventoryQrForm from '@/components/forms/inventoryQrs/InventoryQrForm.vue';
import InventoryQrNewForm from '@/components/forms/inventoryQrs/InventoryQrNewForm.vue';
import InventoryUpcForm from '@/components/forms/inventoryQrs/InventoryUpcForm.vue';
import InventoryUpcNewForm from '@/components/forms/inventoryQrs/InventoryUpcNewForm.vue';
import InventoryMPQRForm from '@/components/forms/inventoryQrs/InventoryMPQRForm';
import GlassBatchQrForm from '@/components/forms/glasses/GlassBatchQrForm.vue';
import GlassMoveQrForm from '@/components/forms/glasses/GlassMoveQrForm.vue';
import WindowQrForm from '@/components/forms/windowQrs/WindowQrForm.vue';
import BillOfLadingForm from '@/components/forms/billOfLadings/BillOfLadingForm.vue';

export default {
  components: {
    QrcodeStream, InventoryQrForm, InventoryUpcForm, InventoryQrNewForm, InventoryUpcNewForm, InventoryMPQRForm, GlassBatchQrForm, GlassMoveQrForm, WindowQrForm, BillOfLadingForm,
  },
  props: {
    msg: {
      type: String,
      default: '',
    },
    qrType: {
      type: String,
      default: '',
    },
    isRmSupply: Boolean,
    isPaintSupply: Boolean,
    isMovingQrPartially: Boolean,
    isPartPickList: Boolean,
  },
  data: () => ({
    loading: false,
    camera: 'rear',
    cameraState: '',
    error: '',
    openDialog: false,
    isQrEqualsTag: false,
    isTyping: false,
    qrCode: '',
    inventoryTag: '',
    isInventoryQr: false,
    isPhysicalCountQr: false,
    isRmReceivingQr: false,
    isMovingQr: false,
    isBatchQr: false,
    isStlQr: false,
    isGlassMovingQr: false,
    isWindowQr: false,
    isBillOfLadingQr: false,
    textfieldTitle: 'QR/UPC/MPQR Code',
    secondTitle: 'Please place QR code into the green square',
    title: 'Scan QR',

  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
      'isPhysicalCountActivated',
    ]),
    ...mapState({
      isQRCodeScan: 'isQRCodeScan',
      isDarkMode: 'isDarkMode',
    }),
    validationPending() {
      return this.camera === 'off';
    },
    qrInputType() {
      const numberTypes = ['isInventoryQr', 'isPhysicalCountQr', 'isRmReceivingQr', 'isMovingQr', 'isBatchQr'];
      const textTypes = ['isBillOfLadingQr'];

      if (numberTypes.some((type) => this[type])) {
        return 'number';
      } if (textTypes.some((type) => this[type])) {
        return 'text';
      }
      return 'text';
    },
  },
  watch: {
    qrType: {
      immediate: true,
      handler(value) {
        if (!value) return;

        this.isInventoryQr = false;
        this.isPhysicalCountQr = false;
        this.isRmReceivingQr = false;
        this.isMovingQr = false;
        this.isBillOfLadingQr = false;
        this.qrCode = '';
        switch (value) {
          case 'inventory-qr':
            this.isInventoryQr = true;
            break;
          case 'physical-count-qr':
            this.isPhysicalCountQr = true;
            break;
          case 'rm-receiving-qr':
            this.isRmReceivingQr = true;
            break;
          case 'moving-qr':
            this.isMovingQr = true;
            break;
          case 'batch-qr':
            this.isBatchQr = true;
            break;
          case 'glass-move-qr':
            this.isGlassMovingQr = true;
            break;
          case 'window-qr':
            this.isWindowQr = true;
            break;
          case 'bill-of-lading-qr':
            this.isBillOfLadingQr = true;
            break;
          case 'stl-qr':
            this.isStlQr = true;
            break;
          default:
        }
        this.setTitle();
      },
    },

    isTyping(val) {
      if (val) this.turnCameraOff();
      else this.turnCameraOn();

      if (!val) {
        this.$nextTick(async () => {
          this.manualBoundingBox();
        });
      }
      this.setTitle();
      this.$store.commit('storeIsQRCodeScan', val);
    },

    error(message) {
      this.$bus.$emit('showError', message);
    },
  },
  created() {
    this.isTyping = this.isQRCodeScan;
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$nextTick();
      this.turnCameraOn();

      this.manualBoundingBox();

      const QrEqualsTagBRName = 'QR Code Equals Inv. Tag';
      this.isQrEqualsTag = this.businessRulesGetBoolean(QrEqualsTagBRName);
    },

    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        }
      } finally {
        this.loading = false;
      }
    },

    turnCameraOn() {
      this.camera = 'rear';
    },

    turnCameraOff() {
      this.cameraState = this.camera;
      this.camera = 'off';
    },

    changeCamera() {
      this.camera = this.camera === 'front' ? 'rear' : 'front';
    },

    manualBoundingBox() {
      const boxSizes = [];
      boxSizes.push({
        boundingBox: {
          x: 50.5, y: 10.5, width: 200, height: 130,
        },
      });
      const canvas = document.getElementById('square-canvas');
      const ctx = canvas.getContext('2d');
      this.paintBoundingBox(boxSizes, ctx);
    },

    paintBoundingBox(detectedCodes, ctx) {
      // eslint-disable-next-line no-restricted-syntax
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: {
            x, y, width, height,
          },
        } = detectedCode;

        ctx.lineWidth = 1;
        ctx.strokeStyle = '#5cb984';
        ctx.strokeRect(x, y, width, height);
      }
    },

    async processResult(result) {
      this.turnCameraOff();
      const resultArray = result.split('/');
      const qrCodeNumberIdx = 4;
      this.qrCode = resultArray[qrCodeNumberIdx];
      this.getQrCode();
    },

    async getQrCode() {
      if (!this.qrCode || this.qrCode === null) {
        this.$bus.$emit('showError', 'Unrecognized QR Code');
        return;
      }

      // Inventory Qr
      if (this.isInventoryQr && (parseInt(this.qrCode, 10) > 199999 && parseInt(this.qrCode, 10) < 209999)) { this.$router.push(`/inventory-mpqr/${this.qrCode}`); return; }
      if (this.isInventoryQr) this.$router.push(`/inventory-qr/${this.qrCode}`);

      // Physical Count
      if (this.isPhysicalCountQr && this.isPhysicalCountActivated) this.$router.push(`/physical-count-qr/${this.qrCode}`);

      // RM Receiving
      if (this.isRmReceivingQr) {
        // let isUPC = false;
        // // UPCs QR Codes: from 100000 to 199999
        // if (parseInt(this.qrCode, 10) > 99999 && parseInt(this.qrCode, 10) < 199999) {
        // // Include records with qty on hand 0 for INSTALATION Class records
        //   isUPC = true;
        // }
        // if (parseInt(this.qrCode, 10) > 199999 && parseInt(this.qrCode, 10) < 209999) {
        // // Include records with qty on hand 0 for INSTALATION Class records
        //   isUPC = true;
        // }
        const submitModel = {
          filters: {
            inventory_tag: this.qrCode,
            is_qty_on_hand_o: 0,
            is_user_location: true,
          },
        };
        const res = await this.$api.inventoryTag.read(submitModel);
        if (res && res.status === 505) {
          this.$bus.$emit('showError', res.message);
          this.isActivatedVisible = true;
          return;
        }

        // UPCs QR Codes: from 100000 to 199999
        if (parseInt(this.qrCode, 10) > 99999 && parseInt(this.qrCode, 10) < 199999) {
        // Include records with qty on hand 0 for INSTALATION Class records
          if (!res || !res.data || !res.data[0]) {
            this.$refs.newUpcForm.openDialog();
          } else { this.$refs.upcForm.open(this.qrCode); }
        } else if (parseInt(this.qrCode, 10) > 199999 && parseInt(this.qrCode, 10) < 209999) {
        // Include records with qty on hand 0 for INSTALATION Class records
          this.$refs.mpqrForm.open(this.qrCode);
        } else if (!res || !res.data || !res.data[0]) {
          this.$refs.newQrForm.openDialog();
        } else {
          this.$refs.tagForm.open(this.qrCode);
        }
      }

      // Moving QR
      if (this.isMovingQr) {
        if ((parseInt(this.qrCode, 10) > 199999 && parseInt(this.qrCode, 10) < 209999)) {
          this.$router.push(`/moving-qr/mpqr/${this.qrCode}`);
          return;
        }
        this.$router.push(`/moving-qr/${this.qrCode}`);
      }

      // return to Supply to Line, Supply to Paint, Part Pick List
      if (this.isRmSupply || this.isPaintSupply || this.isPartPickList) {
        const { qrCode } = this;
        this.$emit('return-qr-code', qrCode);
        this.$nextTick(async () => {
          this.qrCode = '';
        });
      }

      // return to Moving Qr if moving partially
      if (this.isMovingQrPartially) {
        const { qrCode } = this;
        this.$emit('return-qr-code-move', qrCode);
        this.$nextTick(async () => {
          this.qrCode = '';
        });
      }

      if (this.isBatchQr) {
        this.$refs.batchForm.open(this.qrCode);
      }

      if (this.isGlassMovingQr) { this.$refs.glassMoveQr.open(this.qrCode); }
      if (this.isBillOfLadingQr) {
        this.$refs.billOfLadingForm.open(this.qrCode, false, this.isBillOfLadingQr);
      }

      if (this.isWindowQr) {
        this.$refs.windowQr.open(this.qrCode);
      }
      if (this.isStlQr) {
        this.$router.push(`/stl-qr/${this.qrCode}`);
      }

      if (this.isBillOfLadingQr) {
        const submitModel = {
          filters: {
            code: this.qrCode,
          },
        };
        const res = await this.$api.billOfLading.read(submitModel);
        const cancelCode = 3001;
        if (res === cancelCode) return;
        if (!res || !res.data || res.data.length === 0) {
          this.$bus.$emit('showError', 'Bill of lading not found');
          return;
        }
        this.$refs.billOfLadingForm.open(this.qrCode, false, this.isBillOfLadingQr);
      }
    },

    manualEntered() {
      this.getQrCode();
    },

    async submitQRCodeTag(msg) {
      if (!this.isRmReceivingQr) return;

      this.$bus.$emit('showSuccess', msg);

      if (this.isTyping) this.qrCode = '';

      else this.turnCameraOn();
    },

    async setTitle() {
      if (this.isMovingQr || this.isRmSupply || this.isPaintSupply || this.isPartPickList || this.isMovingQrPartially) {
        this.title = this.isTyping ? 'Type QR/UPC' : 'Scan QR/UPC';
        this.secondTitle = this.isTyping ? 'Please type QR/UPC Code into the field' : 'Please place QR/UPC code into the green square';
        return;
      }
      if (this.isBatchQr) {
        this.title = 'Type Batch QR Code';
        this.secondTitle = 'Please type Batch QR Code into the field';
        this.textfieldTitle = 'Batch QR Code';
        return;
      }
      if (this.isGlassMovingQr) {
        this.title = 'Glass Reject / Rework QR';
        this.secondTitle = 'Please place QR code into the green square';
        this.textfieldTitle = 'Glass QR Code';
        return;
      }
      if (this.isWindowQr) {
        this.title = 'Production QR';
        this.secondTitle = 'Please place QR code into the green square';
        this.textfieldTitle = 'Production QR Code';
        return;
      }
      if (this.isStlQr) {
        this.title = 'Supply to Line QR';
        this.secondTitle = 'Please place QR code into the green square';
        return;
      }
      if (this.isBillOfLadingQr) {
        this.title = 'Type Bill Of Lading QR Code';
        this.secondTitle = 'Please type Bill Of Lading QR Code into the field';
        this.textfieldTitle = 'Bill Of Lading QR Code';
        return;
      }
      switch (this.qrType) {
        case 'inventory-qr':
          this.title = 'Inventory QR';
          if (this.isTyping) { this.secondTitle = 'Please type QR Code number of Inventory Item'; } else this.secondTitle = 'Please scan QR Code of Inventory Item';
          break;
        case 'physical-count-qr':
          await this.getActivePhysicalCountList();
          this.title = `Physical Count (${this.physicalCountList ? this.physicalCountList.name : ''}, as of ${new Date(`${this.physicalCountList ? this.physicalCountList.date : ''} 00:00`).toDateString().substring(4)})`;
          if (this.isTyping) { this.secondTitle = 'Please type QR Code number of Inventory Item that needs to be counted'; } else this.secondTitle = 'Please scan QR Code of Inventory Item that needs to be counted';
          break;
        case 'rm-receiving-qr':
          this.title = 'Raw Materials Receiving';
          if (this.isTyping) { this.secondTitle = 'Please attach the unused QR Code label to Receiving bundle and type its number'; } else this.secondTitle = 'Please attach the unused QR Code label to Receiving bundle and scan it';
          break;
        case 'moving-qr':
          this.title = 'Inventory Item Move between locations/shelfs';
          if (this.isTyping) { this.secondTitle = 'Please type QR Code number of Inventory Item that needs to be moved'; } else this.secondTitle = 'Please scan QR Code of Inventory Item that needs to be moved';
          break;
        default:
          this.title = '';
          this.secondTitle = '';
          break;
      }
    },

    async getActivePhysicalCountList() {
      this.physicalCountList = await this.$api.physicalCountList.getActive();
    },
  },
};
</script>
<style scoped>
.overlay-line {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.v-text-field--outlined .v-input__prepend-outer, .v-text-field--outlined .v-input__append-outer {
    margin-top: 0px;
}
.v-application--is-ltr .v-input__append-outer {
    margin-left: 1px;
}

</style>
