<template>
  <v-container
    class="mt-3"
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-row>
          <v-col
            cols="auto"
            class="pb-0 pr-0"
          >
            <v-btn
              outlined
              color="primary"
              class="mr-4"
              @click="setToday"
            >
              Today
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pt-4 pr-0 pl-0"
          >
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="2">
        <v-select
          v-model="type"
          :items="types"
          dense
          hide-details
          label="Type"
          item-value="type"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="2"
        class="pt-0"
      >
        <v-autocomplete
          v-model="filterTruck"
          :items="trucks"
          item-value="id"
          item-text="name"
          clearable
          label="Truck"
        />
      </v-col>
      <v-col
        cols="2"
        class="pt-0"
      >
        <v-select
          v-model="filterStatusRequest"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="3"
        class="pt-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobs"
          hide-details="true"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          item-value="id"
          clearable
          :single-select="false"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
          :height="windowHeight"
        >
          <v-calendar
            ref="calendar"
            v-model="focusDay"
            :weekdays="weekday"
            :type="type"
            :events="slots"
            :event-overlap-mode="type === 'week' ? 'stack' : 'column'"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            :event-height="48"
            interval-height="60"
            first-interval="6"
            interval-count="20"
            color="primary"
            @change="getSlots"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="openViewForm"
          >
            <!-- <template #event="event">
              <v-row>
                <v-col class="pl-5 pb-0 pr-0">
                  <span><span style="font-weight: bold;">{{ event.eventParsed.input.start_time }}-{{ event.eventParsed.input.end_time }}</span>, {{ event.eventParsed.input.name }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-5 pt-0 pb-1 pr-0">
                  <span style="font-size: 10px !important;">{{ event.eventParsed.input.job_name }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-5 pt-1 pb-1 pr-0">
                  <v-chip
                    small
                    :color="event.eventParsed.input.status ? event.eventParsed.input.status.color : ''"
                    text-color="white"
                  >
                    {{ event.eventParsed.input.status ? event.eventParsed.input.status.name : '' }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-5 pt-1 pb-1 pr-0">
                  <v-chip
                    small
                    :color="event.eventParsed.input.class ? event.eventParsed.input.class.color : ''"
                    text-color="white"
                  >
                    {{ event.eventParsed.input.class ? event.eventParsed.input.class.name : '' }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-5 pt-0 pb-1 pr-0">
                  <span>Floor#: {{ event.eventParsed.input.floor_number }}</span>
                </v-col>
              </v-row>
              <v-row v-if="event.eventParsed.input.quantity">
                <v-col class="pl-5 pt-0 pr-0 pb-1">
                  <span>Moving Quantity: {{ event.eventParsed.input.quantity }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-5 pt-1 pb-1 pr-0">
                  <v-chip
                    small
                    :color="event.eventParsed.input.truck_type_color"
                    text-color="white"
                  >
                    {{ event.eventParsed.input.truck_type_name }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row v-if="event.eventParsed.input.quantity">
                <v-col class="pl-5 pt-0 pr-0">
                  <span>Truck: {{ event.eventParsed.input.truck_name }}</span>
                </v-col>
              </v-row>
            </template> -->
            <template #event="event">
              <v-row>
                <v-col cols="auto" class="pt-4 pl-5 pb-0 pr-0">
                  <span style="font-weight: bold;">{{ event.eventParsed.input.start_time }}-{{ event.eventParsed.input.end_time }}</span>
                </v-col>
                <v-col cols="auto" style="padding-top: 14px" class="pb-0">
                  <v-chip
                    small
                    :color="event.eventParsed.input.status ? event.eventParsed.input.status.color : ''"
                    text-color="white"
                  >
                    {{ event.eventParsed.input.status ? event.eventParsed.input.status.name : '' }}
                  </v-chip>
                </v-col>
                <v-spacer/>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="2" class="pl-5 pt-0 pb-1 pr-0">
                  <span style="font-size: 11px !important;">{{ event.eventParsed.input.job_name }}</span>
                </v-col>
              </v-row>
            </template>
          </v-calendar>
        </v-card>
      </v-col>
    </v-row>
    <truck-schedule-view-form
      ref="viewForm"
      :is-actions="true"
      @open-edit-form="openEditForm"
      @open-cancel-form="cancelShipping"
      @open-ship-form="openShipForm"
      @open-deliver-form="deliver"
      @success="success"
    />
    <truck-schedule-approve-form
      ref="approveForm"
      @success="success"
    />
    <truck-schedule-ship-form
      ref="shipForm"
      @success="success"
    />
    <truck-schedule-cancel-form
      ref="cancelForm"

      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TruckScheduleViewForm from '@/components/forms/truckSchedules/TruckScheduleViewForm';
import TruckScheduleApproveForm from '@/components/forms/truckSchedules/TruckScheduleApproveForm';
import TruckScheduleShipForm from '@/components/forms/truckSchedules/TruckScheduleShipForm';
import TruckScheduleCancelForm from '@/components/forms/truckSchedules/TruckScheduleCancelForm';

export default {
  components: {
    TruckScheduleViewForm,
    TruckScheduleApproveForm,
    TruckScheduleShipForm,
    TruckScheduleCancelForm,
  },
  props: {

  },
  data: () => ({
    slots: [],
    type: 'month',
    types: [{ type: 'month', name: 'MONTH', color: '#388E3C' }, { type: 'week', name: 'WEEK', color: '#B71C1C' }, { type: 'day', name: 'DAY', color: '#FBC02D' }],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    focusDay: '',
    loading: false,
    selectedSlot: null,
    selectedOpen: false,
    selectedEvent: null,
    start: null,
    end: null,
    filterStatusRequest: null,
    statuses: [],
    filterJob: null,
    jobs: [],
    filterTruck: null,
    trucks: [],

    windowHeight: 1000,
    topSpaceHeight: 100,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      truckScheduleSlotState: (state) => state.truckScheduleSlotState
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    filterStatusRequest(value) {
      this.storeState('status_id', value)
      this.getSlots({ start: this.start, end: this.end });
    },
    filterJob(value) {
      this.storeState('job_id', value)
      this.getSlots({ start: this.start, end: this.end });
    },
    filterTruck(value) {
      this.storeState('truck_id', value)
      this.getSlots({ start: this.start, end: this.end });
    },
  },
  created() {
    if(this.truckScheduleSlotState){
      if(this.truckScheduleSlotState.job_id) {this.filterJob = this.truckScheduleSlotState.job_id}
      if(this.truckScheduleSlotState.status_id !== null) {this.filterStatusRequest = this.truckScheduleSlotState.status_id}
      if(this.truckScheduleSlotState.truck_id) {this.filterTruck = this.truckScheduleSlotState.truck_id}
    }
    this.init();
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.getStatuses();
      this.getJobs();
      this.getTrucks();
    },

    storeState(property, value){
      const state = this.truckScheduleSlotState;
      state[property] = value;
      this.$store.commit('storeTruckScheduleSlotState', state);
    },

    async getSlots({ start, end }) {
      this.loading = true;
      this.start = start;
      this.end = end;
      const submitModel = {
        filters: {
          start_date: start.date,
          end_date: end.date,
          truck_id: this.filterTruck,
          job_id: this.filterJob,
          status_id: this.filterStatusRequest,
        },
      };
      const res = await this.$api.truckScheduleSlot.read(submitModel, null, 'read-view');
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.slots = res;
    },

    async getStatuses() {
      const res = await this.$api.truckScheduleStatus.get();
      // remove status pending and rejected, since they don't display on calendar
      const statusIdPending = 2;
      const statusIdRejected = 4;
      this.statuses = res.filter((x) => x.id !== statusIdPending && x.id !== statusIdRejected);
    },

    async getJobs() {
      const submitModel = {
        filters: { is_sub_class: 1, is_manual: 0, },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
    },

    async getTrucks() {
      const submitModel = {
        filters: {

        },
      };
      this.trucks = await this.$api.truckScheduleTruck.read(submitModel);
    },

    openViewForm({ event }) {
      this.$refs.viewForm.open(event.request_id, event.request_to_truck_id);
    },

    openEditForm(id) {
      this.$refs.approveForm.open(id);
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    setToday() {
      this.type = 'day';
      this.focusDay = '';
    },

    success() {
      this.getSlots({ start: this.start, end: this.end });
    },

    getEventColor(item) {
      return item.color;
    },

    viewDay({ date }) {
      this.focusDay = date;
      this.type = 'day';
    },

    openShipForm(id) {
      this.$refs.shipForm.open(id);
    },

    cancelShipping(id) {
      this.$refs.cancelForm.open(id);
    },

    async deliver(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to Deliver this record?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.truckScheduleRequest.get(`deliver/${id}`);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getSlots({ start: this.start, end: this.end });
      this.$refs.viewForm.close();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}

::v-deep .v-calendar .v-event {
    min-width: 150px !important;
    width: 99% !important;
    margin-left: 2px !important;
}

::v-deep .v-calendar-daily_head-weekday {
    font-size: 20px !important;
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
    font-size: 18px !important;
}

::v-deep .v-calendar-weekly__day-label .v-btn  {
    font-size: 18px !important;
}

::v-deep .v-event-more {
    left: 1px;
    bottom: 1px;
    height: 18px !important;
}
</style>
