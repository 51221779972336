<template>
  <v-expansion-panels :value="panel">
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold;">
        Location Quantities and Danger Level
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="currentAndDangerLevels"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
            dense
          >
            <template #[`item.limit_level`]="{ item }">
              {{ item.limit_level ? parseFloat(item.limit_level).toLocaleString() : '-' }}
            </template>
            <template #[`item.caution_level`]="{ item }">
              {{ item.caution_level ? parseFloat(item.caution_level).toLocaleString() : '-' }}
            </template>
            <template #[`item.danger_level`]="{ item }">
              {{ item.danger_level ? parseFloat(item.danger_level).toLocaleString() : '-' }}
            </template>
            <template #[`item.quantity_on_hand`]="{ item }">
              {{ item.quantity_on_hand ? parseFloat(item.quantity_on_hand).toLocaleString() : 0 }}
            </template>
            <template #[`item.ordered_quantity`]="{ item }">
              {{ item.ordered_quantity ? parseFloat(item.ordered_quantity).toLocaleString() : 0 }}
            </template>
            <template #[`item.recommended_quantity`]="{ item }">
              {{ item.recommended_quantity ? parseFloat(item.recommended_quantity).toLocaleString() : '-' }}
            </template>
            <template slot="body.append">
              <tr>
                <th>
                  <span
                    style="font-weight: bold; font-size: 14px"
                  >TOTALS:
                  </span>
                </th>
                <th>
                  <span
                    style="font-weight: bold; font-size: 14px"
                  >{{ getTotalLimitLevel().toLocaleString() }}</span>
                </th>
                <th>
                  <span
                    style="font-weight: bold; font-size: 14px"
                  >{{ getTotalCautionLevel().toLocaleString() }}</span>
                </th>
                <th>
                  <span
                    style="font-weight: bold; font-size: 14px"
                  >{{ getTotalDangerLevel().toLocaleString() }}</span>
                </th>
                <th>
                  <span
                    style="font-weight: bold; font-size: 14px"
                  >{{ getTotalQuantityOnHand().toLocaleString() }}</span>
                </th>
                <th>
                  <span
                    style="font-weight: bold; font-size: 14px"
                  >{{ getTotalOrderedQuantity().toLocaleString() }}</span>
                </th>
                <th>
                  <span
                    style="font-weight: bold; font-size: 14px"
                  >{{ getTotalRecommendedQuantity().toLocaleString() }}</span>
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  components: {},
  props: {
    inventoryItemId: {
      type: Number,
      default: null,
    },
    inventoryClassId: {
      type: Number,
      default: null,
    },
    locations: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    currentLevels: [],
    dangerLevels: [],
    currentAndDangerLevels: [],
    loading: false,

    headers: [
      { text: 'Location', value: 'location.name' },
      { text: 'Limit Level', value: 'limit_level' },
      { text: 'Caution Level', value: 'caution_level' },
      { text: 'Danger Level', value: 'danger_level' },
      { text: 'Quantity On Hand', value: 'quantity_on_hand' },
      { text: 'Ordered Quantity', value: 'ordered_quantity' },
      { text: 'Recommended Quantity To Order', value: 'recommended_quantity' },
    ],
    panel: 0,
    inventoryClassIdPaint: 12,
  }),

  computed: {
    isPaintClass() {
      return this.inventoryClassId === this.inventoryClassIdPaint;
    },
  },
  watch: {
    async inventoryItemId(newVal) {
      if (!newVal) {
        this.currentAndDangerLevels = [];
        return;
      }
      this.currentAndDangerLevels = [];
      this.loading = true;
      await this.getCurrentLevels();
      await this.getDangerLevels();
      this.mergeCurrentAndDangerLevels();
      this.loading = false;
    },
  },

  created() {},
  methods: {
    async getCurrentLevels() {
      if (!this.inventoryItemId) return;
      const submitModel = {
        filters: {
          id: this.inventoryItemId,
          is_paint_class: this.isPaintClass ? 1 : 0,
        },
        grouped_by_location: true,
      };
      const res = await this.$api.currentLevel.read(submitModel);
      if (!res) {
        return;
      }

      this.currentLevels = res;
    },

    async getDangerLevels() {
      if (!this.inventoryItemId) return;
      // get danger level where location is not null
      const submitModel = {
        filters: {
          location_id: true,
        },
      };

      submitModel.filters[`${this.isPaintClass ? 'glass_paint_code_id' : 'inventory_item_id'}`] = this.inventoryItemId;

      const res = await this.$api.dangerLevel.read(submitModel);
      if (!res) {
        return;
      }

      this.dangerLevels = res;
    },

    mergeCurrentAndDangerLevels() {
      const locationMap = new Map();

      // Initialize the map with locations
      this.locations.forEach((location) => {
        locationMap.set(location.id, {
          location_id: location.id,
          location,
          quantity_on_hand: 0,
          ordered_quantity: 0,
        });
      });

      // Update the map with danger levels
      this.dangerLevels.forEach((dangerLevel) => {
        const location = locationMap.get(dangerLevel.location_id);
        if (location) {
          location.danger_level = dangerLevel.danger_level;
          location.caution_level = dangerLevel.caution_level;
          location.limit_level = dangerLevel.limit_level;
        }
      });

      // Update the map with current levels
      this.currentLevels.forEach((currentLevel) => {
        const location = locationMap.get(currentLevel.location_id);
        if (location) {
          location.quantity_on_hand = currentLevel.quantity_on_hand;
          location.ordered_quantity = currentLevel.ordered_quantity;
        }
      });

      // Filter out locations that have no relevant levels
      this.currentAndDangerLevels = Array.from(locationMap.values()).filter(
        (location) => (
          location.danger_level !== undefined
            || location.quantity_on_hand !== 0
            || location.ordered_quantity !== 0
        ),
      );

      this.attachRecommendedQuantity();
    },

    attachRecommendedQuantity() {
      this.currentAndDangerLevels.forEach((level) => {
        level.recommended_quantity = level.limit_level !== null
          && level.limit_level
          && level.quantity_on_hand !== null
          && level.ordered_quantity !== null
          ? level.limit_level
              - level.quantity_on_hand
              - level.ordered_quantity
          : null;
      });
    },

    getTotalLimitLevel() {
      let limitLevel = 0;
      this.currentAndDangerLevels.forEach((level) => {
        if (Number.isInteger(level.limit_level)) {
          limitLevel += level.limit_level;
        }
      });
      return limitLevel;
    },

    getTotalCautionLevel() {
      let cautionLevel = 0;
      this.currentAndDangerLevels.forEach((level) => {
        if (Number.isInteger(level.caution_level)) {
          cautionLevel += level.caution_level;
        }
      });
      return cautionLevel;
    },

    getTotalDangerLevel() {
      let dangerLevel = 0;
      this.currentAndDangerLevels.forEach((level) => {
        if (Number.isInteger(level.danger_level)) {
          dangerLevel += level.danger_level;
        }
      });
      return dangerLevel;
    },

    getTotalQuantityOnHand() {
      let quantityOnHand = 0;
      this.currentAndDangerLevels.forEach((level) => {
        quantityOnHand += parseFloat(level.quantity_on_hand);
      });
      return quantityOnHand;
    },

    getTotalOrderedQuantity() {
      let orderedQuantity = 0;
      this.currentAndDangerLevels.forEach((level) => {
        orderedQuantity += parseInt(level.ordered_quantity);
      });
      return orderedQuantity;
    },

    getTotalRecommendedQuantity() {
      let recommendedQuantity = 0;
      this.currentAndDangerLevels.forEach((level) => {
        if (typeof level.recommended_quantity === 'number' && !isNaN(level.recommended_quantity)) {
          recommendedQuantity += level.recommended_quantity;
        }
      });
      return Math.floor(recommendedQuantity);
    },
  },
};
</script>
<style>
</style>
