<template class="formDialog">
  <v-row
    justify="center"
    style="font-size: 15px;"
  >
    <v-dialog
      v-model="dialog"
      max-width="1300px"
      :persistent="true"
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <span class="headline">New UPC Code - <span
              style="font-size: 1.2em"
              class="font-weight-bold"
            >{{ qrCodeNumber }}</span></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  md="3"
                  sm="4"
                  class="pb-0"
                >
                  <inventory-class-select-element
                    v-model="inventoryClassId"
                    :is-add-all="false"
                    :is-required="true"
                    :is4500="false"
                    :is4800="false"
                    :is-commercial="false"
                    :is-railings="false"
                    :is-glass="false"
                    :is-vwd-glass="false"
                    :is-panels="false"
                    :is-sid="false"
                    :is-rail-part="false"
                  />
                </v-col>
                <v-col
                  md="6"
                  sm="8"
                >
                  <v-autocomplete
                    v-model="inventoryItem"
                    :items="inventoryItems"
                    :item-text="
                      (item) => item.material_id + ': ' + item.name
                    "
                    return-object
                    :rules="[(v) => !!v || 'Inventory Item is required']"
                    @change="onInventoryItemChange"
                  >
                    <template #item="{ item }">
                      <v-chip
                        class="mt-1 mb-1 mr-3"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item && item.classes ? item.classes.color : 'white'"
                      >
                        {{ item && item.classes ? item.classes.name : '' }}
                      </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                    </template>
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #selection="{ item }">
                      <v-chip
                        class="mt-1 mb-1 mr-3"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item && item.classes ? item.classes.color : 'white'"
                      >
                        {{ item && item.classes ? item.classes.name : '' }}
                      </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                    </template>
                    <template slot="label">
                      Inventory Item<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="uom"
                    :items="uomsList"
                    auto-select-first
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'UOM is required']"
                    :loading="loadingUom"
                  >
                    <template slot="label">
                      UOM<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="locationId"
                    :items="locationsList"
                    auto-select-first
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Location is required']"
                  >
                    <template slot="label">
                      Location<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="shelfId"
                    :items="inventoryLocations"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Shelf is required']"
                  >
                    <template slot="label">
                      Shelf<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="quantity"
                    :rules="[(v) => !!v || 'Quantity is required']"
                    type="number"
                  >
                    <template slot="label">
                      Quantity<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  class="text-center pb-0"
                >
                  <h3 class="h3">
                    Limit Level
                  </h3> <span> {{ dangerLevel ? dangerLevel.limit_level : '-' }}</span>
                </v-col>
                <v-col
                  class="text-center pb-0"
                >
                  <h3 class="h3">
                    Danger Level
                  </h3> <span> {{ dangerLevel ? dangerLevel.danger_level : '-' }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              color="normal"
              class="mr-2"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              large
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import InventoryLocationsApi from '@/api/InventoryLocationsApi';
import InventoryTagsApi from '@/api/InventoryTagsApi';
import LocationsApi from '@/api/LocationsApi';
import { mapGetters, mapState } from 'vuex';
import UomsApi from '@/api/UomsApi';

export default {
  components: { },
  props: {
    enteredTag: {
      type: String,
      default: '',
    },
    isShowPO: {
      type: Boolean,
      default: false,
    },
    isShowLocation: {
      type: Boolean,
      default: false,
    },
    defaultLocation: {
      type: Object,
      default: () => {},
    },
    defaultShelf: {
      type: Object,
      default: () => {},
    },
    defaultQuantity: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    // Inventory Item
    uom: 0,
    inventoryTag: '',
    inventoryItem: '',
    shelfId: 0,
    quantity: '',
    userId: null,
    po_number: '',
    inventoryClasses: '',
    locationId: '',
    isQrEqualsTag: 0,
    qrCodeNumber: '',
    inventoryClassId: 4,
    supplierLabel: null,

    // Inventory
    uomsList: [],
    inventoryItems: [],
    inventoryLocations: [],
    locationsList: [],

    // DangerLevel
    dangerLevel: null,
    classPartsId: 11,

    // Form
    dialog: false,
    btnSubmitName: 'ADD',
    loadingUom: false,
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      user: 'user',
      invClasses: 'invClasses',
    }),
  },
  watch: {
    enteredTag(value) {
      if (this.isQrEqualsTag) { this.inventoryTag = value; }

      this.qrCodeNumber = value;
    },
    locationId(value) {
      if (!value) return;
      this.fetchInventoryLocations();
    },
    inventoryClassId() {
      this.fetchInventoryItems();
    },

  },
  created() {
    const QrEqualsTagBRName = 'QR Code Equals Inv. Tag';
    this.isQrEqualsTag = this.businessRulesGetBoolean(QrEqualsTagBRName);

    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchUoms();
      this.fetchInventoryItems();
      this.fetchLocations();
      this.fetchInventoryLocations();

      if (!this.user) return;
      this.userId = this.user.id;
    },
    async fetchUoms() {
      this.uomsList = await UomsApi.getList();
    },
    async fetchInventoryItems() {
      this.inventoryItems = [];
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readDataSecondary(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },
    async fetchInventoryLocations() {
      const readModel = {
        filters:
        {
          location_id: this.locationId,
        },
      };
      this.inventoryLocations = await InventoryLocationsApi.read(readModel);
    },
    async fetchLocations() {
      this.locationsList = await LocationsApi.getList();
    },
    async fetchDangerLevel() {
      if (this.inventoryItem && this.inventoryItem.inventory_class_id !== this.classPartsId) return;
      const submitModel = {
        filters: {
          inventory_item_id: this.inventoryItem.id,
        },
      };

      const res = await this.$api.dangerLevel.read(submitModel);
      if (!res || res.length < 1) {
        this.dangerLevel = null;
        return;
      }

      const firstIndex = 0;
      this.dangerLevel = res[firstIndex];
    },
    async getUoms() {
      this.loadingUom = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.inventoryItem.id,
        },
      };
      this.uomsList = await this.$api.uom.read(submitData);
      this.loadingUom = false;
    },

    onInventoryItemChange() {
      this.uom = null;
      this.fetchDangerLevel();
      this.getUoms();
    },

    async submitForm() {
      if (this.$refs.form.validate()) {
        this.$bus.$emit('loading');
        const data = new FormData();

        // Qr Code is treated as inv tag for now
        if (this.qrCodeNumber) {
          data.append('inventory_tag', this.qrCodeNumber);
        }

        if (this.inventoryItem) {
          data.append('inventory_item_id', this.inventoryItem.id);
        }

        if (this.uom) {
          data.append('uom_id', this.uom);
        }

        if (this.shelfId) {
          data.append('inventory_location_id', this.shelfId);
        }

        if (this.quantity) {
          data.append('quantity', this.quantity);
          data.append('upc_received_quantity', this.quantity);
        }
        if (this.user.id) {
          data.append('created_by', this.user.id);
        }

        if (this.locationId) {
          data.append('location_id', this.locationId);
        }

        const res = await InventoryTagsApi.addUpc(data);
        this.$bus.$emit('loading-stop');
        if (res === true) {
          this.$emit('submitQRCodeTag', 'UPC Code has been added to ERP');

          this.closeDialog();
        } else {
          this.$bus.$emit('showError', res);
        }
      }
    },

    openDialog() {
      this.dialog = true;
      this.inventoryClassId = 4;
      if (this.isQrEqualsTag) { this.inventoryTag = this.enteredTag; }

      this.qrCodeNumber = this.enteredTag;

      // set default values

      // UOM
      if (this.uomsList.length) {
        const uomIdBox = 2;
        const uom = this.uomsList.find((x) => x.id === uomIdBox);
        if (uom) { this.uom = uom.id; } else { this.uom = 0; }
      } else { this.uom = 0; }

      // Location
      // If is set prop defaultLocation
      // else get first record from the list
      //   if (this.defaultLocation) {
      //     this.locationId = this.defaultLocation.id;
      //   } else if (this.locationsList.length && this.locationsList[0].id) {
      //     const locationId = this.locationsList[0].id;
      //     if (locationId) { this.locationId = locationId; } else { this.locationId = 0; }
      //   } else { this.locationId = 0; }

      //   // Shelf
      //   // If is set prop defaultShelf
      //   if (this.defaultShelf) {
      //     this.shelfId = this.defaultShelf.id;
      //   }

      // Quantity
      // If is set prop defaulQuantity
      if (this.defaultQuantity) {
        this.quantity = this.defaultQuantity;
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}
.v-text-field >>> input {
    font-size: 1.5em !important;
}
.v-autocomplete >>> input{
    font-size: 1.5em !important;
}
.v-autocomplete >>> label{
    font-size: 1.3em !important;
}
.v-text-field >>> label {
    font-size: 1.3em !important;
}
.v-btn {
    font-size: 1.1em !important;
}
/* v-autocomplete highlight color */
.theme--light.v-list-item .v-list-item__mask {
    background: #e6e90a !important;
}
</style>
