<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="1"
        class="pb-0"
      >
        <!-- <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
          :hide-details="true"
        /> -->
        <v-select
          v-model="inventoryClassIds"
          clearable
          multiple
          label="Class"
          :items="inventoryClasses"
          item-text="name"
          item-value="id"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobCodes"
          hide-details="true"
          item-value="code"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
          <template slot="label">
            Job
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterStatusId"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row>
          <v-col class="pr-2 pt-8">
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <span
                  :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}"
                  v-on="on"
                >Latest Records</span>
              </template>
              <span>Records from the last 3 months</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="auto"
            class="pl-0"
          >
            <v-switch
              v-model="switchAll"
              hide-details
              label="All Records"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row align="center">
          <v-col class="pt-8">
            <span :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}">Original</span>
          </v-col>
          <v-col
            cols="auto"
            class="pl-0"
          >
            <v-switch
              v-model="switchRemakes"
              hide-details
              label="Remakes Only"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pt-8"
          >
            <span :style="{ fontSize: '16px !important', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0,0,0,0.6)'}">Hide</span>
          </v-col>
          <v-col
            cols="auto"
            class="pl-0"
          >
            <v-switch
              v-model="isShowStages"
              hide-details
              label="Show Stages"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="pr-0"
      >
        <v-btn
          color="success"
          outlined
          @click="openFileInput"
        >
          IMPORT
        </v-btn>
      </v-col>
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
      >
        <v-btn
          color="primary"
          outlined
          @click="openNewPo"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New PO
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="poHeaders"
            :items="poItems"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template
              v-for="h in poHeaders"
              #[`header.${h.value}`]="{ header }"
            >
              <v-tooltip
                :key="h.text"
                top
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>{{ header.fullText }}</span>
              </v-tooltip>
            </template>
            <template #item="{ item }">
              <tr>
                <td class="text-center">
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </td>
                <td>
                  <text-highlight
                    :queries="search"
                    class="font-weight-bold"
                  >
                    {{ item.po_number ? item.po_number : '-' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.created_at ? item.created_at : '' }}
                  </text-highlight>
                </td>
                <td>
                  <span>
                    <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                  </span>
                </td>

                <td>
                  <text-highlight :queries="search">
                    {{ item.supplier_code ? item.supplier_code : '' }}
                  </text-highlight>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    :color="item.batch_id ? '#C0CA33' : '#26C6DA'"
                    text-color="white"
                  >
                    {{ item.batch_id ? 'BATCHED' : 'NOT BATCHED' }}
                  </v-chip>
                </td>

                <td>
                  <text-highlight :queries="search">
                    {{ item.total_sp ? item.total_sp : '0' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.total_su ? item.total_su : '0' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.total_sg ? item.total_sg : '0' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.total_glass ? item.total_glass : '0' }}
                  </text-highlight>
                </td>
                <td
                  v-for="stage in stages"
                  v-if="isShowStages"
                  :key="stage.statusId"
                >
                  <v-btn
                    v-if="stage.statusId != null"
                    elevation="0"
                    fab
                    outlined
                    tile
                    color="#FFD84D"
                    :class="`box ${getStageClass(item, stage)}`"
                    @click="openItemsForm(item, stage.statusId)"
                  >
                    <span>{{ getStageValue(item, stage) }}</span>
                  </v-btn>
                  <div
                    v-else
                    class="pl-1"
                    style="width: 1px; height: 100%; border-right: 1px solid #E0E0E0;"
                  />
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    style="width: 100%; max-width: 140px; white-space: nowrap; "
                    :color="item && item.status ? item.status.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.status ? item.status.name : '' }}
                  </v-chip>
                </td>
                <td>
                  <text-highlight
                    v-if="item.status_id == glassStatusIdReceivedOnSite || item.status_id == glassStatusIdPReceivedOnSite || item.status_id == glassStatusIdReceived || item.status_id == glassStatusIdPReceived || item.status_id == glassStatusIdPShippedToSite || item.status_id == glassStatusIdPShipped || item.status_id == glassStatusIdShippedToSite || item.status_id == glassStatusIdShipped"
                    :queries="search"
                  >
                    {{ item.location_codes ? item.location_codes : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight
                    v-if="item.status_id == glassStatusIdReceivedOnSite || item.status_id == glassStatusIdPReceivedOnSite || item.status_id == glassStatusIdPShippedToSite || item.status_id == glassStatusIdShippedToSite"

                    :queries="search"
                  >
                    {{ item.project_name ? item.project_name: '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.prod_schedule && item.prod_schedule.glass_required_by ? item.prod_schedule.glass_required_by : '' }}
                  </text-highlight>
                </td>
                <td
                  class="text-end"
                  style="white-space: nowrap;"
                >
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mb-1"
                        elevation="0"
                        fab
                        outlined
                        x-small
                        v-bind="attrs"
                        tile
                        color="primary"
                        style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="pa-3"
                    >
                      <v-list-item
                        :disabled="!item.is_manual || item.batch_id != null"
                        @click="openEdit(item)"
                      >
                        <v-list-item-title
                          style="font-weight: 600;"
                        >
                          <span style="font-size: 16px !important; color: #1976D2">EDIT</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openClone(item)"
                      >
                        <v-list-item-title
                          style="font-weight: 600;"
                        >
                          <span style="font-size: 16px !important; color: #00897B;">CLONE</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="!item.is_manual || item.batch_id != null"
                        @click="softDelete(item)"
                      >
                        <v-list-item-title
                          style="font-weight: 600;"
                        >
                          <span style="font-size: 16px !important; color: red">DELETE</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.is_manual == 1 && !item.file"
                        @click="item.is_manual == 1 ? downloadPdf(item.po_number) : runWebhook(item, 'pdf')"
                      >
                        <v-list-item-title :style="`color: ${item.is_manual == 0 || item.file ? 'green' : 'rgba(0,0,0,0.4)'}; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                :color="`${item.is_manual == 0 || item.file ? 'green' : 'rgba(0,0,0,0.4)'}`"
                              >
                                fas fa-file
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              PDF
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.is_manual == 1 && !item.file"
                        @click="item.is_manual == 1 ? downloadCsv(item.po_number) : runWebhook(item, 'csv')"
                      >
                        <v-list-item-title :style="`color: ${item.is_manual == 0 || item.file ? '#00ACC1' : 'rgba(0,0,0,0.4)'}; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 18px;"
                                :color="`${item.is_manual == 0 || item.file ? '#00ACC1' : 'rgba(0,0,0,0.4)'}`"
                              >
                                mdi-arrow-collapse-down
                              </v-icon>
                            </v-col>
                            <v-col class="pl-2 pt-4">
                              CSV
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.is_manual == 1 && !item.file"
                        @click="item.is_manual == 1 ? downloadTxt(item.po_number) : runWebhook(item, 'txt')"
                      >
                        <v-list-item-title :style="`color: ${item.is_manual == 0 || item.file ? '#5E35B1' : 'rgba(0,0,0,0.4)'}; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-1 pl-4"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 18px;"
                                :color="`${item.is_manual == 0 || item.file ? '#5E35B1' : 'rgba(0,0,0,0.4)'}`"
                              >
                                fas fa-file-alt
                              </v-icon>
                            </v-col>
                            <v-col class="pl-2 pt-4">
                              TXT
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="cancel(item)"
                      >
                        <v-list-item-title>
                          <span style="font-size: 16px !important; color: #E91E63; font-weight: 600;">CANCEL</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="viewGlass(item)"
                      >
                        <v-list-item-title style="color: #00897B; font-weight: 600;">
                          <span style="font-size: 16px !important;">VIEW GLASS</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-batch-form
      ref="batchForm"
      @success="success"
    />
    <glass-import-form
      ref="glassImportForm"
      @success="success"
    />
    <glass-p-o-form
      ref="glassPOForm"
      @success="success"
    />
    <glass-items-form
      ref="glassItemsForm"
    />
    <v-file-input
      v-show="false"
      ref="fileInput"
      v-model="importFile"
      @change="importGlassItems"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import GlassBatchForm from '@/components/forms/glasses/GlassBatchForm';
import GlassImportForm from '@/components/forms/glasses/GlassImportForm';
import GlassPOForm from '@/components/forms/glasses/GlassPOForm';
import GlassItemsForm from '@/components/forms/glasses/GlassItemsForm';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import ExcelHelper from '@/helpers/ExcelHelper';

export default {
  components: {
    GlassBatchForm,
    GlassImportForm,
    GlassPOForm,
    GlassItemsForm,
  },
  props: {
    jobCode: {
      type: Number,
      default: 0,
    },
    batchId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    poItems: [],
    jobCodes: [],
    batches: [],
    footer: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    types: [],
    statuses: [],
    loading: false,
    inventoryClassIds: null,
    inventoryClasses: [],
    filterJob: null,
    filterBatchId: 0,
    jobLoading: false,
    importFile: null,
    classIdVWDGlass: 7,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    topSpaceHeight: 280,
    windowHeight: 1500,
    expandedItems: [],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    total: 0,
    filterStatusId: null,
    loadingJobs: false,
    loadingBatch: false,
    glassStatusIdNew: 1,
    glassStatusIdPProduced: 2,
    glassStatusIdProduced: 3,
    glassStatusIdCanceled: 4,
    glassStatusIdRemake: 5,
    glassStatusIdShipped: 6,
    glassStatusIdReceived: 7,
    glassStatusIdCutting: 10,
    glassStatusIdTempering: 11,
    glassStatusIdShippedToSite: 12,
    glassStatusIdReceivedOnSite: 13,
    glassStatusIdPShipped: 8,
    glassStatusIdPReceived: 9,
    glassStatusIdPShippedToSite: 14,
    glassStatusIdPReceivedOnSite: 15,
    glassStatusIdRejected: 16,
    switchRemakes: false,
    switchAll: false,
    isShowStages: true,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
      glassPoState: (state) => state.glassPoState,
    }),

    stages() {
      return [
        { statusId: this.glassStatusIdNew, letter: 'C' },
        { statusId: this.glassStatusIdCutting, letter: 'T' },
        { statusId: this.glassStatusIdTempering, letter: 'P' },
        { statusId: this.glassStatusIdProduced, letter: 'S' },
        { statusId: this.glassStatusIdShipped, letter: 'R' },
        { statusId: null, letter: '' },
        { statusId: this.glassStatusIdReceived, letter: 'S' },
        { statusId: this.glassStatusIdShippedToSite, letter: 'R' },
        { statusId: this.glassStatusIdRejected, letter: 'RJ' },
      ];
    },

    poHeaders() {
      const headers = [];
      headers.push({
        text: 'Class', align: 'center',
      });

      headers.push({
        text: 'PO#',
        width: '5%',
      });

      headers.push({
        text: 'Date',
      });

      headers.push({
        text: 'Job',
      });

      headers.push({
        text: 'Supplier',
      });

      headers.push({
        text: 'Batch', align: 'center',
      });

      headers.push({
        text: 'SP',
        fullText: 'Spandrel',
      });

      headers.push({
        text: 'SU',
        fullText: 'Sealed Unit',
      });

      headers.push({
        text: 'SG',
        fullText: 'Single Glass',
      });

      headers.push({
        text: 'Total',
      });

      if (this.isShowStages) {
        headers.push({
          text: 'CUT',
          fullText: 'Cutting',
        });

        headers.push({
          text: 'TMP',
          fullText: 'Tempering',
        });

        headers.push({
          text: 'PRD',
          fullText: 'Produced',
        });

        headers.push({
          text: 'SHP',
          fullText: 'Shipped',
        });

        headers.push({
          text: 'REC',
          fullText: 'Received',
        });
        headers.push({
          text: '', width: '1%',
        });
        headers.push({
          text: 'STS',
          fullText: 'Shipped to Site',
        });

        headers.push({
          text: 'ROS',
          fullText: 'Received on Site',
        });

        headers.push({
          text: 'RJ',
          fullText: 'Rejected',
        });
      }

      headers.push({
        text: 'Status', align: 'center',
      });

      headers.push({
        text: 'Loc. Rec.',
        fullText: 'Location Received',
      });

      headers.push({
        text: 'Proj. Ship.',
        fullText: 'Project Shipped',
      });

      headers.push({
        text: 'Req. by',
        fullText: 'Required by',
      });

      headers.push({
        text: 'Actions', align: 'end',
      });

      return headers;
    },

  },
  watch: {
    inventoryClassIds(value) {
      this.storeState('inventory_class_ids', value);
      this.get();
    },
    filterJob(value) {
      this.storeState('job_code', value);
      this.get();
    },
    filterBatchId(value) {
      this.storeState('batch_id', value);
      this.get();
    },
    pagination() {
      // might not this
      const state = this.glassPoState;
      state.rowsPerPage = this.pagination.itemsPerPage;
      this.$store.commit('storeGlassPoState', state);
      this.get();
    },
    search() {
      this.get();
    },
    filterStatusId(value) {
      this.storeState('status_id', value);
      this.get();
    },
    switchRemakes() {
      this.get();
    },
    switchAll() {
      this.get();
    },
  },
  created() {
    if (this.glassPoState && !this.jobCode && !this.batchId) {
      if (this.glassPoState.inventory_class_ids) { this.inventoryClassIds = this.glassPoState.inventory_class_ids; }
      if (this.glassPoState.rowsPerPage) { this.pagination.itemsPerPage = this.glassPoState.rowsPerPage; }
      if (this.glassPoState.job_code) { this.filterJob = this.glassPoState.job_code; }
      if (this.glassPoState.batch_id) { this.filterBatchId = this.glassPoState.batch_id; }
      if (this.glassPoState.status_id !== null) { this.filterStatusId = this.glassPoState.status_id; }
    }
    if (this.jobCode) this.filterJob = this.jobCode;
    if (this.batchId) this.filterBatchId = this.batchId;
    this.roles = this.user.roles.map((x) => x.id);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.getJobs();
      this.getBatches();
      this.getStatuses();
      this.getClasses();
    },

    storeState(property, value) {
      const state = this.glassPoState;
      state[property] = value;
      this.$store.commit('storeGlassPoState', state);
    },

    async get() {
      // if (this.inventoryClassIds === null) return;
      this.poItems = [];
      this.total = 0;
      this.loading = true;
      const submitModel = {
        filters: {
          inventory_class_ids: this.inventoryClassIds,
          job_code: this.filterJob,
          batch_id: this.filterBatchId,
          status_id: this.filterStatusId,
          is_remakes: this.switchRemakes ? 1 : 0,
          is_all: this.switchAll ? 1 : 0,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.glass.readPos(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.init();
      if (!res || !res.data || res.data.length === 0) {
        this.poItems = [];
        this.total = 0;
        return;
      }
      res.data.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.total_new = parseInt(item.total_new, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_cutting = parseInt(item.total_cutting, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_tempering = parseInt(item.total_tempering, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_produced = parseInt(item.total_produced, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_shipped = parseInt(item.total_shipped, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_received = parseInt(item.total_received, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_shipped_to_site = parseInt(item.total_shipped_to_site, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_received_on_site = parseInt(item.total_received_on_site, 10);
        // eslint-disable-next-line no-param-reassign
        item.total_rejected = parseInt(item.total_rejected, 10);
      });
      this.poItems = res.data;
      this.total = res.total;
      this.loading = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: { inventory_class_ids: this.inventoryClassIds, is_manual: 0 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobCodes = [];
        this.loadingJobs = false;
        return;
      }
      this.jobCodes = res;
      this.loadingJobs = false;
    },

    async getBatches() {
      this.loadingBatch = true;
      this.batches = await this.$api.glassBatch.get('batch-numbers');
      this.loadingBatch = false;
    },

    async getStatuses() {
      this.statuses = await this.$api.glassPoStatus.get();
    },

    async getClasses() {
      const submitData = {
        filters: {
        },
      };

      const res = await this.$api.inventoryClass.store(submitData, 'read');

      if (!res) return;

      const classIdAll = 0;
      this.inventoryClasses = res.filter((x) => x.id !== classIdAll);
    },

    async removeGlassItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this PO# from Batch?',
      });
      if (!confirm) return;
      const res = this.$api.glass.get(`remove-po/${item.batch_id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    viewGlass(item) {
      this.$router.push({
        name: 'GlassItems',
        params: {
          poNumber: item.po_number,
        },
      });
    },

    viewBatch(item) {
      this.$router.push({
        name: 'GlassBatchView',
        params: {
          poNumber: item.po_number,
        },
      });
    },

    openBatch() {
      this.$refs.batchForm.open();
    },

    openNewPo() {
      this.$refs.glassPOForm.open();
    },

    openEdit(item) {
      this.$refs.glassPOForm.open(item.inventory_class_id, item.po_number);
    },

    openClone(item) {
      const isClone = true;
      this.$refs.glassPOForm.open(item.inventory_class_id, item.po_number, isClone);
    },

    async startSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to do a Data Sync? This action will bring new Glass Items into ERP and can take up to 2 minutes!',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.glass.get('sync');
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
      this.getJobs();
    },

    createAutoBatch(item) {
      this.$refs.batchForm.open(item.inventory_class_id, item.po_number);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
      this.getJobs();
    },
    async importGlassItems() {
      if (!this.importFile) return;
      this.$refs.glassImportForm.open(this.importFile);
    },
    openFileInput() {
      this.importFile = null;
      this.$refs.fileInput.$refs.input.click();
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this PO#?',
      });
      if (!confirm) return;
      const res = await this.$api.glassPurchaseOrderItem.delete(`${item.po_number}/${item.inventory_class_id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    async downloadPdf(poNumber) {
      this.$bus.$emit('loading');
      const res = await this.$api.glass.download(poNumber, 1);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${poNumber}_${now}`;
      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
    },

    async downloadCsv(poNumber) {
      this.$bus.$emit('loading');
      const res = await this.$api.glass.download(poNumber, 1);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${poNumber}_${now}`;
      const file = new Blob([res], {
        type: 'text/csv',
      });
      saveAs(file, fileName);
    },

    async downloadTxt(poNumber) {
      this.$bus.$emit('loading');
      const res = await this.$api.glass.download(poNumber, 1);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      const file = new File([res], 'name');
      const result = await ExcelHelper.excelToCSV(file);
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${poNumber}_${now}`;
      const blobFile = new Blob([result], {
        type: 'text/plain',
      });
      saveAs(blobFile, fileName);
    },

    async runWebhook(item, type) {
      this.$bus.$emit('loading');
      const inventoryClassId4500 = 1;
      const inventoryClassId4800 = 2;
      const webhookData = {
        module_id: null,
        po_number: item.po_number,
        type,
      };

      if (type === 'pdf') {
        if (item.inventory_class_id === inventoryClassId4500) {
          webhookData.module_id = 22;
        } else if (item.inventory_class_id === inventoryClassId4800) {
          webhookData.module_id = 23;
        }
      } else if (type === 'csv' || type === 'txt') {
        if (item.inventory_class_id === inventoryClassId4500) {
          webhookData.module_id = 24;
        } else if (item.inventory_class_id === inventoryClassId4800) {
          webhookData.module_id = 25;
        }
      }

      const res = await this.$api.glassPurchaseOrderItem.store(webhookData, 'run-webhook-sync', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.po_number}_${now}`;

      if (type === 'pdf') {
        const file = new Blob([res], {
          type: 'application/pdf',
        });
        saveAs(file, fileName);
      } else if (type === 'csv') {
        const file = new Blob([res], {
          type: 'application/vnd.ms-excel',
        });
        saveAs(file, fileName);
      } else if (type === 'txt') {
        const file = new File([res], 'name');
        const result = await ExcelHelper.excelToCSV(file);
        const blobFile = new Blob([result], {
          type: 'text/plain',
        });
        saveAs(blobFile, fileName);
      }
      this.$bus.$emit('loading');
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
        this.pagination = pagination;
        this.storeState('rowsPerPage', pagination.itemsPerPage);
      }
    },

    async changeStatus(item) {
      const res = await this.$api.glass.get(`po-status/${item.po_number}/${item.inventory_class_id}/${item.status_id}`);
      if (!res) return;
      this.get();
    },

    async cancel(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to cancel this PO#?',
      });
      if (!confirm) return;
      const res = await this.$api.glass.get(`po-status/${item.po_number}/${item.inventory_class_id}/${this.glassStatusIdCanceled}`);
      if (!res) return;
      this.get();
    },

    convertDateFormat(inputDate) {
      // Parse the input date
      const date = new Date(inputDate);

      // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      return formattedDate;
    },

    getStageClass(item, stage) {
      switch (stage.statusId) {
        case this.glassStatusIdNew:
          if (item.total_new !== 0) return 'yellow-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1) return 'green-box';
          return 'empty-box';
        case this.glassStatusIdCutting:
          if (item.total_cutting !== 0) return 'yellow-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0) return 'green-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new !== 0) return 'yellow-box';
          return 'empty-box';
        case this.glassStatusIdTempering:
          if (item.total_tempering !== 0) return 'yellow-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0 && item.total_cutting === 0) return 'green-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0)) return 'yellow-box';
          return 'empty-box';
        case this.glassStatusIdProduced:
          if (item.total_produced !== 0) return 'yellow-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0 && item.total_cutting === 0 && item.total_tempering === 0) return 'green-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0 || item.total_tempering !== 0)) return 'yellow-box';
          return 'empty-box';
        case this.glassStatusIdShipped:
          // eslint-disable-next-line no-case-declarations
          const total = item.total_received + item.total_shipped_to_site + item.total_received_on_site;
          if (total !== 0 && item.total_glass - item.total_remake !== 0 && total === item.total_glass - item.total_remake) return 'green-box';
          if (item.total_shipped !== 0 || item.total_received !== 0) return 'yellow-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0 && item.total_cutting === 0 && item.total_tempering === 0 && item.total_produced === 0) return 'green-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0 || item.total_tempering !== 0 || item.total_produced !== 0)) return 'yellow-box';
          return 'empty-box';
        case this.glassStatusIdReceived:
          if (item.total_received !== 0 && item.total_shipped_to_site !== 0) return 'yellow-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_received === 0 && item.total_new === 0 && item.total_cutting === 0 && item.total_tempering === 0 && item.total_produced === 0 && item.total_shipped === 0) return 'green-box';
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0 || item.total_tempering !== 0 || item.total_produced !== 0) && item.total_received !== 0 && item.total_shipped_to_site !== 0) return 'yellow-box';
          return 'empty-box';
        case this.glassStatusIdShippedToSite:
          if (item.total_received_on_site !== 0 && item.total_glass - item.total_remake !== 0 && item.total_received_on_site === item.total_glass - item.total_remake) return 'green-box';
          if (item.total_shipped_to_site !== 0 || item.total_received_on_site !== 0) return 'yellow-box';
          return 'empty-box';
        case this.glassStatusIdRejected:
          if (item.total_rejected !== 0) return 'red-box';
          return 'empty-box';
        default:
          return 'empty-box';
      }
    },

    getStageValue(item, stage) {
      switch (stage.statusId) {
        case this.glassStatusIdNew:
          if (item.total_new !== 0) return item.total_new;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1) return stage.letter;
          return '';
        case this.glassStatusIdCutting:
          if (item.total_cutting !== 0) return item.total_cutting + item.total_new;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0) return stage.letter;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new !== 0) return item.total_new;
          return '';
        case this.glassStatusIdTempering:
          if (item.total_tempering !== 0) return item.total_tempering + item.total_cutting + item.total_new;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0 && item.total_cutting === 0) return stage.letter;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0)) return item.total_cutting + item.total_new;
          return '';
        case this.glassStatusIdProduced:
          if (item.total_produced !== 0) return item.total_produced + item.total_tempering + item.total_cutting + item.total_new;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0 && item.total_cutting === 0 && item.total_tempering === 0) return stage.letter;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0 || item.total_tempering !== 0)) return item.total_tempering + item.total_cutting + item.total_new;
          return '';
        case this.glassStatusIdShipped:
          // eslint-disable-next-line no-case-declarations
          const total = item.total_received + item.total_shipped_to_site + item.total_received_on_site;
          if (total !== 0 && item.total_glass - item.total_remake !== 0 && total === item.total_glass - item.total_remake) return stage.letter;
          if (item.total_shipped !== 0 || item.total_received !== 0) return item.total_received + item.total_shipped + item.total_produced + item.total_tempering + item.total_cutting + item.total_new;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_new === 0 && item.total_cutting === 0 && item.total_tempering === 0 && item.total_produced === 0) return stage.letter;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0 || item.total_tempering !== 0 || item.total_produced !== 0)) return item.total_produced + item.total_tempering + item.total_cutting + item.total_new;
          return '';
        case this.glassStatusIdReceived:
          if (item.total_received !== 0 && item.total_shipped_to_site !== 0) return item.total_received + item.total_shipped + item.total_produced + item.total_tempering + item.total_cutting + item.total_new;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && item.total_received === 0 && item.total_new === 0 && item.total_cutting === 0 && item.total_tempering === 0 && item.total_produced === 0 && item.total_shipped === 0) return stage.letter;
          if (this.getStatusValue(stage.statusId, item.status_id) === 1 && (item.total_new !== 0 || item.total_cutting !== 0 || item.total_tempering !== 0 || item.total_produced !== 0) && item.total_received !== 0 && item.total_shipped_to_site !== 0) return item.total_received + item.total_produced + item.total_tempering + item.total_cutting + item.total_new;
          return '';
        case this.glassStatusIdShippedToSite:
          if (item.total_received_on_site !== 0 && item.total_glass - item.total_remake !== 0 && item.total_received_on_site === item.total_glass - item.total_remake) return stage.letter;
          if (item.total_shipped_to_site !== 0 || item.total_received_on_site !== 0) return item.total_received_on_site + item.total_shipped_to_site + item.total_received + item.total_shipped + item.total_produced + item.total_tempering + item.total_cutting + item.total_new;
          return '';
        case this.glassStatusIdRejected:
          if (item.total_rejected !== 0) return item.total_rejected;
          return '';
        default:
          return '';
      }
    },

    getStatusValue(statusId, itemStatusId) {
      const statusesInOrder = [
        this.glassStatusIdNew,
        this.glassStatusIdCutting,
        this.glassStatusIdTempering,
        this.glassStatusIdPProduced,
        this.glassStatusIdProduced,
        this.glassStatusIdShipped,
        this.glassStatusIdPShipped,
        this.glassStatusIdReceived,
        this.glassStatusIdPReceived,
        this.glassStatusIdShippedToSite,
        this.glassStatusIdPShippedToSite,
        this.glassStatusIdReceivedOnSite,
        this.glassStatusIdPReceivedOnSite,
      ];

      const statusIndex = statusesInOrder.findIndex((x) => x === statusId);
      const itemStatusIndex = statusesInOrder.findIndex((x) => x === itemStatusId);

      return statusIndex < itemStatusIndex ? 1 : 0;
    },

    openItemsForm(item, statusId) {
      let statusIds = [];
      switch (statusId) {
        case this.glassStatusIdNew:
          statusIds = [this.glassStatusIdNew];
          break;
        case this.glassStatusIdCutting:
          statusIds = [this.glassStatusIdCutting, this.glassStatusIdNew];
          break;
        case this.glassStatusIdTempering:
          statusIds = [this.glassStatusIdCutting, this.glassStatusIdTempering, this.glassStatusIdNew];
          break;

        case this.glassStatusIdProduced:
          statusIds = [this.glassStatusIdCutting, this.glassStatusIdTempering, this.glassStatusIdProduced, this.glassStatusIdNew];
          break;

        case this.glassStatusIdShipped:
          statusIds = [this.glassStatusIdCutting, this.glassStatusIdTempering, this.glassStatusIdProduced, this.glassStatusIdShipped, this.glassStatusIdNew, this.glassStatusIdReceived];
          break;

        case this.glassStatusIdReceived:
          statusIds = [this.glassStatusIdCutting, this.glassStatusIdTempering, this.glassStatusIdProduced, this.glassStatusIdShipped, this.glassStatusIdReceived, this.glassStatusIdNew];
          break;

        case this.glassStatusIdShippedToSite:
          statusIds = [this.glassStatusIdReceivedOnSite, this.glassStatusIdCutting, this.glassStatusIdTempering, this.glassStatusIdProduced, this.glassStatusIdShipped, this.glassStatusIdReceived, this.glassStatusIdShippedToSite, this.glassStatusIdNew];
          break;

        case this.glassStatusIdReceivedOnSite:
          statusIds = [this.glassStatusIdCutting, this.glassStatusIdTempering, this.glassStatusIdProduced, this.glassStatusIdShipped, this.glassStatusIdReceived, this.glassStatusIdShippedToSite, this.glassStatusIdReceivedOnSite, this.glassStatusIdNew];
          break;

        case this.glassStatusIdRejected:
          statusIds = [this.glassStatusIdRejected];
          break;

        default:
          statusIds = [statusId];
          break;
      }
      this.$refs.glassItemsForm.open(item.po_number, statusIds);
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td
{
  padding: 0 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    padding-right: 4px;
    padding-left: 4px;
}

span
{
  font-size: 12px;
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 20px;
}
::v-deep .fa-caret-down:before {
    font-size: 14px;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 0 3px 0;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-left: 0px !important;
}
::v-deep .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner {
    width: 15px;
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding-left: 10px;
    padding-right: 10px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
    width: 12px;
}
::v-deep .select-status .theme--light.v-icon {
    color: white;
    padding-right: 6px !important;
}

.box
{
  border-radius: 4px;
   width: 25px;
    height: 25px;
}

.empty-box
{
  border: 1.5px solid #FFD84D;
  background-color: #FFFFFF;
}

.empty-box span
{
  color: #000000;
}

.red-box
{
  border: 1.5px solid #F44336;
  background-color: #F44336;
}

.yellow-box
{
  border: 1.5px solid #FFD84D;
  background-color: #FFD84D;
}

.yellow-box span
{
  color: #000000;
}

.green-box
{
  border: 1.5px solid #4CAF50;
  background-color: #4CAF50;
}

.green-box span
{
  color: #FFFFFF;
}
</style>
