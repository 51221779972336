<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Move'"
    :max-width="800"
  >
    <template slot="header">
      <div
        style="width: 100%;"
        class="d-flex justify-space-between align-center"
      >
        <span
          v-if="item"
          class="f6 fw600"
        >Glass Item# - {{ item.po_number }}-{{ item.item_index }}</span>
        <div
          class="d-flex"
          style="gap: 6px;"
        >
          <v-chip
            :color="item && item.class ? item.class.color : 'black'"
            text-color="white"
          >
            {{ item && item.class ? item.class.name : '' }}
          </v-chip>
          <v-chip
            :color="item && item.status ? item.status.color : 'black'"
            text-color="white"
          >
            {{ item && item.status ? item.status.name : '' }}
          </v-chip>
        </div>
      </div>
    </template>
    <template slot="body">
      <div
        v-if="item"
        class="d-flex flex-column ga-6 pt-2"
      >
        <div class="d-flex flex-column ga-1">
          <span class="title-span">Job</span>
          <div class="desc-div">
            <span
              class="desc-span"
              style="font-weight: bold;"
            >{{ item.job_code ? `${item.job_code}` : '' }}</span> - <span class="desc-span">{{ item.job_name ? item.job_name : item.job_name }}</span> <span
              class="desc-span"
              style="color: #1976D2;"
            >{{ item.job_reference ? `; ${item.job_reference}` : '' }}</span>
          </div>
        </div>

        <div class="d-flex ga-3 align-center">
          <div class="outer-div">
            <span class="title-span">PO#</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.po_number }}</span>
            </div>
          </div>

          <div class="outer-div">
            <span class="title-span">Batch</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.batch_number }}</span>
            </div>
          </div>

          <div class="outer-div">
            <span class="title-span">Tag#</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.tag_number }}</span>
            </div>
          </div>
        </div>

        <span
          class="f8 fw600"
          style="color: #000"
        >Glass Specifications </span>

        <div class="d-flex ga-3 align-center">
          <div class="outer-div">
            <span class="title-span">Glass Code</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.g_code }}</span>
            </div>
          </div>

          <div class="outer-div">
            <span class="title-span">Glass Thickness</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.glass_thickness }}</span>
            </div>
          </div>
        </div>

        <span
          class="f8 fw600"
          style="color: #000"
        >Dimentions </span>

        <div class="d-flex ga-3 align-center">
          <div class="outer-div">
            <span class="title-span">Width</span>
            <div class="desc-div">
              <span class="desc-span">{{ convertToFraction(item.width) }}</span>
            </div>
          </div>

          <div class="outer-div">
            <span class="title-span">Height</span>
            <div class="desc-div">
              <span class="desc-span">{{ convertToFraction(item.height) }}</span>
            </div>
          </div>

          <div class="outer-div">
            <span class="title-span">Quantity</span>
            <div class="desc-div">
              <span class="desc-span">{{ item.quantity }}</span>
            </div>
          </div>
        </div>

        <v-divider style="border-bottom: 1px solid #BDBDBD;" />

        <span
          class="f8 fw600"
          style="color: #000"
        >Status Managemenet </span>

        <div class="outer-div">
          <span class="title-span">Move to Status</span>
          <v-select
            v-model="selectedStatusId"
            :items="statuses"
            item-text="name"
            item-value="id"
            hide-details="true"
            outlined
            clearable
            :rules="[(v) => !!v || 'Move to Status is required']"
            placeholder="Select Status"
          >
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : 'white'"
              >
                {{ item ? item.name : '' }}
              </v-chip>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : 'white'"
              >
                {{ item ? item.name : '' }}
              </v-chip>
            </template>
          </v-select>
        </div>

        <div
          v-if="selectedStatusId === statusIdRejected"
          class="pt-5"
        >
          <span class="title-span">Select Rejected Glass</span>
          <div v-if="item.glass && item.glass.glass_code_type_id == glassCodeTypeIdSealedUnit">
            <v-checkbox
              v-if="(item.glass.ext_heat_treatment == 'HST' || item.glass.ext_heat_treatment == 'TMP') && !checkHasRejection(item, rejection_type_id_exterior)"
              v-model="isExteriorGlassRejected"
              label="Exterior Glass Rejected"
              :true-value="1"
              :false-value="0"
              hide-details
            >
              <template slot="label">
                <span class="desc-span">Exterior Glass Rejected</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="(item.glass.cen_heat_treatment == 'HST' || item.glass.cen_heat_treatment == 'TMP') && !checkHasRejection(item, rejection_type_id_central)"
              v-model="isCentralGlassRejected"
              label="Central Glass Rejected"
              :true-value="1"
              :false-value="0"
              hide-details
            >
              <template slot="label">
                <span class="desc-span">Central Glass Rejected</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="(item.glass.int_heat_treatment == 'HST' || item.glass.int_heat_treatment == 'TMP') && !checkHasRejection(item, rejection_type_id_interior)"
              v-model="isInteriorGlassRejected"
              label="Interior Glass Rejected"
              :true-value="1"
              :false-value="0"
              hide-details
            >
              <template slot="label">
                <span class="desc-span">Interior Glass Rejected</span>
              </template>
            </v-checkbox>
          </div>
          <div v-else>
            <v-checkbox
              v-if="(item.glass.sgl_heat_treatment == 'HST' || item.glass.sgl_heat_treatment == 'TMP') && !checkHasRejection(item, rejection_type_id_single)"
              v-model="isSingleGlassRejected"
              label="Single Glass Rejected"
              :true-value="1"
              :false-value="0"
              hide-details
            >
              <template slot="label">
                <span class="desc-span">{{ item.glass && item.glass.glass_code_type_id == glassCodeTypeIdSpandrel ? 'Spandrel' : 'Single' }} Glass Rejected</span>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div
          v-if="selectedStatusId === statusIdTempering"
          class="pt-5"
        >
          <span class="title-span">Select Qualified Glass</span>
          <div v-if="item.glass && item.glass.glass_code_type_id == glassCodeTypeIdSealedUnit">
            <v-checkbox
              v-if="(item.glass.ext_heat_treatment == 'HST' || item.glass.ext_heat_treatment == 'TMP')"
              v-model="isExteriorGlassQualified"
              label="Exterior Glass Qualified"
              :true-value="1"
              :false-value="0"
              hide-details
              :disabled="!checkHasRejection(item, rejection_type_id_exterior, false)"
            >
              <template slot="label">
                <span class="desc-span">Exterior Glass Qualified</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="(item.glass.cen_heat_treatment == 'HST' || item.glass.cen_heat_treatment == 'TMP')"
              v-model="isCentralGlassQualified"
              label="Central Glass Qualified"
              :true-value="1"
              :false-value="0"
              hide-details
              :disabled="!checkHasRejection(item, rejection_type_id_central, false)"
            >
              <template slot="label">
                <span class="desc-span">Central Glass Qualified</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="(item.glass.int_heat_treatment == 'HST' || item.glass.int_heat_treatment == 'TMP')"
              v-model="isInteriorGlassQualified"
              label="Interior Glass Qualified"
              :true-value="1"
              :false-value="0"
              hide-details
              :disabled="!checkHasRejection(item, rejection_type_id_interior, false)"
            >
              <template slot="label">
                <span class="desc-span">Interior Glass Qualified</span>
              </template>
            </v-checkbox>
          </div>
          <div v-else>
            <v-checkbox
              v-if="(item.glass.sgl_heat_treatment == 'HST' || item.glass.sgl_heat_treatment == 'TMP')"
              v-model="isSingleGlassQualified"
              label="Single Glass Qualified"
              :true-value="1"
              :false-value="0"
              hide-details
              :disabled="!checkHasRejection(item, rejection_type_id_single, false)"
            >
              <template slot="label">
                <span class="desc-span">Single Glass Qualified</span>
              </template>
            </v-checkbox>
          </div>
        </div>
      </div>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    statusIdNew: 1,
    statusIdShipped: 6,
    statusIdReceived: 7,
    statusIdCutting: 10,
    statusIdTempering: 11,
    statusIdRejected: 16,
    selectedStatusId: null,
    glassCodeTypeIdSpandrel: 1,
    glassCodeTypeIdSealedUnit: 2,
    glassCodeTypeIdSingleGlass: 3,
    isExteriorGlassRejected: 0,
    isCentralGlassRejected: 0,
    isInteriorGlassRejected: 0,
    isSingleGlassRejected: 0,
    isExteriorGlassQualified: 0,
    isCentralGlassQualified: 0,
    isInteriorGlassQualified: 0,
    isSingleGlassQualified: 0,
    rejection_type_id_single: 1,
    rejection_type_id_exterior: 2,
    rejection_type_id_central: 3,
    rejection_type_id_interior: 4,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.getStatuses();
  },
  methods: {

    async get(qrCode) {
      this.item = null;
      this.$bus.$emit('loading');
      const poNumberAndItemIndexMap = this.extractPoNumberAndItemIndex(qrCode);
      if (!poNumberAndItemIndexMap) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'QR code not valid');
        return;
      }
      const submitModel = {
        filters: {
          po_number: poNumberAndItemIndexMap.po_number,
          item_index: poNumberAndItemIndexMap.item_index,
          is_pack_slip_null: 1,
        },
      };
      const res = await this.$api.glass.readGlass(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'Glass item not found');
        this.$refs.dialogBox.close();
        return;
      }
      const firstIndex = 0;

      if (res.data[firstIndex].status_id === this.statusIdShipped || res.data[firstIndex].status_id === this.statusIdReceived) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'Glass item already shipped or received');
        this.$refs.dialogBox.close();
        return;
      }
      this.item = res.data[firstIndex];

      if (this.item && this.item.rejections && this.item.rejections.length > 0) {
        this.isExteriorGlassQualified = this.item.rejections.some((x) => x.type_id === this.rejection_type_id_exterior && x.is_qualified === 1) ? 1 : 0;
        this.isCentralGlassQualified = this.item.rejections.some((x) => x.type_id === this.rejection_type_id_central && x.is_qualified === 1) ? 1 : 0;
        this.isInteriorGlassQualified = this.item.rejections.some((x) => x.type_id === this.rejection_type_id_interior && x.is_qualified === 1) ? 1 : 0;
        this.isSingleGlassQualified = this.item.rejections.some((x) => x.type_id === this.rejection_type_id_single && x.is_qualified === 1) ? 1 : 0;
      }
      this.$bus.$emit('loading');
    },

    async getStatuses() {
      const res = await this.$api.glassPoStatus.get();
      this.statuses = res.filter((x) => x.id === this.statusIdNew || x.id === this.statusIdCutting || x.id === this.statusIdTempering || x.id === this.statusIdRejected);
    },

    async submit() {
      const submitModel = {
        po_number: this.item.po_number,
        item_index: this.item.item_index,
        status_id: this.selectedStatusId,
        is_switch_status: true,
      };

      if (this.selectedStatusId === this.statusIdTempering) {
        if (this.item.glass && this.item.glass.glass_code_type_id === this.glassCodeTypeIdSealedUnit) {
          if ((this.item.glass.ext_heat_treatment === 'HST' || this.item.glass.ext_heat_treatment === 'TMP') && this.isExteriorGlassQualified === 0) submitModel.is_switch_status = false;
          if ((this.item.glass.cen_heat_treatment === 'HST' || this.item.glass.cen_heat_treatment === 'TMP') && this.isCentralGlassQualified === 0) submitModel.is_switch_status = false;
          if ((this.item.glass.int_heat_treatment === 'HST' || this.item.glass.int_heat_treatment === 'TMP') && this.isInteriorGlassQualified === 0) submitModel.is_switch_status = false;
        } else if ((this.item.glass.sgl_heat_treatment === 'HST' || this.item.glass.sgl_heat_treatment === 'TMP') && this.isSingleGlassQualified === 0) submitModel.is_switch_status = false;
      }

      if (this.selectedStatusId === this.statusIdRejected) {
        if (this.item.glass && this.item.glass.glass_code_type_id === this.glassCodeTypeIdSealedUnit) {
          if ((this.item.glass.ext_heat_treatment === 'HST' || this.item.glass.ext_heat_treatment === 'TMP') && this.isExteriorGlassRejected === 1) submitModel.is_exterior_glass_rejected = 1;
          if ((this.item.glass.cen_heat_treatment === 'HST' || this.item.glass.cen_heat_treatment === 'TMP') && this.isCentralGlassRejected === 1) submitModel.is_central_glass_rejected = 1;
          if ((this.item.glass.int_heat_treatment === 'HST' || this.item.glass.int_heat_treatment === 'TMP') && this.isInteriorGlassRejected === 1) submitModel.is_interior_glass_rejected = 1;
        } else if ((this.item.glass.sgl_heat_treatment === 'HST' || this.item.glass.sgl_heat_treatment === 'TMP') && this.isSingleGlassRejected === 1) submitModel.is_single_glass_rejected = 1;
      } else if (this.selectedStatusId === this.statusIdTempering) {
        if (this.item.glass && this.item.glass.glass_code_type_id === this.glassCodeTypeIdSealedUnit) {
          if ((this.item.glass.ext_heat_treatment === 'HST' || this.item.glass.ext_heat_treatment === 'TMP') && this.isExteriorGlassQualified === 1) submitModel.is_exterior_glass_rejected = 0;
          if ((this.item.glass.cen_heat_treatment === 'HST' || this.item.glass.cen_heat_treatment === 'TMP') && this.isCentralGlassQualified === 1) submitModel.is_central_glass_rejected = 0;
          if ((this.item.glass.int_heat_treatment === 'HST' || this.item.glass.int_heat_treatment === 'TMP') && this.isInteriorGlassQualified === 1) submitModel.is_interior_glass_rejected = 0;
        } else if ((this.item.glass.sgl_heat_treatment === 'HST' || this.item.glass.sgl_heat_treatment === 'TMP') && this.isSingleGlassQualified === 1) submitModel.is_single_glass_rejected = 0;
      }
      const res = await this.$api.glassPurchaseOrderItem.store(submitModel, 'move');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(qrCode) {
      if (!qrCode) return;
      this.get(qrCode);
      this.$refs.dialogBox.open();
      this.isExteriorGlassRejected = 0;
      this.isCentralGlassRejected = 0;
      this.isInteriorGlassRejected = 0;
      this.isSingleGlassRejected = 0;
    },

    extractPoNumberAndItemIndex(glassItemQr) {
      if (!glassItemQr) return false;
      const [poNumber, itemIndex] = glassItemQr.split('-');
      const poNumberAndItemIndexMap = {
        po_number: parseInt(poNumber, 10),
        item_index: parseInt(itemIndex, 10),
      };
      if (!poNumber || !itemIndex) return false;
      return poNumberAndItemIndexMap;
    },

    convertToFraction(decimal) {
      const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
      };

      // If the decimal is an exact match to any fraction, return the fraction directly
      if (decimal in fractions) {
        return fractions[decimal];
      }

      const wholeNumber = Math.floor(decimal);
      // eslint-disable-next-line no-param-reassign
      decimal -= wholeNumber; // Remove the whole number part

      let closestFraction = Object.keys(fractions)[0];

      // eslint-disable-next-line no-restricted-syntax
      for (const fraction of Object.keys(fractions)) {
        if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
          closestFraction = fraction;
        }
      }

      if (wholeNumber > 0) {
        return `${wholeNumber} - ${fractions[closestFraction]}"`;
      }
      return fractions[closestFraction];
    },

    checkHasRejection(item, rejectionTypeId, isCheckEmpty = true) {
      if (item.rejections && item.rejections.length > 0) {
        return item.rejections.some((x) => x.type_id === rejectionTypeId && x.is_qualified === 0);
      }
      if (isCheckEmpty) return false;
      return true;
    },
  },
};
</script>

<style scoped>

.outer-div{
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

}
.title-span
{
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.desc-div
{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #BDBDBD;
  border-radius: 6px;
  background-color: #F9F9FA;
}

.desc-span
{
  color: #000;
  font-size: 20px;
}
</style>
