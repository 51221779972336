<template>
  <v-app-bar
    class="zm-toolbar"
    app
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer-size')" />
    <v-toolbar-title>{{ pageTitle ? pageTitle.toUpperCase() : '' }}</v-toolbar-title>
    <v-spacer />
    <v-text-field
      v-model="searchText"
      prepend-inner-icon="fas fa-search"
      placeholder="Search"
      single-line
      hide-details
      solo
      rounded
      clearable
      class="ml-5 elevation-0 pa-0"
      style="border: 1px solid #d6d6d6 !important; max-width: 1000px;"
    />
    <v-spacer />
    <h2
      v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
      class="headline font-weight-medium primary--text pr-8"
    >
      {{ name }}
    </h2>
    <v-divider
      v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
      vertical
      inset
    />
    <v-tooltip top>
      <template #activator="{ on }">
        <a
          class="ml-4"
          v-on="on"
          @click="openReportIssue()"
        ><v-icon
          style="font-size: 30px; color: #757575; cursor: pointer;"
        >
          mdi-alert-outline
        </v-icon></a>
      </template>

      <span>Report Issue/Bug</span>
    </v-tooltip>
    <a
      v-if="checkUserPermission(['Admin'])"
      @click="alertsClicked()"
    ><v-badge
      :content="alertsTotal"
      :value="alertsTotal"
      overlap
      class="mr-10 ml-4"
      color="error"
    >
      <v-icon
        @click="alertsClicked()"
      >
        fas fa-bell
      </v-icon>
    </v-badge></a>
    <v-menu
      bottom
      min-width="200px"
      rounded
      offset-y
    >
      <template #activator="{ on }">
        <v-btn
          large
          class="pa-0 mr-0 elevation-0"
          v-on="on"
        >
          <v-avatar
            color="#008E40"
            size="40"
          >
            <span class="white--text text-h6">{{ user ? `${user.first_name[0]}${user.last_name[0]}` : '' }}</span>
          </v-avatar>
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar
              color="#008E40"
              size="56"
            >
              <span class="white--text text-h5">{{ user ? `${user.first_name[0]}${user.last_name[0]}` : '' }}</span>
            </v-avatar>
            <h3 class="pt-3">
              {{ user ? `${user.first_name} ${user.last_name}` : '' }}
            </h3>
            <p class="text-caption mt-1">
              {{ user.email }}
            </p>
            <theme-switch style="margin-left: 50px; margin-bottom: 1rem;" />
            <v-divider class="my-3" />
            <v-row>
              <v-col
                v-if="checkUserPermission(['Admin'])"
                class="pa-1"
              >
                <v-btn
                  depressed
                  tile
                  text
                  block
                  @click="adminClicked()"
                >
                  <v-row>
                    <v-col
                      cols="auto"
                      class="pl-7"
                    >
                      <v-icon class="pr-3">
                        fas fa-cog
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-4 pl-1"
                    >
                      <span>ADMIN</span>
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row>
              <v-col class="pa-1">
                <v-btn
                  depressed
                  tile
                  text
                  block
                  @click="logout()"
                >
                  <v-row>
                    <v-col
                      cols="auto"
                      class="pl-7"
                    >
                      <v-icon class="pr-3">
                        fas fa-sign-out-alt
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-4 pl-1"
                    >
                      <span>LOG OUT</span>
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
    <report-issue-form
      v-show="false"
      ref="reportIssueForm"
    />
  </v-app-bar>
</template>
<script>
import OrganizationsApi from '@/api/OrganizationsApi';
import ThemeSwitch from '@/components/elements/ThemeSwitch';
import ReportIssueForm from '@/components/forms/reportIssues/ReportIssueForm';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';

import { mapGetters, mapState } from 'vuex';

export default {
  components: { ThemeSwitch, ReportIssueForm },
  data: () => ({
    name: '',
    url: '',
    alertsTotal: 0,
    searchText: '',
  }),
  computed: {
    ...mapState({
      physicalCountList: 'physicalCountList',
      user: (state) => state.user,
      search: (state) => state.search,
    }),
    ...mapGetters([
      'isPhysicalCountActivated',
      'checkUserPermission',
    ]),
    pageTitle() {
      return this.$route.meta.title;
    },
  },
  watch: {
    searchText: debounce(function (val) {
      this.$store.commit('storeSearch', val === null ? '' : val);
    }, 500),
    $route(to, from) {
      this.searchText = '';
    },
    search(newVal) {
      this.searchText = newVal;
    },
  },
  created() {
    this.initialize();
    this.$store.commit('storeSearch', '');
  },
  beforeRouteUpdate(to, from, next) {
    this.clearSearchText();
    next();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchOrg()]);
      this.getAlertsNumber();
    },
    async fetchOrg() {
      this.org = await OrganizationsApi.getList();
      this.name = this.org[0].name;
      this.url = this.org[0].url;
    },
    async getAlertsNumber() {
      const res = await this.$api.alert.get('total');
      if (!res) return;
      this.alertsTotal = res;
    },

    adminClicked() {
      this.$router.push('/admin');
    },

    alertsClicked() {
      this.$router.push('/alerts');
    },

    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },

    clearSearchText() {
      this.searchText = '';
    },

    openReportIssue() {
      this.$refs.reportIssueForm.open();
    },

  },
};
</script>
<style>
.zm-toolbar {
  box-shadow: none !important;
  border-bottom: 1px solid #d6d6d6 !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
}
.v-divider--vertical.v-divider--inset {
    border: 1px solid #d6d6d6;
}
div > div > div.v-input__prepend-inner > div > i
{
  font-size: 20px !important;
}
</style>
