<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-tabs
          v-model="selectedTab"
        >
          <v-tab
            @click="tabChanges(0)"
          >
            Windows Dashboard
          </v-tab>
          <v-tab
            @click="tabChanges(1)"
          >
            Reports
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === windowsIndex">
      <v-col class="pb-0">
        <div
          id="8a2c3044-8f96-4ed0-b764-005b0414a4e3"
          class="reportContainer"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === reportsIndex">
      <v-col class="pb-0">
        <div
          class="pa-3 d-flex justify-space-between align-center"
          style="border: 1px solid #e0e0e0; border-radius: 10px;"
        >
          <span style="font-size: 20px; font-weight: 500;">Windows report</span>
          <div>
            <v-btn
              class="ml-1 mt-1 mb-1"
              color="orange"
              outlined
              small
              @click="open()"
            >
              GENERATE PDF
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :submit-btn-text="'Submit'"
    >
      <template slot="header">
        <span>Windows Report</span>
      </template>
      <template slot="body">
        <v-row>
          <v-col
            class="pb-0"
          >
            <v-text-field
              v-model="name"
              :rules="[(v) => (!!v || 'Work Order is required')]"
            >
              <template slot="label">
                Work Order<RedAsterisk />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as pbi from 'powerbi-client';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    selectedTab: null,
    items: [],
    dashboardUser: null,
    windowsIndex: 0,
    windowsId: '8a2c3044-8f96-4ed0-b764-005b0414a4e3',
    reportsIndex: 1,
    previousIdreport: null,
    reports: [],
    name: null,
  }),

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
  },
  mounted() {
    this.getEmbedReports();
  },

  created() {
    this.init();
  },
  methods: {
    init() {
    },

    async getEmbedReports() {
      const reportMappings = [
        { index: this.glassPurchseOrderIndex, id: this.glassPurchaseOrderId },
        { index: this.chrisleaInventoryIndex, id: this.chrisleaInventoryId },
        { index: this.procoreCorrespondenceIndex, id: this.procoreCorrespondenceId },
        { index: this.awdBreakdownIndex, id: this.awdBreakdownId },
        { index: this.awdPaintConsumptionIndex, id: this.awdPaintConsumptionId },
        { index: this.windowsIndex, id: this.windowsId },
      ];

      const res = await this.$api.dashboards.get('auth');
      if (!res) return;

      // Filter and map the reports based on the defined mappings
      this.reports = reportMappings
        .map(({ id }) => res.find((item) => item.report_id === id) || null)
        .filter((report) => report !== null);

      if (this.reports.length > 0) {
        this.embedReport(this.reports[0]); // Embed the first report if available
      }
    },

    embedReport(report) {
      const { models } = pbi;
      const config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: report.embed_token,
        embedUrl: report.embed_url,
        id: report.report_id,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
          },
          bars: {
            statusBar: {
              visible: false,
            },
          },
        },
      };

      if (this.previousIdreport) {
        const prevReportContainer = document.getElementById(this.previousIdreport);
        if (prevReportContainer) {
          // eslint-disable-next-line no-undef
          powerbi.reset(prevReportContainer);
        }
      }

      this.previousIdreport = report.report_id;

      const reportContainer = document.getElementById(`${report.report_id}`);

      if (reportContainer) {
        // eslint-disable-next-line no-undef
        powerbi.embed(reportContainer, config);
      }
    },

    tabChanges(index) {
      this.selectedTab = index;
    },

    open() {
      this.$refs.dialogBox.open();
    },

    async submit() {
      this.$bus.$emit('loading');

      const webhookModuleIdWindows = 53;
      const webhookData = {
        module_id: webhookModuleIdWindows,
        work_order: this.name,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Windows_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },
  },
};
</script>

  <style scoped>
  ::v-deep iframe {
          border: none !important;
      }

      .reportContainer {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
  }
  </style>
