<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
    :full-screen="true"
  >
    <template slot="header">
      <span
        v-if="item"
        style="font-size: 30px; font-weight: 500; color: #000000;"
      >Job: {{ item.wo_number }} - {{ item.project }}; {{ item.project_floor }}</span>
      <v-col class="text-end">
        <span style="font-size: 30px; font-weight: 500; color: #000000;">Total Items: {{ items.length }}</span>
      </v-col>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
              :items-per-page="-1"
              :loading="loading"
              dense
            >
              <template #[`item.cut_height`]="{ item }">
                <span>
                  {{ item.cut_height ? item.cut_height : '-' }}
                </span>
              </template>
              <template #[`item.location`]="{ item }">
                <span>
                  {{ item.location ? item.location.name : '' }}
                </span>
              </template>
              <template #[`item.inventory_location`]="{ item }">
                <span>
                  {{ item.inventory_location ? item.inventory_location.name : '' }}
                </span>
              </template>
              <template #[`item.status`]="{ item }">
                <!-- <v-chip
                  v-if="item && item.status"
                  small
                  :color="item.status.color ? item.status.color : '#000000'"
                  text-color="white"
                >
                  {{ item.status.name }}
                </v-chip> -->
                <v-tooltip
                  bottom
                  color="black"
                >
                  <template #activator="{ on }">
                    <v-chip
                      v-if="item && item.status"
                      small
                      :color="item.status.color ? item.status.color : '#000000'"
                      text-color="white"
                      @mouseover="getHistory(item)"
                      v-on="on"
                    >
                      {{ item.status.name }}
                    </v-chip>
                  </template>

                  <div
                    style="width: 400px; flex-direction: column; gap: 12px;"
                    class="pt-3 d-flex"
                  >
                    <div
                      v-for="history in finishedGoodHistories"
                      :key="history.id"
                      class="d-flex align-center"
                    >
                      <div style="width: 50%">
                        <v-chip
                          v-if="history && history.status"
                          small
                          :color="history.status.color ? history.status.color : '#000000'"
                          text-color="white"
                        >
                          {{ history.status.name }}
                        </v-chip>
                      </div>
                      <span> At: {{ history.created_at }}</span>
                    </div>
                  </div>
                </v-tooltip>
              </template>
              <template #[`item.note`]="{ item }">
                <span
                  v-if="item.notes && item.notes.length > 0"
                  style="white-space: nowrap; color: rgb(255, 152, 0);"
                >
                  <v-btn
                    icon
                    small
                    @click="openNoteForm(item.id)"
                  >
                    <v-icon
                      small
                    >
                      mdi-eye
                    </v-icon>
                  </v-btn>
                  <span>{{ item.notes && item.notes.length > 0 ? item.notes[0].text : '' }}</span>
                </span>
                <v-btn
                  v-else
                  color="primary"
                  small
                  icon
                  @click="openNoteForm(item.id)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <NoteForm
        ref="noteForm"
        :simple="true"
        @success="get(item, statusId)"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NoteForm from '@/components/forms/notes/NoteForm.vue';

export default {
  components: {
    NoteForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    loading: false,
    item: null,
    noteTypeIdFinishedGood: 3,
    finishedGoodStatusIdReturned: 12,
    statusIdFinishedGoods: 8,
    statusId: null,
    finishedGoodHistories: [],
    loadingHistory: false,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
    headers() {
      const returnHeaders = [];
      returnHeaders.push({ text: 'Serial#', value: 'index' });
      returnHeaders.push({ text: 'Tag Id', value: 'tag_id' });
      returnHeaders.push({ text: 'Tag Number', value: 'tag_number' });
      returnHeaders.push({ text: 'Work Order', value: 'work_order' });
      returnHeaders.push({ text: 'Reference', value: 'floor_number' });
      returnHeaders.push({ text: 'Cut Width', value: 'cut_width' });
      returnHeaders.push({ text: 'Cut Height', value: 'cut_height' });
      returnHeaders.push({ text: 'Sqft', value: 'sqft' });

      if (this.statusId > this.statusIdFinishedGoods) {
        returnHeaders.push({ text: 'Location', value: 'location' });
        returnHeaders.push({ text: 'Shelf', value: 'inventory_location' });
      }

      if (this.statusId === this.finishedGoodStatusIdReturned) {
        returnHeaders.push({
          text: 'Return Reason', value: 'return_reason',
        });
      }

      returnHeaders.push({ text: 'Status', value: 'status', align: 'center' });
      returnHeaders.push({ text: 'Note', value: 'note', align: 'center' });

      return returnHeaders;
    },
  },
  watch: {

  },
  created() {
  },
  methods: {

    async get(item, statusId) {
      this.loading = true;
      this.items = [];
      const submitModel = {
        filters: {
          production_schedule_id: item.id,
          status_id: statusId,
        },
      };
      const res = await this.$api.finishedGood.read(submitModel);
      this.loading = false;
      if (!res) return;
      this.items = res;
    },

    async getHistory(item) {
      if (this.loadingHistory) return;
      this.loadingHistory = true;
      const submitModel = {
        filters: {
          finished_good_id: item.id,
        },
      };

      const res = await this.$api.finishedGoodHistory.read(submitModel);

      this.loadingHistory = false;
      if (!res || !res.data || res.data.length === 0) {
        this.finishedGoodHistories = [];
        return;
      }

      this.finishedGoodHistories = res.data;
    },

    open(item, statusId) {
      this.item = item;
      this.statusId = statusId;
      this.get(item, statusId);
      this.$refs.dialogBox.open();
    },

    openNoteForm(itemId) {
      this.$refs.noteForm.open(itemId, this.noteTypeIdFinishedGood);
    },
  },
};
</script>
