<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :full-screen="true"
    :persistent="true"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Invoice {{ item && item.id ? `#${item.id}` : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-row>
            <v-col
              cols="2"
              class="pb-0"
            >
              <inventory-class-select-element
                v-model="item.inventory_class_id"
                :is-required="true"
                :is-add-all="false"
              />
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <v-select
                v-model="item.sub_class_id"
                label="Sub Class"
                :items="subClasses"
                hide-details="true"
                item-value="id"
                item-text="name"
                clearable
              >
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-autocomplete
                v-model="item.client_id"
                label="Bill To"
                :items="clients"
                hide-details="true"
                item-value="id"
                item-text="name"
                clearable
                :loading="loadingClients"
                :rules="[(v) => !!v || 'Bill To is required']"
              >
                <template slot="label">
                  Bill To<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-autocomplete
                v-model="item.job_id"
                label="Job"
                :items="jobs"
                hide-details="true"
                item-value="id"
                :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
                clearable
                :loading="loadingJobs"
                :rules="[(v) => !!v || 'Job is required']"
              >
                <template #selection="{ item }">
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>

                <template #item="{ item }">
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>
                <template slot="label">
                  Job<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <v-autocomplete
                v-model="item.term_id"
                label="Term"
                :items="terms"
                hide-details="true"
                item-value="id"
                item-text="name"
                clearable
                :rules="[(v) => !!v || 'Term is required']"
              >
                <template slot="label">
                  Term<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <v-autocomplete
                v-model="item.currency_id"
                label="Currency"
                :items="currencies"
                hide-details="true"
                item-value="id"
                item-text="name"
                clearable
                :rules="[(v) => !!v || 'Currency is required']"
              >
                <template slot="label">
                  Currency<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <v-menu
                ref="menuDate"
                v-model="dateMenuDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.date"
                    label="Invoice Date"
                    readonly
                    v-bind="attrs"
                    append-icon="mdi-calendar"
                    :rules="[(v) => !!v || 'Invoice Date is required']"
                    v-on="on"
                    @change="$refs.menuDate.save(item.date)"
                  >
                    <template slot="label">
                      Invoice Date<RedAsterisk />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="item.date"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="item.date = null"
                  >
                    Clear
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="auto"
            >
              <span style="color: #000000; font-size: 18px; font-weight: bold;">All Charges</span>
            </v-col>
            <v-spacer />
            <v-col
              cols="auto"
            >
              <v-btn
                color="primary"
                outlined
                @click="openInvoiceItem"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  fas fa-plus
                </v-icon>
                New Charge
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-card
                elevation="0"
                outlined
              >
                <v-data-table
                  :headers="headers"
                  :items="item.invoice_items"
                  :loading="loading"
                  hide-default-footer
                  :items-per-page="-1"
                  dense
                >
                  <template #[`item.description`]="{ item }">
                    <span>{{ item.description }}</span>
                  </template>
                  <template #[`item.quantity`]="{ item }">
                    <span>{{ item.quantity }}</span>
                  </template>
                  <template #[`item.price`]="{ item }">
                    <span>{{ findCurrency() }} {{ item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                  </template>
                  <template #[`item.sub_total`]="{ item }">
                    <span>{{ findCurrency() }} {{ item.sub_total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                  </template>
                  <template #[`item.tax`]="{ item }">
                    <span class="text-end">{{ findCurrency() }} {{ item.tax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                  </template>
                  <template #[`item.total`]="{ item }">
                    <span>{{ findCurrency() }} {{ item.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-btn
                      class="ml-1 mt-1 mb-1"
                      color="primary"
                      outlined
                      small
                      @click="openEditInvoiceItem(item)"
                    >
                      EDIT
                    </v-btn>
                    <v-btn
                      class="ml-1 mt-1 mb-1"
                      color="error"
                      outlined
                      small
                      @click="softDelete(item)"
                    >
                      DELETE
                    </v-btn>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <th class="text-end">
                        <span style="color: #000000; font-weight: bold; font-size: 18px;">TOTAL: </span>
                      </th>
                      <th class="text-end">
                        <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ getTotalQuantityItems() }}</span>
                      </th>
                      <th
                        class="text-end"
                      >
                        <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '14px', whiteSpace: 'nowrap' }">{{ findCurrency() }} {{ parseFloat(getTotalUnitPrice()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                      </th>
                      <th
                        class="text-end"
                      >
                        <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '14px', whiteSpace: 'nowrap' }">{{ findCurrency() }} {{ parseFloat(getSubtotal()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                      </th>
                      <th
                        class="text-end"
                      >
                        <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '14px', whiteSpace: 'nowrap' }">{{ findCurrency() }} {{ parseFloat(getTotalTax()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                      </th>
                      <th
                        class="text-end"
                      >
                        <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '14px', whiteSpace: 'nowrap' }">{{ findCurrency() }} {{ parseFloat(getTotal()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                      </th>
                      <th />
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row align="center">
                <v-col
                  class="pb-0"
                >
                  <span :style="{ color: '#000000', fontSize: '18px' }">Notes</span>
                </v-col>
                <v-col
                  v-if="item.id"
                  class="text-end"
                >
                  <v-btn
                    color="orange"
                    outlined
                    @click="openNoteForm"
                  >
                    SEE ALL NOTES
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="pt-0"
                >
                  <v-textarea
                    v-model="item.note"
                    :disabled="item.notes && item.notes.length > 0"
                    outlined
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col
              cols="auto"
              class="mr-10"
            >
              <v-row>
                <v-col class="pt-2">
                  <v-checkbox
                    v-model="item.is_include_tax"
                    :true-value="1"
                    :false-value="0"
                    class="mt-0"
                    persistent-hint
                    hint="If checked, tax will be applied to the total."
                  >
                    <template slot="label">
                      <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px' }">Is tax included?</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row>
                <v-col
                  class="text-end pr-0"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Subtotal:</span>
                </v-col>
                <v-col
                  class="text-end pl-0"
                  style="min-width: 250px !important;"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">{{ findCurrency() }} {{ parseFloat(getSubtotal()).toLocaleString('en-US', { minimumFractionDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="text-end pr-0"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Tax:</span>
                </v-col>
                <v-col
                  class="text-end pl-0"
                  style="min-width: 250px !important;"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">{{ findCurrency() }} {{ parseFloat(getTotalTax()).toLocaleString('en-US', { minimumFractionDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="text-end pr-0"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '20px' }">Total:</span>
                </v-col>
                <v-col
                  class="text-end pl-0"
                  style="min-width: 250px !important;"
                >
                  <span :style="`font-weight: bold; font-size: 20px; color: ${isDarkMode ? '' : '#000000'};`">{{ findCurrency() }} {{ parseFloat(getTotal()).toLocaleString('en-US', { minimumFractionDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <invoice-item-form
        ref="itemForm"
        @success="addInvoiceItem"
      />
      <confirm-form ref="confirmForm" />
      <note-form
        v-show="false"
        ref="noteForm"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NoteForm from '@/components/forms/notes/NoteForm';
import InvoiceItemForm from './InvoiceItemForm.vue';

export default {
  components: {
    InvoiceItemForm,
    NoteForm,
  },
  props: {
  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      client_id: null,
      job_id: null,
      inventory_class_id: null,
      currency_id: null,
      term_id: null,
      is_include_tax: 1,
      date: null,
      invoice_items: [],
      note: null,
      is_manual: 0,
      type_id: 1,
      sub_class_id: null,
    },
    headers: [
      {
        text: 'Description', value: 'description', width: '30%', class: 'pr-4',
      },
      {
        text: 'Quantity', value: 'quantity', width: '10%', class: ' text-end pr-4', align: 'end',
      },
      {
        text: 'Unit Price', value: 'price', width: '10%', class: ' text-end pr-4', align: 'end',
      },
      {
        text: 'Subtotal', value: 'sub_total', width: '10%', class: ' text-end pr-4', align: 'end',
      },
      {
        text: 'Tax', value: 'tax', width: '10%', class: ' text-end pr-4', align: 'end',
      },
      {
        text: 'Total', value: 'total', width: '10%', class: ' text-end pr-4', align: 'end',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '20%', class: '',
      },
    ],

    clients: [],
    jobs: [],
    taxes: [],
    terms: [],
    currencies: [],
    subClasses: [],

    loading: false,
    loadingClients: false,
    loadingJobs: false,
    dateMenuDate: false,
    currencyIdCanadian: 1,
    termId30: 2,
    noteTypeIdInvoice: 4,
    inventoryClassId4800: 2,
    clientIdAWDInstallation: 50,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    'item.is_include_tax': {
      handler(val) {
        if (val === 1) {
          this.item.invoice_items.forEach((item) => {
            const tax = this.taxes[0];
            if (!tax) return;
            // eslint-disable-next-line no-param-reassign
            item.total = item.sub_total + (item.sub_total * tax.rate);
            // eslint-disable-next-line no-param-reassign
            item.tax = item.total - item.sub_total;
          });
        } else {
          this.item.invoice_items.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.total = item.sub_total;
            // eslint-disable-next-line no-param-reassign
            item.tax = 0;
          });
        }
      },
      immediate: false,
    },
  },
  created() {
    this.item = { ...this.itemDto };
    this.getTaxes();
  },
  methods: {

    async get(id, password) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
          password,
        },
      };
      const res = await this.$api.invoice.read(submitModel);
      if (!res || !res.data || !res.data[0]) return;
      const firstIndex = 0;
      this.item = res.data[firstIndex];

      if (this.item.invoice_items.length > 0) {
        this.item.invoice_items.forEach((item, index) => {
          this.item.invoice_items[index].index = Math.floor(Math.random() * 100000000);
        });
      }
    },

    async getClients() {
      this.loadingClients = true;
      const readModel = {
        filters:
        {
        },
      };
      this.clients = await this.$api.client.read(readModel);
      this.loadingClients = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: { inventory_class_id: this.item.inventory_class_id },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobs = [];
        this.loadingJobs = false;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },

    async getTaxes() {
      this.taxes = await this.$api.tax.get();
    },

    async getTerms() {
      this.terms = await this.$api.term.get();
    },

    async getCurrencies() {
      this.currencies = await this.$api.currency.get();
    },

    async getSubClasses() {
      this.subClasses = await this.$api.jobSubClass.get();
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.invoice.update(this.item);
      } else {
        res = await this.$api.invoice.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      if (this.item.note && !this.item.notes) {
        const note = {
          text: this.item.note,
          record_id: res,
          type_id: this.noteTypeIdInvoice,
        };
        const res4 = await this.$api.note.store(note);
        if (!res4) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading');
          return false;
        }
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(item, isManual, jobId, invoiceItems, typeId, password) {
      this.$refs.dialogBox.open();
      if (item) {
        this.get(item.id, password);
      } else {
        this.item = { ...this.itemDto };
        this.item.currency_id = this.currencyIdCanadian;
        this.item.term_id = this.termId30;
        this.item.is_include_tax = 1;
        this.item.is_manual = isManual;
        this.item.inventory_class_id = this.inventoryClassId4800;
        this.item.client_id = this.clientIdAWDInstallation;
        this.item.invoice_items = [];
        this.item.type_id = typeId;
        if (jobId) {
          this.item.job_id = jobId;
        }
        if (invoiceItems && invoiceItems.length > 0) {
          const tax = this.taxes[0];
          this.item.invoice_items = invoiceItems.map((x, index) => {
            const price = parseFloat(x.total) / parseFloat(x.total_quantity);
            const returnItem = {
              description: x.job_description,
              quantity: x.total_quantity,
              price: price.toFixed(2),
              sub_total: parseFloat(x.sub_total),
              tax: parseFloat(x.sub_total * tax.rate),
              total: parseFloat(x.sub_total) + parseFloat(x.sub_total * tax.rate),
              index: Math.floor(Math.random() * 100000000),
            };
            return returnItem;
          });
        }
      }
      this.getClients();
      this.getJobs();
      this.getTaxes();
      this.getTerms();
      this.getCurrencies();
      this.getSubClasses();
    },

    openInvoiceItem() {
      this.$refs.itemForm.open();
    },

    openEditInvoiceItem(item) {
      this.$refs.itemForm.open(item);
    },

    addInvoiceItem(item) {
      if (!item) return;
      if (item.index != null) {
        const index = this.item.invoice_items.findIndex((x) => x.index === item.index);
        if (index === -1) return;
        this.$set(this.item.invoice_items, index, item);
      } else {
        this.item.invoice_items.push(item);
        this.item.invoice_items[this.item.invoice_items.length - 1].index = Math.floor(Math.random() * 100000000);
      }
    },

    findCurrency() {
      if (!this.item.currency_id || !this.currencies) return '';
      const currency = this.currencies.find((c) => c.id === this.item.currency_id);
      if (!currency) return '';
      return currency.symbol_short;
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (!confirm) return;
      const index = this.item.invoice_items.findIndex((x) => x.index === item.index);
      if (index === -1) return;
      this.item.invoice_items.splice(index, 1);
    },

    openNoteForm() {
      this.$refs.noteForm.open(this.item.id, this.noteTypeIdInvoice);
    },

    getTotalUnitPrice() {
      let totalUnitPrice = 0;
      this.item.invoice_items.forEach((item) => {
        totalUnitPrice += item.price;
      });
      return totalUnitPrice;
    },

    getSubtotal() {
      let subtotal = 0;
      this.item.invoice_items.forEach((item) => {
        subtotal += item.sub_total;
      });
      return subtotal;
    },

    getTotal() {
      let total = 0;
      this.item.invoice_items.forEach((item) => {
        total += item.total;
      });
      return total;
    },

    getTotalTax() {
      let totalTax = 0;
      this.item.invoice_items.forEach((item) => {
        totalTax += item.tax;
      });
      return totalTax;
    },

    getTotalQuantityItems() {
      let totalQuantity = 0;
      this.item.invoice_items.forEach((item) => {
        totalQuantity += parseInt(item.quantity, 10);
      });
      return totalQuantity;
    },

    isBtnDisabled() {
      return this.item.is_manual === 0;
    },
  },
};
</script>
