<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
          :is-inst="false"
          :is-vwd-glass="false"
          :is-rail-part="false"
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="clientId"
          label="Client"
          :items="clients"
          hide-details="true"
          item-value="id"
          item-text="name"
          clearable
          :loading="loadingClients"
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="jobId"
          label="Job"
          :items="jobs"
          hide-details="true"
          item-value="id"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="typeId"
          label="Type"
          :items="types"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col
        cols="auto"
        class="pr-0 pt-0"
      >
        <v-btn
          color="orange"
          outlined
          @click="open(typeIdCreditMemo)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New CREDIT MEMO
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="pt-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open(typeIdInvoice)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New manual Invoice
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0 pb-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #item="{ item }">
              <tr>
                <td class="pr-1 pl-1">
                  <span class="font-weight-bold">
                    <text-highlight :queries="search">
                      {{ item.id }}
                    </text-highlight>
                  </span>
                </td>
                <td class="text-center pr-1 pl-1">
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </td>
                <td class="text-center pr-1 pl-1">
                  <v-chip
                    v-if="item.sub_class"
                    small
                    :color="item && item.sub_class ? item.sub_class.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.sub_class ? item.sub_class.name : '' }}
                  </v-chip>
                </td>
                <td class="pr-1 pl-1">
                  <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">
                    {{ item.job_name ? item.job_name : '' }}
                  </text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.client ? item.client.name : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.term ? item.term.name_short : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.date ? item.date : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.total_quantity_items ? item.total_quantity_items : '0' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.created_at_short ? item.created_at_short : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.user_full_name ? item.user_full_name : '' }}
                  </text-highlight>
                </td>
                <td class="text-center pr-1 pl-1">
                  <v-chip
                    small
                    :color="item && item.type ? item.type.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.type ? item.type.name : '' }}
                  </v-chip>
                </td>
                <td
                  class="text-end pr-1 pl-1"
                  style="white-space: nowrap;"
                >
                  <text-highlight
                    v-if="item.type_id === typeIdInvoice"
                    :queries="search"
                  >
                    {{ item.currency ? item.currency.symbol_short : '' }}{{ item.sub_total ? item.sub_total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0' }}
                  </text-highlight>
                  <text-highlight
                    v-else
                    style="color: red;"
                    :queries="search"
                  >
                    ({{ item.currency ? item.currency.symbol_short : '' }}{{ item.sub_total ? item.sub_total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0' }})
                  </text-highlight>
                </td>
                <td
                  class="text-end pr-1 pl-1"
                  style="white-space: nowrap;"
                >
                  <text-highlight
                    v-if="item.type_id === typeIdInvoice"
                    :queries="search"
                  >
                    {{ item.currency ? item.currency.symbol_short : '' }}{{ item.tax ? item.tax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0' }}
                  </text-highlight>
                  <text-highlight
                    v-else
                    style="color: red;"
                    :queries="search"
                  >
                    ({{ item.currency ? item.currency.symbol_short : '' }}{{ item.tax ? item.tax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0' }})
                  </text-highlight>
                </td>
                <td
                  class="text-end pr-1 pl-1"
                  style="white-space: nowrap;"
                >
                  <text-highlight
                    v-if="item.type_id === typeIdInvoice"
                    :queries="search"
                  >
                    {{ item.currency ? item.currency.symbol_short : '' }}{{ item.total ? item.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0' }}
                  </text-highlight>
                  <text-highlight
                    v-else
                    style="color: red;"
                    :queries="search"
                  >
                    ({{ item.currency ? item.currency.symbol_short : '' }}{{ item.total ? item.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0' }})
                  </text-highlight>
                </td>
                <td
                  class="text-end pr-1 pl-1"
                  style="white-space: nowrap;"
                >
                  <v-btn
                    class="ml-1 mt-1 mb-1 mr-1"
                    color="primary"
                    outlined
                    small
                    @click="openEdit(item)"
                  >
                    EDIT
                  </v-btn>
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mb-1"
                        elevation="0"
                        fab
                        outlined
                        x-small
                        v-bind="attrs"
                        tile
                        color="primary"
                        style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="pa-3"
                    >
                      <v-list-item
                        @click="runWebhook(item)"
                      >
                        <v-list-item-title :style="`color: green; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                color="green"
                              >
                                fas fa-file
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              PDF
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="softDelete(item)"
                      >
                        <v-list-item-title :style="`color: red; font-weight: 600;`">
                          <v-row align="center">
                            <v-col class="pt-4">
                              DELETE
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <the-dialog-box
      ref="dialogBox"
      :submit="get"
      :submit-btn-text="'Submit'"
      :persistent="true"
      :btn-loading="loading"
    >
      <template slot="header">
        <span>Enter password</span>
      </template>
      <template slot="body">
        <v-row>
          <v-col
            class="pb-0"
          >
            <v-text-field
              v-model="passwordForm"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Password is required']"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
    <invoice-form
      ref="itemForm"
      @success="get"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InvoiceForm from '@/components/forms/invoices/InvoiceForm.vue';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    InvoiceForm,

  },
  props: {

  },
  data: () => ({
    items: [],
    jobs: [],
    clients: [],
    total: 0,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    headers: [
      {
        text: '#', value: 'id', width: '1%', align: 'center', class: 'pl-1 pr-1',
      },
      {
        text: 'Class', value: 'inventory_class', width: '3%', class: 'pl-1 pr-1',
      },
      {
        text: 'Sub Class', value: 'sub_class', width: '5%', class: 'pl-1 pr-1',
      },
      {
        text: 'Job', value: 'color_code', width: '15%', class: 'pl-1 pr-1',
      },
      {
        text: 'Bill To', value: 'supplier_code', width: '10%', class: 'pl-1 pr-1',
      },
      {
        text: 'Term', value: 'total_quantity_items', width: '5%', class: 'pl-1 pr-1',
      },
      {
        text: 'Invoice Date', value: 'total', width: '5%', class: 'pl-1 pr-1',
      },
      {
        text: 'Total Qty.', value: 'total', width: '5%', class: 'pl-1 pr-1',
      },
      {
        text: 'Created At', value: 'total', width: '5%', class: 'pl-1 pr-1',
      },
      {
        text: 'Created By', value: 'created_by', width: '5%', class: 'pl-1 pr-1',
      },
      {
        text: 'Type', value: 'type', width: '5%', class: 'pl-1 pr-1', align: 'center',
      },
      {
        text: 'Subtotal', value: 'location_name', width: '7%', class: 'pl-1 pr-1', align: 'end',
      },
      {
        text: 'Tax', value: 'fob_option_name', width: '7%', class: 'pl-1 pr-1', align: 'end',
      },
      {
        text: 'Total', value: 'shipping_option_name', width: '7%', class: 'pl-1 pr-1', align: 'end',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '10%', class: 'pl-1 pr-1',
      },
    ],
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },

    inventoryClassId: null,
    jobId: null,
    clientId: null,

    loading: false,
    loadingJobs: false,
    loadingClients: false,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    topSpaceHeight: 270,
    windowHeight: 1000,
    passwordForm: null,
    password: null,
    isInit: false,
    showPassword: false,
    typeIdInvoice: 1,
    typeIdCreditMemo: 2,
    types: [],
    typeId: null,

  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    inventoryClassId: {
      handler() {
        if (this.isInit) return;
        this.get();
        this.getJobs();
        this.getClients();
      },
      immediate: false,
    },
    jobId() {
      this.get();
    },
    clientId() {
      this.get();
    },
    typeId() {
      this.get();
    },
    search() {
      this.get();
    },
    pagination() {
      this.get();
    },
  },
  created() {
    this.isInit = true;
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    if (!this.passwordForm) {
      this.$refs.dialogBox.open();
    }

    this.$nextTick(async () => {
      this.isInit = false;
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      if (!this.password) this.password = JSON.parse(JSON.stringify(this.passwordForm));
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          job_id: this.jobId,
          client_id: this.clientId,
          password: this.password,
          type_id: this.typeId,
        },
        search: this.search,
        pagination: this.pagination,
      };
      const res = await this.$api.invoice.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        this.password = null;
        this.$bus.$emit('showError', 'Password is incorrect. Please try again.');
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
      this.getJobs();
      this.getClients();
      this.getTypes();
      this.$refs.dialogBox.close();
    },

    async getClients() {
      this.loadingClients = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };
      this.clients = await this.$api.client.read(readModel);
      this.loadingClients = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
          is_manual: 0,
        },
      };
      const res = await this.$api.job.readDataSecondary(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobs = [];
        this.loadingJobs = false;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },

    async getTypes() {
      const res = await this.$api.invoiceType.get();
      if (!res) return;
      this.types = res;
    },

    open(typeId) {
      this.$refs.itemForm.open(null, 1, null, null, typeId);
    },

    openEdit(item) {
      this.$refs.itemForm.open(item, 1, null, null, null, this.password);
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this Invoice?',
      });
      if (!confirm) return;
      const res = await this.$api.invoice.delete(`${item.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
        this.pagination = pagination;
      }
    },

    async runWebhook(item) {
      this.$bus.$emit('loading');

      const webhookModuleIdInvoice = 47;
      const webhookModuleIdInvoiceCreditMemo = 48;
      let webhookModuleId = webhookModuleIdInvoice;
      if (item.type_id === this.typeIdCreditMemo) {
        webhookModuleId = webhookModuleIdInvoiceCreditMemo;
      }
      const webhookData = {
        module_id: webhookModuleId,
        id: item.id,
        inventory_class_id: item.inventory_class_id,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.id}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },
  },
};
</script>

<style scoped>
span {
  font-size: 12px;
}
</style>
