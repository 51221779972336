<template>
  <v-navigation-drawer
    v-model="drawer"
    :color="color"
    :drawer="drawer"
    dark
    fixed
    :app="true"
    grow
  >
    <v-row>
      <v-spacer />
      <v-col
        class="pb-0 pt-8"
        cols="10"
      >
        <img
          src="@/assets/balanced-logo-white.svg"
          width="200"
        >
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-6 text-center">
        <span
          style="color: white; font-size: 14px;"
        >v{{ appVersion }}</span>
      </v-col>
    </v-row>

    <v-list
      dense
      nav
    >
      <v-list-item-group>
        <template v-for="(item, index) in items">
          <v-list-item
            :key="item.key"
            :to="item.link"
            :style="item.style"
          >
            <v-row>
              <v-col
                class="pt-4 pb-4"
              >
                <v-row
                  align="center"
                >
                  <v-col
                    cols="auto"
                    class="pr-0 pt-2"
                  >
                    <v-icon
                      :color="item.iconColor ? item.iconColor : ''"
                      :style="item.iconStyle"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    <span style="font-size: 14px; font-weight: 500;">{{ item.title }}</span>
                  </v-col>
                  <v-col
                    v-if="item.subItems && item.subItems.length > 0"
                    cols="auto"
                    class="pr-1 pl-0"
                  >
                    <v-btn
                      small
                      style="background: transparent;"
                      elevation="0"
                      @click="toggleItemOpen(item)"
                      @click.prevent
                    >
                      <v-icon
                        small
                        style="color: #FFFFFF;"
                      >
                        fas fa-angle-down
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-container
                  v-if="item.equipment && item.isOpen"
                  class="pa-0 mt-2"
                >
                  <v-list-item
                    v-for="subItem in item.subItems"
                    :key="subItem.key"
                    active-class="sub-item-selected"
                    class="navbar-subitem"
                    @click="openEquipmentMovementForm(subItem.title)"
                  >
                    <v-list-item-icon class="mb-0">
                      <v-icon small>
                        {{ subItem.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <div style="font-size: 0.75rem">
                      {{ subItem.title }}
                    </div>
                  </v-list-item>
                </v-container>
                <v-container
                  v-if="!item.equipment && item.isOpen"
                  class="pa-0 mt-2"
                >
                  <v-list-item
                    v-for="subItem in item.subItems"
                    :key="subItem.key"
                    :to="subItem.link"
                    active-class="sub-item-selected"
                    class="navbar-subitem"
                    @click.stop
                  >
                    <v-list-item-icon
                      class="mb-0"
                    >
                      <v-icon
                        :color="subItem.iconColor ? subItem.iconColor : ''"
                        small
                      >
                        {{ subItem.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <div
                      style="font-size: 0.75rem"
                    >
                      {{ subItem.title }}
                    </div>
                  </v-list-item>
                </v-container>
              </v-col>
            </v-row>
          </v-list-item>
          <!-- eslint-disable-next-line vue/valid-v-for -->
          <v-divider
            v-if="items[index + 1] && items[index + 1].groupId != item.groupId"
            color="#757575"
            class="mt-2 mb-2"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'NavigationDrawer',
  components: { },
  data: () => ({
    drawer: false,
    isEquipmentSubmenuOpen: false,
    color: '#323232',
    name: '',
    isReportAssigned: false,
    items: [],
  }),
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getAdminModuleValue',
      'checkUserPermission',
      'checkUserPermissionExcept',
    ]),
    ...mapState({
      adminModules: (state) => state.admin_modules,
      userId: (state) => state.user.id,
    }),

    appVersion() {
      return this.$store.getters.appVersion;
    },
  },
  watch: {
    adminModules: {
      handler() {
        this.get();
      },
      deep: true,
    },
  },
  created() {
    if (!this.isLoggedIn) return;
    this.get();
  },
  methods: {

    get() {
      const items = [];

      // remove read, read-write later
      const clientPermission = this.checkUserPermission(['Admin', 'Associate', 'Read', 'Read-Write']);
      const clientValue = this.getAdminModuleValue('Clients');
      let clientTitle = 'CLIENTS';
      const supplierValue = this.getAdminModuleValue('Suppliers');
      if (supplierValue) {
        clientTitle = 'CLIENTS / SUPPLIERS';
      }
      if (clientPermission && clientValue) {
        items.push({
          icon: 'fas fa-address-card',
          title: clientTitle,
          key: 1,
          link: '/clients',
          iconStyle: 'font-size: 20px; padding-top: 4px;',
          groupId: 1,
        });
      }

      const scopeOfProjectPermission = this.checkUserPermission(['Admin', 'Associate', 'Estimator', 'Project Manager']);
      const scopeOfProjectValue = this.getAdminModuleValue('Projects');
      if (scopeOfProjectPermission && scopeOfProjectValue) {
        items.push({
          icon: 'mdi-ruler',
          title: 'PROJECTS',
          key: 13,
          link: '/projects',
          iconStyle: 'font-size: 24px;',
          groupId: 1,
        });
      }

      // remove read, read-write later
      const jobPermission = this.checkUserPermission(['Admin', 'Associate', 'Read', 'Read-Write']);
      const jobValue = this.getAdminModuleValue('Jobs');
      if (jobPermission && jobValue) {
        items.push({
          icon: 'fas fa-suitcase',
          title: 'JOBS',
          key: 2,
          link: '/jobs',
          iconStyle: 'font-size: 22px; padding-right: 2px;',
          groupId: 1,
        });
      }

      const inventoryPermission = this.checkUserPermission(['Admin', 'Associate', 'Material Handler', 'Purchaser', 'Paint Handler']);
      const inventoryValue = this.getAdminModuleValue('Inventory');
      if (inventoryPermission && inventoryValue) {
        const inventoryItem = {
          icon: 'fas fa-boxes',
          title: 'INVENTORY',
          key: 5,
          link: '/inventory',
          isOpen: true,
          subItems: [],
          iconStyle: 'font-size: 20px; padding-right: 3px;',
          groupId: 1,
        };

        const inventoryQrPermission = this.checkUserPermission(['Admin', 'Associate', 'Material Handler', 'Paint Handler']);
        const inventoryQrValue = this.getAdminModuleValue('Inventory Qr');
        if (inventoryQrPermission && inventoryQrValue) {
          inventoryItem.subItems.push({
            icon: 'fas fa-qrcode',
            title: 'INVENTORY QR',
            key: 100,
            link: '/inventory-qr',
          });
        }

        const physicalCountQrPermission = this.checkUserPermission(['Admin', 'Associate', 'Physical Count Handler']);
        const physicalCountQrValue = this.getAdminModuleValue('Physical Count Qr');
        if (physicalCountQrPermission && physicalCountQrValue) {
          inventoryItem.subItems.push({
            icon: 'fas fa-qrcode',
            iconColor: 'pink',
            title: 'PHYSICAL COUNT QR',
            key: 101,
            link: '/physical-count-qr',
          });
        }

        const rmReceivingQrPermission = this.checkUserPermission(['Admin', 'Associate', 'RM Receiving Handler', 'Paint Handler']);
        const rmReceivingQrValue = this.getAdminModuleValue('RM Receiving');
        if (rmReceivingQrPermission && rmReceivingQrValue) {
          inventoryItem.subItems.push({
            icon: 'fas fa-qrcode',
            iconColor: 'green',
            title: 'RM RECEIVING QR',
            key: 102,
            link: '/rm-receiving-qr',
          });
        }

        const movingPermission = this.checkUserPermission(['Admin', 'Associate', 'Moving Handler']);
        const movingValue = this.getAdminModuleValue('Moving');
        if (movingPermission && movingValue) {
          inventoryItem.subItems.push({
            icon: 'fas fa-qrcode',
            iconColor: 'yellow',
            title: 'MOVING QR',
            key: 103,
            link: '/moving-qr',
          });
        }

        const poPermission = this.checkUserPermission(['Admin', 'Purchaser']);
        const poValue = this.getAdminModuleValue('Inventory PO');
        if (poPermission && poValue) {
          inventoryItem.subItems.push({
            icon: 'fas fa-clipboard-check',
            title: 'INVENTORY PO',
            key: 104,
            link: '/inventory-po',
          });
        }

        items.push(inventoryItem);
      }

      const packagingSlipPermission = this.checkUserPermissionExcept(['Accountant', 'Time-Off User', 'Time-Off Supervisor', 'Time-Off Accountant', 'HR', 'HR Manager']);
      const glassValue = this.getAdminModuleValue('Glass');
      if (packagingSlipPermission && glassValue) {
        items.push({
          icon: 'mdi-checkbox-blank',
          title: 'GLASS',
          link: '/glass',
          key: 20,
          iconStyle: 'padding-right: 2px;',
          isOpen: true,
          groupId: 1,
          subItems: [
          ],
        });
        if (this.checkUserPermission(['Admin', 'Associate', 'Glass Handler'])) {
          const index = items.findIndex((item) => item.key === 20);
          if (index !== -1) {
            items[index].subItems.push(
              {
                title: 'GLASS JOBS', link: '/glass/jobs', icon: 'fas fa-wallet', key: 200,
              },
            );
            items[index].subItems.push(
              {
                title: 'PO', link: '/glass/po-items', icon: 'fas fa-clipboard-check', key: 201,
              },
            );
            items[index].subItems.push(
              {
                title: 'GLASS ITEMS', link: '/glass/glass-items', icon: 'fas fa-th', key: 202,
              },
            );
          }
        }
        if (this.checkUserPermission(['Admin', 'Associate'])) {
          const index = items.findIndex((item) => item.key === 20);
          if (index !== -1) {
            items[index].subItems.push({
              title: 'BATCHES', link: '/glass/batches', icon: 'fas fa-layer-group', key: 203,
            });
          }
        }
        const packagingSlipValue = this.getAdminModuleValue('Packaging Slip');
        const packagingSlipQrValue = this.getAdminModuleValue('Packaging Slip QR');

        if (packagingSlipValue) {
          const index = items.findIndex((item) => item.key === 20);
          if (index !== -1) {
            items[index].subItems.push({
              title: 'PACKAGING SLIP', link: '/glass/packaging-slips', icon: 'fas fa-box-open', key: 204,
            });
          }
        }

        if (packagingSlipQrValue) {
          const index = items.findIndex((item) => item.key === 20);
          if (index !== -1) {
            items[index].subItems.push({
              title: 'PACKAGING SLIP QR', link: '/glass/packaging-slip-qrs', icon: 'fas fa-qrcode', key: 205,
            });
          }
        }
      }

      const supplyReturnPermission = this.checkUserPermission(['Admin', 'Associate', 'Material Handler']);
      const supplyReturnValue = this.getAdminModuleValue('RM Supply / Return');
      if (supplyReturnPermission && supplyReturnValue) {
        items.push({
          icon: 'fas fa-truck-loading',
          title: 'STL - SUPPLY / RETURN',
          key: 3,
          link: '/consumptions',
          iconStyle: 'font-size: 18px; padding-right: 2px;',
          groupId: 2,
        });
      }

      const partPickListPermission = this.checkUserPermission(['Admin', 'Associate', 'Picker', 'Requester']);
      const partPickListValue = this.getAdminModuleValue('Part Pick List');
      if (partPickListPermission && partPickListValue) {
        items.push({
          icon: 'mdi-format-list-checks',
          title: 'PPL - PART PICK LIST',
          key: 14,
          link: '/part-pick-lists',
          groupId: 2,
        });
      }

      const supplyPaintPermission = this.checkUserPermission(['Admin', 'Associate', 'Material Handler']);
      const supplyPaintValue = this.getAdminModuleValue('Supply To Paint');
      if (supplyPaintPermission && supplyPaintValue) {
        items.push({
          icon: 'mdi-spray',
          title: 'STP - SUPPLY TO PAINT',
          key: 4,
          link: '/supply-to-paints',
          iconStyle: 'font-size: 22px; padding-left: 0px; padding-right: 3px;',
          groupId: 2,
        });
      }

      const truckSchedulePermission = this.checkUserPermission(['Admin', 'Associate', 'Approver', 'Requester', 'Shipper', 'Installer']);
      const truckScheduleValue = this.getAdminModuleValue('Truck Schedule');
      if (truckSchedulePermission && truckScheduleValue) {
        items.push({
          icon: 'mdi-truck',
          title: 'TRUCK SCHEDULE',
          key: 40,
          isOpen: true,
          iconStyle: 'font-size: 22px; padding-right: 2px; padding-left: 2px;',
          groupId: 3,
          subItems: [
            {
              title: 'REQUESTS', link: '/truck-schedules/requests', icon: 'far fa-calendar-plus', key: 400,
            },
            {
              title: 'SCHEDULE', link: '/truck-schedules/slots', icon: 'far fa-calendar-alt', key: 401,
            },
          ],
        });
      }

      const productionSchedulePermission = this.checkUserPermission(['Admin', 'Associate', 'Glass Handler', 'Production Supervisor', 'Read', 'Spandrel Paint Supervisor', 'Production Admin', 'Glass Handler']);
      const productionScheduleValue = this.getAdminModuleValue('Production Schedule');
      if (productionSchedulePermission && productionScheduleValue) {
        items.push({
          icon: 'mdi-package-down',
          title: 'PROD SCHEDULE',
          key: 50,
          isOpen: true,
          iconStyle: 'padding-right: 2px;',
          groupId: 3,
          subItems: [
            {
              title: 'CURRENT', link: '/production-schedules/1', icon: 'mdi-arrow-right-drop-circle', key: 500,
            },
            {
              title: 'ON HAND', link: '/production-schedules/3', icon: 'mdi-inbox', key: 5001,
            },
            {
              title: 'ARCHIVED', link: '/production-schedules/2', icon: 'mdi-archive', key: 5002,
            },
            {
              title: 'CALENDAR', link: '/production-schedule-calendar', icon: 'far fa-calendar-alt', key: 5003,
            },
          ],
        });
      }

      const cncFilesPermission = this.checkUserPermission(['Admin', 'Associate']);
      const cncFilesValue = this.getAdminModuleValue('CNC Files');
      if (cncFilesPermission && cncFilesValue) {
        items.push({
          icon: 'fas fa-file-import',
          title: 'CNC FILES',
          key: 11,
          link: '/cnc-files',
          iconStyle: 'font-size: 20px; padding-right: 5px;',
          groupId: 4,
        });
      }

      const cutSheetPermission = this.checkUserPermission(['Admin', 'Associate']);
      const cutSheetValue = this.getAdminModuleValue('Cut Sheets');
      if (cutSheetPermission && cutSheetValue) {
        items.push({
          icon: 'fas fa-file-alt',
          title: 'CUT SHEETS',
          key: 12,
          link: '/cut-sheets',
          iconStyle: 'font-size: 20px; padding-right: 5px; padding-left: 5px;',
          groupId: 4,
        });
      }

      const reportPermission = this.checkUserPermission(['Admin', 'Associate', 'Moving Handler', 'RM Receiving Handler', 'Physical Count Handler', 'Material Handler', 'Glass Handler']);
      const reportValue = this.getAdminModuleValue('Reports');
      if (reportPermission && reportValue) {
        items.push({
          icon: 'fas fa-file',
          title: 'REPORTS',
          key: 10,
          link: '/reports',
          iconStyle: 'font-size: 20px; padding-right: 6px; padding-left: 5px;',
          groupId: 5,
        });
      }

      const dashboardPermission = this.checkUserPermission(['Admin', 'Associate']);
      const dashboardValue = this.getAdminModuleValue('Dashboard');
      if (dashboardPermission && dashboardValue) {
        items.push({
          icon: 'mdi-chart-bar',
          title: 'DASHBOARD',
          key: 16,
          link: '/dashboards',
          iconStyle: 'font-size: 20px; padding-right: 6px; padding-left: 5px;',
          groupId: 5,
        });
      }

      const timeOffPermission = this.checkUserPermission(['Time-Off User', 'Time-Off Supervisor', 'Time-Off Accountant', 'HR']);
      const timeOffValue = this.getAdminModuleValue('Time Off');
      if (timeOffPermission && timeOffValue) {
        items.push({
          icon: 'fas fa-clock',
          title: 'TIME OFF',
          key: 17,
          link: '/time-offs',
          iconStyle: 'font-size: 20px; padding-right: 6px; padding-left: 5px;',
          groupId: 5,
        });
      }

      // remove read, read-write later
      const equipmentPermission = this.checkUserPermission(['Admin', 'Associate', 'Equipment Handler', 'Read', 'Read-Write']);
      const equipmentValue = this.getAdminModuleValue('Equipment');
      if (equipmentPermission && equipmentValue) {
        items.push({
          title: 'EQUIPMENT',
          icon: 'fas fa-hammer',
          link: '/equipment',
          key: 30,
          equipment: true,
          isOpen: true,
          subItems: [
            {
              title: 'CHECK - IN', icon: 'fas fa-arrow-right', key: 300,
            },
            {
              title: 'CHECK - OUT', icon: 'fas fa-arrow-left', key: 301,
            },
            {
              title: 'CHANGE SITE', icon: 'fas fa-random', key: 302,
            },
            {
              title: 'REQUEST REPAIR', icon: 'fas fa-tools', key: 303,
            },
            {
              title: 'REPAIR DONE', icon: 'fas fa-check', key: 304,
            },
          ],
        });
      }

      this.items = items;
    },

    openEquipmentMovementForm(type) {
      this.$bus.$emit('openEquipmentMovementForm', type);
      // this.$store.commit('openEquipmentMovementForm', type);
    },

    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },

    redirect(path) {
      this.$router.push(path);
    },

    menuActionClick(actionName) {
      if (!actionName) return;
      if (actionName === 'logout') {
        this.logout();
      }
    },

    toggleItemOpen(item) {
      const index = this.items.findIndex((x) => x.key === item.key);
      if (index === -1) return;
      this.items[index].isOpen = !this.items[index].isOpen;
      this.$set(this.items, index, this.items[index]);
    },
  },
};
</script>

<style scoped>
/* ::v-deep .navbar-grouped .theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
    opacity: 0 !important;
} */
::v-deep .navbar-grouped .v-list-group__header.v-list-item--active:not(:hover):not(:focus):before {
    opacity: 0 !important;
}
::v-deep .navbar-grouped .v-list-group__header
{
  padding: 0px;
}
::v-deep .navbar-grouped .theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::active {
    opacity: 0 !important;
}
::v-deep .navbar-grouped .v-list-group__header.v-list-item--active:not(:hover):not(:focus):active {
    opacity: 0 !important;
}

::v-deep .navbar-grouped .v-list-item--link:before {
  background-color: transparent !important;
}
.navbar-grouped-item {
  width: 100%;
}

.navbar-grouped-item >>> div {
  padding: 0;
}

.navbar-item-icon {
  width: 1.5rem;
}

.navbar-subitem {
  margin-left: 2rem;
  max-height: 10px !important;
}

.logo-size {
  max-width: 30px;
}

::v-deep .sub-item-selected{
  background-color: rgba(215, 215, 215, 0.2) !important;
}

::v-deep .v-list-item--dense .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon {
    width: 10px !important;
}

::v-deep .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 0px !important;
}

::v-deep .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 20px !important;
    max-width: 20px !important;
}

::v-deep .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child
{
    margin-right: 10px !important;
    margin-top: 2px !important;
}

.gradient-text {
  background-image: linear-gradient(60deg, #a100d6, #fc9b0e, #51bafa #39ffc8);
  background-clip: text;
  color: transparent;
}

.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 30px;
}

.v-list-item--dense .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon {
    height: 24px;
    margin-top: 6px;
    margin-bottom: 6px;
}
</style>
