<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="1"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobCodes"
          hide-details="true"
          item-value="code"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :single-select="false"
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterPo"
          label="PO#"
          :items="poNumbers"
          hide-details="true"
          clearable
          :loading="laodingPo"
        />
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterBatchId"
          label="Batch"
          item-value="id"
          item-text="batch_number"
          :items="batches"
          hide-details
          clearable
          :loading="loadingBatch"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterStatusId"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-menu
          ref="menuFrom"
          v-model="dateMenuFrom"
          :return-value.sync="filterDateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDateFrom"
              label="Produced From"
              readonly
              v-bind="attrs"
              v-on="on"
              @change="$refs.menuFrom.save(filterDateFrom)"
            />
          </template>
          <v-date-picker
            v-model="filterDateFrom"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuFrom = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDateFrom = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-menu
          ref="menuTo"
          v-model="dateMenuTo"
          :return-value.sync="filterDateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDateTo"
              label="Produced To"
              readonly
              v-bind="attrs"
              v-on="on"
              @change="$refs.menuTo.save(filterDateTo)"
            />
          </template>
          <v-date-picker
            v-model="filterDateTo"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuTo = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDateTo = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row>
          <v-col class="pr-2 pt-8">
            <span :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}">Decimal</span>
          </v-col>
          <v-col class="pl-0">
            <v-switch
              v-model="uomSwitch"
              hide-details
              class="pt-5"
              label="Fraction"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row>
          <v-col class="pr-2 pt-8">
            <span :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}">Assigned</span>
          </v-col>
          <v-col class="pl-0">
            <v-switch
              v-model="switchAssigned"
              hide-details
              class="pt-5"
              label="Unassigned"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row>
          <v-col class="pr-2 pt-8">
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <span
                  :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}"
                  v-on="on"
                >Latest Records</span>
              </template>
              <span>Records from the last 3 months</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="auto"
            class="pl-0"
          >
            <v-switch
              v-model="switchAll"
              hide-details
              class="pt-5"
              label="All Records"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="pb-0 pt-0 pr-0"
      >
        <v-tooltip
          top
          color="black"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                color="success"
                class="mt-3"
                outlined
                :disabled="!inventoryClassId && !filterJob && !filterPo && !filterBatchId && !filterStatusId"

                @click="generateExcel()"
              >
                CSV
              </v-btn>
            </div>
          </template>
          <span>Make sure to select at least one filter is selected</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pt-0 pr-0"
      >
        <v-tooltip
          top
          color="black"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                color="success"
                class="mt-3"
                outlined
                :disabled="!inventoryClassId && !filterJob && !filterPo && !filterBatchId && !filterStatusId"

                @click="runWebhookAll()"
              >
                PDF
              </v-btn>
            </div>
          </template>
          <span>Make sure to select at least one filter is selected</span>
        </v-tooltip>
      </v-col>
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate)) || roles.includes(userRoleIdGlassHandler)"
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-btn
          color="primary"
          class="mt-3"
          outlined
          :disabled="selectedGlass.length === 0"
          @click="openNewBatch(true)"
        >
          New Remake PO
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="glassHeaders"
            :items="glassItems"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            :show-select="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate)"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @update:options="updateOptions"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #item.data-table-select="{ item }">
              <v-checkbox
                :key="item.id"
                v-model="selectedGlass"
                :value="item"
                hide-details
              />
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #header.data-table-select>
              <v-checkbox
                v-model="allSelect"
                hide-details
                @click="selectAll()"
              />
            </template>

            <!-- <template #header.data-table-select /> -->
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.job`]="{ item }">
              <span>
                <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
              </span>
            </template>
            <template #[`item.glass_thickness`]="{ item }">
              <text-highlight :queries="search">
                {{ item.glass_thickness ? item.glass_thickness : '-' }}
              </text-highlight>
            </template>
            <template #[`item.po_number`]="{ item }">
              <span class="font-weight-bold">
                <text-highlight :queries="search">
                  {{ item.po_number ? item.po_number : '' }}
                </text-highlight>
              </span>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <span
                class="font-weight-bold"
              >
                <text-highlight
                  style="font-size: 14px !important;"
                  :queries="search"
                >

                  {{ item.batch_number ? item.batch_number : '' }}
                </text-highlight></span>
            </template>
            <template #[`item.job_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.job_code ? item.job_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.g_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.g_code ? item.g_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.packaging_slip`]="{ item }">
              <text-highlight :queries="search">
                {{ item.packaging_slip ? item.packaging_slip : '-' }}
              </text-highlight>
            </template>
            <template #[`item.tag_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.tag_number ? item.tag_number : '-' }}
              </text-highlight>
            </template>
            <template #[`item.width`]="{ item }">
              <text-highlight :queries="search">
                {{ item.width ? (uomSwitch ? convertToFraction(item.width) : item.width) : '-' }}
              </text-highlight>
            </template>
            <template #[`item.height`]="{ item }">
              <text-highlight :queries="search">
                {{ item.height ? (uomSwitch ? convertToFraction(item.height) : item.height) : '-' }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              <text-highlight :queries="search">
                {{ item.quantity ? item.quantity : '-' }}
              </text-highlight>
            </template>
            <template #[`item.quality_check`]="{ item }">
              <div
                v-if="item.rejections && item.rejections.length > 0"
                class="d-flex justify-center align-center pl-3 pr-3 pt-1 pb-1 ga-2 mt-1 mb-1"
                :style="`display: inline-block; border: 1px solid #E4E4E7; border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; width: ${item.rejections.length * 50}px;`"
              >
                <div
                  v-for="rejection, index in item.rejections"
                  :key="rejection.id"
                  :style="`${item.rejections.length > 1 && item.rejections.length - 1 > index ? 'border-right: 2px solid #E4E4E7; padding-right: 6px;' : ''}; `"
                >
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <div
                        class="d-flex align-center ga-1"
                        style="display:inline-block;"
                        v-on="on"
                      >
                        <h4
                          v-if="rejection.type_id === typeIdSingleGlass"
                        >
                          S
                        </h4>
                        <h4
                          v-if="rejection.type_id === typeIdExteriorGlass"
                        >
                          E
                        </h4>
                        <h4
                          v-if="rejection.type_id === typeIdCentralGlass"
                        >
                          C
                        </h4>
                        <h4
                          v-if="rejection.type_id === typeIdInteriorGlass"
                        >
                          I
                        </h4>
                        <v-icon
                          v-if="rejection.is_qualified === 1"
                          style="font-size: 20px; color: #62CE6D;"
                        >
                          mdi-check
                        </v-icon>
                        <v-icon
                          v-else
                          style="font-size: 20px; color: #E23F45;"
                        >
                          mdi-close
                        </v-icon>
                      </div>
                    </template>
                    <div
                      v-if="rejection.rejected_at"
                      class="d-flex align-center ga-1"
                    >
                      <h4 v-if="rejection.type_id === typeIdSingleGlass">
                        Single,
                      </h4>
                      <h4 v-if="rejection.type_id === typeIdExteriorGlass">
                        Exterior,
                      </h4>
                      <h4 v-if="rejection.type_id === typeIdCentralGlass">
                        Central,
                      </h4>
                      <h4 v-if="rejection.type_id === typeIdInteriorGlass">
                        Interior,
                      </h4>
                      <h4>
                        QA Rejected at: {{ rejection.rejected_at }}
                      </h4>
                    </div>
                    <div
                      v-if="rejection.qualified_at && rejection.is_qualified === 1"
                      class="d-flex align-center ga-1"
                    >
                      <h4 v-if="rejection.type_id === typeIdSingleGlass">
                        Single,
                      </h4>
                      <h4 v-if="rejection.type_id === typeIdExteriorGlass">
                        Exterior,
                      </h4>
                      <h4 v-if="rejection.type_id === typeIdCentralGlass">
                        Central,
                      </h4>
                      <h4 v-if="rejection.type_id === typeIdInteriorGlass">
                        Interior,
                      </h4>
                      <h4
                        v-if="rejection.is_qualified === 1"
                      >
                        QA Approved at: {{ rejection.qualified_at }}
                      </h4>
                    </div>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template #[`item.status`]="{ item }">
              <div class="d-flex justify-center">
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on }">
                    <span
                      :style="{ color: item.colour, width: '100%', maxWidth: '140px' }"
                      v-on="on"
                    ><v-chip
                      small
                      style="width: 100%; max-width: 140px; white-space: nowrap; "
                      :color="item && item.status ? item.status.color : 'black'"
                      text-color="white"
                    >
                      {{ item && item.status ? item.status.name : '' }}
                    </v-chip></span>
                  </template>
                  <span
                    v-if="item.status_id === glassStatusIdRemake"
                    style="font-size: 20px; font-weight: 600"
                  >{{ item && item.remake_reason ? item.remake_reason.name : '' }} - {{ item && item.remake_sub_reason ? item.remake_sub_reason.name : '' }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.item_index`]="{ item }">
              <text-highlight :queries="search">
                {{ item.item_index ? item.item_index : '-' }}
              </text-highlight>
            </template>
            <template #[`item.updated_at`]="{ item }">
              <v-tooltip
                bottom
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    @mouseover="getGlassHistory(item)"
                    v-on="on"
                  >{{ item ? convertDateFormat(item.updated_at) : '' }}</span>
                </template>

                <div
                  style="width: 400px;"
                  class="pt-3"
                >
                  <p
                    v-for="history in glassHistories"
                    :key="history.id"
                  >
                    <span v-if="history.status_id == glassStatusIdCutting">{{ `Cut At: ${history.created_at} - Cut By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusidTempering">{{ `Tempered At: ${history.created_at} - Tempered By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdProduced">{{ `Produced At: ${history.created_at} - Produced By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdShipped">{{ `Shipped At: ${history.created_at} - Shipped By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdReceived">{{ `Received At: ${history.created_at} - Received By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdCanceled">{{ `Canceled At: ${history.created_at} - Canceled By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdRemake">{{ `Remake At: ${history.created_at} - Remake By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdRejected">{{ `Rejected At: ${history.created_at} - Rejected By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdShipedToSite">{{ `Shipped To Site At: ${history.created_at} - Shipped To Site By: ${history.user_full_name}` }}</span>
                    <span v-if="history.status_id == glassStatusIdReceivedOnSite">{{ `Received On Site At: ${history.created_at} - Received On Site By: ${history.user_full_name}` }}</span>
                  </p>
                </div>
              </v-tooltip>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #footer>
              <v-row v-if="glassItems && glassItems.length > 0">
                <v-col class="text-end mr-6">
                  <span style="font-weight: bold; font-size: 15px !important;"> Total Quantity: {{ glassItems.reduce((total, item) => total + parseInt(item.quantity, 10), 0) }}</span>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-batch-insert-form
      ref="batchInsertForm"
      @success="success"
    />
    <glass-batch-manual-form
      ref="batchManualForm"
      @success="success"
    />

    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import GlassBatchInsertForm from '@/components/forms/glasses/GlassBatchInsertForm';
import GlassBatchManualForm from '@/components/forms/glasses/GlassBatchManualForm';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import ExcelHelper from '@/helpers/ExcelHelper';

export default {
  components: {
    GlassBatchManualForm,
    GlassBatchInsertForm,
  },
  props: {
    jobCode: {
      type: Number,
      default: 0,
    },
    poNumber: {
      type: String,
      default: null,
    },
    batchId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    glassItems: [],
    poNumbers: [],
    jobCodes: [],
    selectedGlass: [],
    batches: [],
    glassHeaders: [
      {
        text: 'Class', value: 'inventory_class', align: 'center', width: '5%',
      },
      { text: 'Job', value: 'job', width: '15%' },
      { text: 'PO#', value: 'po_number', width: '6%' },
      { text: 'Batch', value: 'batch_number', width: '6%' },
      { text: 'G Code', value: 'g_code', width: '6%' },
      { text: 'Pack. Slip#', value: 'packaging_slip', width: '6%' },
      { text: 'Glass Thickness', value: 'glass_thickness', width: '8%' },
      { text: 'Tag#', value: 'tag_number', width: '5%' },
      { text: 'Width', value: 'width', width: '3%' },
      { text: 'Height', value: 'height', width: '3%' },
      { text: 'Qty', value: 'quantity', width: '4%' },
      { text: 'Quality Check (QA)', value: 'quality_check', width: '10%' },
      {
        text: 'Status', value: 'status', width: '8%', align: 'center',
      },
      { text: 'Line#', value: 'item_index', width: '4%' },
      {
        text: 'Updated At', value: 'updated_at', width: '19%', align: 'end',
      },
    ],

    footer: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    roles: [],
    types: [],
    loading: false,
    jobLoading: false,
    inventoryClassId: null,
    filterJob: 0,
    filterPo: 0,
    uomSwitch: true,
    filterBatchId: 0,
    allSelect: false,
    glassBatchStatusIdNew: 1,
    glassStatusIdNew: 1,
    glassStatusIdProduced: 3,
    glassStatusIdCanceled: 4,
    glassStatusIdRemake: 5,
    glassStatusIdShipped: 6,
    glassStatusIdReceived: 7,
    glassStatusIdCutting: 10,
    glassStatusidTempering: 11,
    glassStatusIdShipedToSite: 12,
    glassStatusIdReceivedOnSite: 13,
    glassStatusIdRejected: 16,
    switchAssigned: false,
    switchAll: false,
    topSpaceHeight: 305,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 100,
      page: 1,
      sortBy: 'po_number',
      sortDesc: true,
    },
    total: 0,
    inventoryClassIdVwdGlass: 7,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    dateMenuFrom: false,
    filterDateFrom: null,
    filterStatusId: null,
    dateMenuTo: false,
    filterDateTo: null,
    statuses: [],
    loadingJobs: false,
    laodingPo: false,
    loadingBatch: false,
    selectStatuses: [],
    glassHistories: [],
    typeIdSingleGlass: 1,
    typeIdExteriorGlass: 2,
    typeIdCentralGlass: 3,
    typeIdInteriorGlass: 4,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
      glassItemsState: (state) => state.glassItemsState,
    }),
  },
  watch: {
    inventoryClassId() {
      this.selectedGlass = [];
      this.getGlass();
    },
    filterJob(value) {
      this.storeState('job_code', value);
      this.getGlass();
    },
    filterPo(value) {
      this.storeState('po_number', value);
      this.getGlass();
    },
    filterBatchId(value) {
      this.storeState('batch_id', value);
      this.getGlass();
    },
    switchAssigned() {
      this.getGlass();
    },
    switchAll() {
      this.getGlass();
    },
    pagination() {
      // const state = this.glassItemsState;
      // state.pagination = value;
      // this.$store.commit('storeGlassItemsState', state);
      this.getGlass();
    },
    search() {
      this.getGlass();
    },
    filterDateFrom(value) {
      this.$refs.menuFrom.save(value);
      this.storeState('date_from', value);
      this.getGlass();
    },
    filterDateTo(value) {
      this.$refs.menuTo.save(value);
      this.storeState('date_to', value);
      this.getGlass();
    },
    filterStatusId(value) {
      this.storeState('status_id', value);
      this.getGlass();
    },
  },
  created() {
    if (this.jobCode) this.filterJob = this.jobCode;
    if (this.poNumber) this.filterPo = this.poNumber;
    if (this.batchId) this.filterBatchId = this.batchId;
    if (!this.jobCode && !this.poNumber && !this.batchId) {
      if (this.glassItemsState) {
        if (this.glassItemsState.pagination) { this.pagination = this.glassItemsState.pagination; }
        if (this.glassItemsState.job_code) { this.filterJob = this.glassItemsState.job_code; }
        if (this.glassItemsState.po_number) { this.filterPo = this.glassItemsState.po_number; }
        if (this.glassItemsState.batch_id) { this.filterBatchId = this.glassItemsState.batch_id; }
        if (this.glassItemsState.date_from) { this.filterDateFrom = this.glassItemsState.date_from; }
        if (this.glassItemsState.date_to) { this.filterDateTo = this.glassItemsState.date_to; }
        if (this.glassItemsState.status_id !== null) { this.filterStatusId = this.glassItemsState.status_id; }
      }
    }
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.getPoItems();
      this.getJobs();
      this.getBatches();
      this.getStatuses();
    },

    storeState(property, value) {
      const state = this.glassItemsState;
      state[property] = value;
      this.$store.commit('storeGlassItemsState', state);
    },

    async getGlass() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.glassItems = [];
      this.total = 0;
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          job_code: this.filterJob,
          po_number: this.filterPo,
          batch_id: this.filterBatchId,
          is_unassigned: this.switchAssigned ? 1 : 0,
          date_from: this.filterDateFrom,
          date_to: this.filterDateTo,
          status_id: this.filterStatusId,
          is_all: this.switchAll ? 1 : 0,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.glass.readGlass(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.init();
      if (!res || !res.data || res.data.length === 0) {
        this.glassItems = [];
        this.total = 0;
        return;
      }
      this.glassItems = res.data;
      this.total = res.total;
    },

    async getPoItems() {
      this.laodingPo = true;
      const res = await this.$api.glass.get(`po-numbers/${this.inventoryClassId}/${null}`);
      if (!res) {
        this.poNumbers = [];
        this.laodingPo = false;
        return;
      }
      this.poNumbers = res;
      this.laodingPo = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId, is_manual: 0 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobCodes = [];
        this.loadingJobs = false;
        return;
      }
      this.jobCodes = res;
      this.loadingJobs = false;
    },

    async getBatches() {
      this.loadingBatch = true;
      this.batches = await this.$api.glassBatch.get('batch-numbers');
      this.loadingBatch = false;
    },

    async getStatuses() {
      this.statuses = await this.$api.glassPoStatus.get();
      const statusIdNew = 1;
      const statusIdProduced = 3;
      this.selectStatuses = this.statuses.filter((x) => x.id === statusIdNew || x.id === statusIdProduced);
    },

    async getGlassHistory(item) {
      this.loadingHistory = true;

      const submitModel = {
        filters: { id: item.id },
      };
      const res = await this.$api.glassHistory.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return '';
      if (!res) {
        this.glassHistories = [];
        this.loadingJobs = false;
        return '';
      }
      this.glassHistories = res;
      this.loadingHistory = false;
      return '';
    },

    async startSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to do a Data Sync? This action will bring new Glass Items into ERP and can take up to 2 minutes!',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.glass.get('sync');
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getGlass();
      this.getPoItems();
      this.getJobs();
    },

    async removeGlassItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record from Batch?',
      });
      if (!confirm) return;
      const res = await this.$api.glass.get(`remove/${item.id}/${item.batch_id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getGlass();
      this.getBatches();
    },

    openNewBatch(isRemake = false) {
      if (this.selectedGlass.length === 0) return;
      const firstRecord = this.selectedGlass[0];
      const selectedIds = this.selectedGlass.map((x) => x.id);
      this.$refs.batchManualForm.open(selectedIds, firstRecord.inventory_class_id, isRemake);
    },

    async changeStatus(item) {
      const res = await this.$api.glass.get(`status/${item.id}/${item.status_id}/1`);
      if (!res) return;
      const index = this.glassItems.findIndex((x) => x.id === item.id);
      const status = this.selectStatuses.find((x) => x.id === item.status_id);
      this.glassItems[index].status = status;
      this.glassItems[index].status_id = item.status_id;
      this.glassItems[index].updated_at = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      this.$set(this.glassItems, index, this.glassItems[index]);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.getGlass();
      this.getPoItems();
      this.getJobs();
      this.getBatches();
      this.selectedGlass = [];
      this.allSelect = false;
    },

    viewBatch(item) {
      this.$router.push({
        name: 'GlassBatchView',
        params: {
          poNumber: item.po_number,
        },
      });
    },

    selectAll() {
      if (this.allSelect) this.selectedGlass = this.glassItems;
      // if (this.allSelect) this.selectedGlass = JSON.parse(JSON.stringify(this.glassItems)); need to use this one if we need to create a new array
      else this.selectedGlass = [];
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
      this.storeState('pagination', newOptions);
    },

    convertToFraction(decimal) {
      if (decimal % 1 === 0) {
        return decimal;
      }
      const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
      };

      // If the decimal is an exact match to any fraction, return the fraction directly
      if (decimal in fractions) {
        return fractions[decimal];
      }

      const wholeNumber = Math.floor(decimal);
      // eslint-disable-next-line no-param-reassign
      decimal -= wholeNumber; // Remove the whole number part

      let closestFraction = Object.keys(fractions)[0];

      // eslint-disable-next-line no-restricted-syntax
      for (const fraction of Object.keys(fractions)) {
        if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
          closestFraction = fraction;
        }
      }

      if (wholeNumber > 0) {
        return `${wholeNumber} - ${fractions[closestFraction]}"`;
      }
      return fractions[closestFraction];
    },

    convertDateFormat(inputDate) {
      // Parse the input date
      const date = new Date(inputDate);

      // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      return formattedDate;
    },

    async runWebhookAll(type = 'pdf') {
      this.$bus.$emit('loading');
      const webhookModuleIdGlassItems = 44;
      const webhookModuleIdGlassItemsCsv = 46;
      // const moduleId = type === 'pdf' ? webhookModuleIdGlassItems : webhookModuleIdGlassItemsCsv;
      const ids = this.selectedGlass.map((x) => x.id);
      const idsString = ids.join(',');
      let batchNumber = '';
      if (this.filterBatchId) {
        const batch = this.batches.find((x) => x.id === this.filterBatchId);
        batchNumber = batch ? batch.batch_number : '';
      }
      const webhookData = {
        module_id: webhookModuleIdGlassItems,
        inventory_class_id: this.inventoryClassId ? this.inventoryClassId : 0,
        job_id: this.filterJob ? this.filterJob : '',
        po_number: this.filterPo ? this.filterPo : '',
        batch_id: batchNumber,
        status_id: this.filterStatusId ? this.filterStatusId : 0,
        type,
        ids: idsString,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Glass_Items_${now}`;

      if (type === 'pdf') {
        const file = new Blob([res], {
          type: 'application/pdf',
        });
        saveAs(file, fileName);
      } else if (type === 'xls') {
        const file = new Blob([res], {
          type: 'application/vnd.ms-excel',
        });
        saveAs(file, fileName);
      }
      this.$bus.$emit('loading');
    },

    generateExcel() {
      const excelData = this.glassItems.map((item) => ({
        Class: item && item.class ? item.class.name : '',
        Job: `${item.job_code ? item.job_code : ''} - ${item.job_name ? item.job_name : ''}; ${item.job_reference ? item.job_reference : ''}`,
        'PO#': item.po_number ? item.po_number : '',
        Batch: item.batch_number ? item.batch_number : '',
        'G Code': item.g_code ? item.g_code : '-',
        'Pack. Slip#': item.packaging_slip ? item.packaging_slip : '',
        'Glass Thickness': item.glass_thickness ? item.glass_thickness : '',
        'Tag#': item.tag_number ? item.tag_number : '',
        Width: item.width ? (this.uomSwitch ? this.convertToFraction(item.width) : item.width) : '',
        Height: item.height ? (this.uomSwitch ? this.convertToFraction(item.height) : item.height) : '',
        Qty: item.quantity ? item.quantity : '',
        Status: item.status ? item.status.name : '',
        'Line#': item.item_index ? item.item_index : '',
        'Updated At': item ? this.convertDateFormat(item.updated_at) : '',
      }));
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Glass Items');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Glass_Items_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },

  },
};
</script>

<style scoped>
span
{
  font-size: 11px !important;
}
.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}
::v-deep .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
    color: rgba(0, 0, 0, 0.1) !important;
}
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 20px;
}
::v-deep .fa-caret-down:before {
    font-size: 14px;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 0 3px 0;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-left: 0px !important;
}
::v-deep .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner {
    width: 15px;
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding-left: 10px;
    padding-right: 10px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
    width: 12px;
}
::v-deep .select-status .theme--light.v-icon {
    color: white;
    padding-right: 6px !important;
}
</style>
