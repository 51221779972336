/* eslint-disable no-unused-expressions */
<template>
  <v-container fluid>
    <v-container
      v-if="inventoryTag != null"
      style="font-size: 1.3em !important;"
      fluid
    >
      <v-row
        class="pa-0"
      >
        <v-col
          class="pa-0"
          cols="1"
          md="1"
          sm="2"
          style="text-align:right"
        >
          <v-row>
            <v-col class="text-center">
              <h5 class="h5">
                QR Code
              </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-chip
                class="mt-1 mb-1"
                text-color="white"
                style="cursor: pointer;"
                :color="inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.color : 'white'"
              >
                <span style="font-size: 20px;">{{ inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.name : '-' }}</span>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-3 mb-5 text-center">
              <h6 class="mb-3">
                Supplier Label
              </h6>
              <a

                @click="openItemShowImageForm(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
              >
                <v-img
                  :src="convertedPhoto(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
                  class="profile-photo-mini ml-3"
                />
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="`pl-0 pt-0 pb-0 ${inventoryTag.item.photo && inventoryTag.item.mime_type ? '' : 'pr-6'}`"
          cols="auto"
          style=""
        >
          <div
            style="border-left: 1px solid; height: 90%; margin-left:30px;"
          />
        </v-col>
        <v-col
          v-if="inventoryTag.item.photo && inventoryTag.item.mime_type"
          class="pa-0 pr-3 pb-3"
          cols="auto"
          md="3"
          sm="8"
        >
          <v-img
            :src="convertedPhoto(inventoryTag.item.photo, inventoryTag.item.mime_type)"
            contain
            height="100%"
            width="100%"
            class="profile-photo"
          />
        </v-col>
        <v-col
          class="pa-0"
        >
          <v-row>
            <v-col
              cols="5"
              style="text-align:center"
            >
              <h5 class="h5">
                Item Name
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.item.full_name : '' }}</span>
            </v-col>
            <v-col
              v-if="!isUpc && inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass && inventoryTag.item.classes.id !== inventoryClassIdSID && inventoryTag.item.classes.id !== inventoryClassIdPatioDoor && inventoryTag.item.classes.id !== inventoryClassIdEquipment)"
              cols="3"
              style="text-align:center"
            >
              <h5 class="h5">
                Colour
              </h5>
              <span class="value"> {{ inventoryTag && inventoryTag.color ? inventoryTag.color.code : '' }}</span>
            </v-col>
            <v-col
              v-if="!isUpc && inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass && inventoryTag.item.classes.id !== inventoryClassIdSID && inventoryTag.item.classes.id !== inventoryClassIdPanels && inventoryTag.item.classes.id !== inventoryClassIdPatioDoor && inventoryTag.item.classes.id !== inventoryClassIdEquipment)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Length
              </h5>
              <span class="value"> {{ inventoryTag && inventoryTag.length ? inventoryTag.length.name : '' }}</span>
            </v-col>
            <v-col
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                UOM
              </h5>
              <span class="value"> {{ inventoryTag != null ? inventoryTag.uom.name : '' }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag != null && inventoryTag.door_size"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Size
              </h5>
              <span class="value"> {{ inventoryTag.door_size.name }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag != null && inventoryTag.door_hinge"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Hinge
              </h5>
              <span class="value"> {{ inventoryTag.door_hinge.name }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag != null && inventoryTag.door_swing"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Swing
              </h5>
              <span class="value"> {{ inventoryTag.door_swing.name }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag != null && inventoryTag.door_lite"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Lite
              </h5>
              <span class="value"> {{ inventoryTag.door_lite.name }}</span>
            </v-col>
            <v-col
              v-if="!isUpc"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                PO#
              </h5>
              <span class="value"> {{ inventoryTag ? inventoryTag.po_number : '-' }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.is_painted != null"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Paint
              </h5>
              <span class="value"> {{ inventoryTag.is_painted == 1 ? 'Painted' : 'Non-Painted' }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.height && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Door Height
              </h5>
              <span class="value"> {{ inventoryTag.height }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.width && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Door Width
              </h5>
              <span class="value"> {{ inventoryTag.width }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.door_screen && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Screen
              </h5>
              <span class="value"> {{ inventoryTag.door_screen.name }}</span>
            </v-col>
            <!-- <v-col
              v-if="inventoryTag && inventoryTag.glass && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Glass
              </h5>
              <span class="value"> {{ inventoryTag.glass }}</span>
            </v-col> -->
            <v-col
              v-if="inventoryTag && inventoryTag.is_fritted != null && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Fritted
              </h5>
              <span class="value"> {{ inventoryTag.is_fritted == 1 ? 'Fritted' : 'Non-Fritted' }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.color && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdSID || inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Int Color
              </h5>
              <span class="value"> {{ inventoryTag.color.code }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.ex_color && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdSID || inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Ext Color
              </h5>
              <span class="value"> {{ inventoryTag.ex_color.code }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
              cols="2"
              class="text-center"
            >
              <h5 class="h5">
                Vendor Batch#
              </h5>
              <span>{{ inventoryTag ? inventoryTag.batch_number : '' }}</span>
            </v-col>
            <v-col
              v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
              cols="2"
              class="text-center"
            >
              <h5 class="h5">
                Production Date
              </h5>
              <span>{{ inventoryTag ? inventoryTag.production_date_simple : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Received Qty
              </h5>
              <span class="value"> {{ inventoryTag != null ? inventoryTag.quantity : '' }}</span>
            </v-col>
            <v-col
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Qty on Hand
              </h5> <span class="value"> {{ inventoryTag != null ? (inventoryTag.quantity_on_hand ? inventoryTag.quantity_on_hand : 0) : '' }}</span>
            </v-col>
            <v-col
              cols="2"
              style="text-align:center"
            >
              <h5 class="h5">
                Qty Available
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.quantity_available : 0 }}</span>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                color="orange"
                class="mr-2"
                outlined
                x-large
                @click="openNotes"
              >
                Notes
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                class="mr-4"
                x-large
                @click="historyRedirect"
              >
                History
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-row>
            <v-col
              class="mt-3"
            >
              <h3>Moving from: </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0">
              <span class="font-weight-bold">
                Location
              </span>
              <span class="value"> {{ inventoryTag != null ? inventoryTag.location.name : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <span class="font-weight-bold">
                Shelf
              </span>
              <span class="value"> {{ inventoryTag != null ? inventoryTag.inventory_location.name : '' }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="mt-3">
              <h3>Moving to: </h3>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row class="mt-0">
              <v-col>
                <v-row>
                  <v-col
                    cols="3"
                    md="3"
                    sm="6"
                    class="pb-0"
                  >
                    <h5 class="h5">
                      Location<RedAsterisk />
                    </h5>
                    <v-autocomplete
                      v-model="location"
                      :items="locationsList"
                      item-text="name"
                      return-object
                      append-icon=""
                      outlined
                      single-line
                      :disabled="scannedInventoryTag != null"
                      :rules="[(v) => !!v || 'Location is required']"
                    >
                      <template slot="label">
                        Location
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="3"
                    md="3"
                    sm="6"
                    class="pb-0"
                  >
                    <h5 class="h5">
                      Shelf<RedAsterisk />
                    </h5>
                    <v-autocomplete
                      v-model="inventoryLocation"
                      :items="filteredInventoryLocations"
                      item-text="name"
                      return-object
                      append-icon=""
                      outlined
                      single-line
                      :disabled="scannedInventoryTag != null"
                      :rules="[(v) => !!v || 'Inventory Location is required']"
                    >
                      <template slot="label">
                        Inventory Location
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="3"
                    md="3"
                    sm="6"
                    class="pb-0"
                  >
                    <h5 class="h5">
                      Moving Quantity
                    </h5>
                    <v-text-field
                      v-model="quantity"
                      :rules="[(v) => !!v || 'Moving Quantity is required', (v) => /^\d+$/.test(v) || 'Please enter a whole number']"
                      required
                      outlined
                      single-line
                      type="number"
                      step="1"
                      min="0"
                      readonly
                      disabled
                    >
                      <template #label>
                        Moving Quantity
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span
                      v-if="scannedQrCode"
                    ><span class="font-weight-bold">IMPORTANT!</span> QR Code <span class="font-weight-bold">{{ scannedQrCode }} </span>will be used for Inventory Movement</span>
                    <span
                      v-else
                      class="red--text"
                    >{{ errorMessage }}</span>
                  </v-col>
                </v-row>
                <!-- might need scan qr btn in the future -->
                <!-- <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                  >
                    <v-btn
                      :disabled="!isScanQrMoveActive"
                      color="primary"
                      x-large
                      class="mr-4"
                      @click="scanQrCode"
                    >
                      scan qr
                    </v-btn>
                    <v-btn
                      :disabled="!isSubmitBtnActive"
                      color="primary"
                      x-large
                      class="mr-4"
                      @click="submit"
                    >
                      submit
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mr-4"
                      outlined
                      x-large
                      @click="redirectBack"
                    >
                      cancel
                    </v-btn>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn
            :disabled="!isSubmitBtnActive"
            color="primary"
            x-large
            class="mr-4"
            @click="submit"
          >
            submit
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            class="mr-4"
            outlined
            x-large
            @click="redirectBack"
          >
            cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <h2 v-if="isActivatedVisible">
      <v-row>
        <v-col class="text-center">
          <v-row>
            <v-col>
              {{ topErrorMessage }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                class="mr-4"
                outlined
                x-large
                @click="redirectBack"
              >
                scan new qr code
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </h2>

    <v-dialog
      v-model="qrCodeDialog"
      fullscreen
      persistent
      hide-overlay
    >
      <v-card>
        <v-card-title>
          <v-col class="text-right">
            <v-btn
              icon
              @click="qrCodeDialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-container fluid>
          <inventory-qr
            :is-rm-supply="true"
            :is-moving-qr-partially="true"
            @return-qr-code-move="returnQrCode"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
    <inventory-tag-note-form
      ref="noteForm"
    />
  </v-container>
</template>

<script>
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import InventoryTagNoteForm from '@/components/forms/inventoryTags/InventoryTagNoteForm';
import { mapState } from 'vuex';

export default {
  components: {
    InventoryItemShowImageForm,
    InventoryQr,
    InventoryTagNoteForm,
  },
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    quantity: 0,
    photo: null,
    mime_type: null,
    inventoryLocation: null,
    location: null,
    inventoryItem: null,
    inventoryLocations: [],
    locationsList: [],
    filteredInventoryLocations: [],
    scannedInventoryTag: null,
    scannedQrCode: null,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdEquipment: 13,
    inventoryClassIdPatioDoor: 14,
    onTruckLocationId: 9,
    errorMessage: '',
    isSubmitBtnActive: true,
    isScanQrMoveActive: true,
    isActivatedVisible: false,
    qrCodeDialog: false,
    isCreateNewInventoryTag: false,
    isMovingAll: 0,
    isUpc: false,
    inventoryIdSlab: 1100,
    inventoryIdSweep: 1101,
    inventoryIdInsert: 1102,
    topErrorMessage: 'THIS QR CODE DOES NOT EXIST IN THE SYSTEM. PLEASE SCAN A VALID QR CODE.',
  }),
  computed: {
    ...mapState({
      user: 'user',
    }),
  },
  watch: {
    location(value) {
      if (value) {
        this.inventoryLocation = null;
        const locationId = value.id;
        this.filteredInventoryLocations = this.inventoryLocations.filter((x) => x.location_id === locationId);
      }
    },
    quantity(value) {
      const qtyOnHand = parseInt(this.inventoryTag.quantity_on_hand, 10);
      const quantity = parseInt(value, 10);

      this.isSubmitBtnActive = true;
      this.isMovingAll = 1;
      this.errorMessage = '';
      this.isScanQrMoveActive = true;
      this.scannedQrCode = null;
      this.scannedInventoryTag = null;
      this.isCreateNewInventoryTag = false;
      if (qtyOnHand < 0) {
        this.errorMessage = 'ERROR! Moving Quantity On Hand must be greater than 0';
        this.isSubmitBtnActive = false;
      } else if (quantity < 0) {
        this.errorMessage = 'ERROR! Moving Quantity must be greater than 0';
        this.isSubmitBtnActive = false;
      // eslint-disable-next-line eqeqeq
      } else if (quantity == 0) {
        this.errorMessage = 'ERROR! Moving Quantity must be greater than 0';
        this.isSubmitBtnActive = false;
      } else if (quantity > qtyOnHand) {
        this.errorMessage = 'ERROR! Moving Quantity cannot be greater than Quantity On Hand';
        this.isSubmitBtnActive = false;

      // If moving partially, then scan a qr code
      } else if (quantity < qtyOnHand) {
        this.errorMessage = 'IMPORTANT! You\'re moving an incomplete bundle. Please SCAN QR using button below to activate new code.';
        this.isScanQrMoveActive = true;
        this.isSubmitBtnActive = false;
      }
    },
  },
  created() {
    this.qrCode = this.$route.params.code;
    this.qrCode = this.$route.params.code;
    if (parseInt(this.qrCode, 10) > 99999 && parseInt(this.qrCode, 10) < 199999) {
      this.isUpc = true;
    } else {
      this.isUpc = false;
    }
    this.init();
  },

  methods: {
    async init() {
      await this.getLocations();
      await this.getInventoryLocations();
      this.getInventoryTag();
    },

    // QR CODE IS THE SAME AS INVENTORY TAG
    async getInventoryTag() {
      if (!this.qrCode) return;
      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (res && res.status === 505) {
        this.topErrorMessage = res.message;
        this.isActivatedVisible = true;
        return;
      }
      if (!res || !res.data || !res.data[0]) {
        this.isActivatedVisible = true;
        return;
      }
      const data = res.data[0];
      this.isActivatedVisible = false;
      this.inventoryTag = data;
      this.photo = data.item && data.item.photo ? data.item.photo : null;
      this.mime_type = data.item && data.item.mime_type ? data.item.mime_type : null;
      this.quantity = data.quantity_on_hand;
      this.inventoryItem = data.item;
      // If current Qr Code location is one of the warehouses (will be moved to Truck first), then Set defaul Location - 'ON TRUCK' and Shelf - 'Truck Cargo',
      // If current Qr Code location is 'ON TRUCK' (will be moved from Truck to warehouse), then default location is 'ON TRUCK'
      if (data.location && data.location.id !== this.onTruckLocationId) {
        const defaultLocation = this.locationsList.find((x) => x.id === this.onTruckLocationId);
        if (defaultLocation) { this.location = defaultLocation; }

        this.$nextTick(async () => {
          const defaultInvLocation = this.inventoryLocations.find((x) => x.name === 'Truck Cargo');
          if (defaultInvLocation) { this.inventoryLocation = defaultInvLocation; }
        });
      }
    },

    async getInventoryLocations() {
      this.inventoryLocations = await this.$api.inventoryLocation.getList();
    },

    async getLocations() {
      this.locationsList = await this.$api.location.getList();
    },

    async submit() {
      if (!this.inventoryTag) return;
      if (!await this.$refs.form.validate()) return;

      this.$bus.$emit('loading');
      const inventoryClassId = this.inventoryTag && this.inventoryTag.item && this.inventoryTag.item.classes ? this.inventoryTag.item.classes.id : 0;

      // If moving partially and new Qr Code being used, else use the old QR Code
      const qrCode = this.scannedQrCode ? this.scannedQrCode : this.qrCode;

      const submitData = {
        // Tag parameters
        inventory_tag: qrCode,
        inventory_location_id: this.inventoryLocation.id,
        location_id: this.location.id,
        inventory_class_id: inventoryClassId,

        // Movement Parameters
        qr_code: qrCode,
        created_by: this.user.id,
        quantity: this.quantity,

        // Supply Parameters
        consumption_id: 0,
        inventory_item_id: this.inventoryItem.id,
        old_qr_code: this.qrCode,
        // If moving entire bundle
        is_moving_all: this.isMovingAll,
        // If moving to existing tag
        is_scanned_exist: this.isCreateNewInventoryTag === true ? 0 : 1,
      };

      const res = await this.$api.inventoryTag.submitMovement(submitData);
      this.$bus.$emit('loading-stop');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      setTimeout(() => {
        this.redirectBack();
      }, 2000);
    },

    scanQrCode() {
      this.qrCodeDialog = true;
    },

    // Can be two options if moving partially:
    // merge moving tag into existing one (parameters must be the same)
    // or create a new tag
    async returnQrCode(qrCode) {
      if (!qrCode) return;
      this.qrCodeDialog = false;
      this.scannedQrCode = null;
      this.isSubmitBtnActive = false;
      this.isCreateNewInventoryTag = false;

      // Get Scanned qr code if moving partially
      const submitModel = {
        filters: {
          inventory_tag: qrCode,
          is_qty_on_hand_o: 0,
        },
      };

      const resRead = await this.$api.inventoryTag.read(submitModel);
      let res = null;
      if (resRead && resRead.data && resRead.data.length !== 0) {
        const firstIndex = 0;
        res = resRead.data[firstIndex];
      }
      // If scanned tag exist
      if (res) {
        // Check if qr code has the same item parameters as Moving Qr Code
        const checkTag = this.checkInventoryTag(res);
        if (!checkTag) {
          this.$bus.$emit('showError', 'QR Code must have the same Item Parameters as the one being moved!');
          return;
        }
        this.isSubmitBtnActive = true;
        this.scannedQrCode = qrCode;
        this.scannedInventoryTag = res;
        this.location = res.location;
        this.$nextTick(async () => {
          this.inventoryLocation = res.inventory_location;
        });
        // }
      // else scanned tag is missing
      } else {
        this.isCreateNewInventoryTag = true;
        this.scannedQrCode = qrCode;
        this.isSubmitBtnActive = true;
      }

      this.isMovingAll = 0;
    },

    checkInventoryTag(tag) {
      // Check if Inventory Tag has the same item parameters as the one being moved (must have the same parameters)
      try {
        if (
          this.inventoryTag.item.id !== tag.item.id
        || this.inventoryTag.length.id !== tag.length.id
        || this.inventoryTag.uom.id !== tag.uom.id
        || this.inventoryTag.color.id !== tag.color.id
        || this.inventoryTag.item.classes.id !== tag.item.classes.id
        ) {
          return false;
        }
        return true;
      } catch (ex) {
        console.log(ex);
        return false;
      }
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    redirectBack() {
      this.$router.push('/moving-qr');
    },

    historyRedirect() {
      const transactionsTabIndex = 2;
      const transactionsTabId = 3;
      this.$store.commit('storeInventoryTab', transactionsTabIndex);
      this.$store.commit('storeInventoryTabId', transactionsTabId);
      this.$router.push({
        name: 'Inventory',
        params: {
          searchParam: `qr=${this.qrCode}`,
        },
      });
    },
    openNotes() {
      this.$refs.noteForm.open(this.qrCode);
    },
  },
};
</script>
<style scoped>
.profile-photo-mini{
  max-width: 80px;

}
.profile-photo{

  max-height: 400px;
  max-width: 400px;

}
.v-text-field >>> input {
    font-size: 1.5em !important;
}
.v-btn {
    font-size: 1.1em !important;
}

</style>
