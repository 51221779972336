<template>
  <div>
    <!-- DESKTOP VIEW -->
    <v-container v-if="!isMobile">
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          max-width="1050px"
          :persistent="true"
        >
          <v-card style="width: 100%">
            <v-card-title>{{ viewForm ? 'View' : approveForm ? 'Approve/Deny' : 'New' }} Time Off Request</v-card-title>
            <v-card-text>
              <v-container style="width: 100%">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row>
                    <v-col cols="8">
                      <v-row>
                        <v-col class="pb-0">
                          <v-autocomplete
                            v-if="(roles.includes(userRoleIdHrManager) || roles.includes(userRoleIdHr)) && (!viewForm && !approveForm)"
                            v-model="employee"
                            :disabled="viewForm || approveForm"
                            no-data-text="Employee Not Found"
                            :items="employees"
                            :item-text="
                              (employee) => `${employee.first_name} ${employee.last_name}`
                            "
                            return-object
                            placeholder="Type Employee Name"
                          >
                            <template #label>
                              Employee Name<RedAsterisk />
                            </template>
                          </v-autocomplete>
                          <v-text-field
                            v-else
                            v-model="fullName"
                            :disabled="viewForm || approveForm"
                            readonly
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          class="pt-0 pb-0"
                        >
                          <v-text-field
                            v-model="employeeNumber"
                            :disabled="viewForm || approveForm"
                            readonly
                          >
                            <template #label>
                              Employee Number
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          id="department-select-input"
                          cols="12"
                          class="pt-0 pb-0"
                        >
                          <v-autocomplete
                            v-model="departmentName"
                            :items="departmentName ? [departmentName] : []"
                            :disabled="viewForm || approveForm"
                            label="Department"
                            chips
                            readonly
                            hide-no-data
                          >
                            <template #selection>
                              <v-chip
                                v-if="!!departmentName"
                                :color="employee.department ? employee.department.color : ''"
                                text-color="white"
                              >
                                {{ departmentName }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="6"
                          class="pt-0"
                        >
                          <v-text-field
                            v-model="employee.phone_number"
                            :disabled="viewForm || approveForm"
                            :rules="[rules.required]"
                            hint="Needed in case of emergency"
                            persistent-hint
                          >
                            <template #label>
                              Phone <RedAsterisk />
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          class="pt-0"
                        >
                          <v-text-field
                            v-model="employee.email"
                            :disabled="viewForm || approveForm"
                            :rules="[rules.required]"
                            hint="Needed in case of emergency"
                            persistent-hint
                          >
                            <template #label>
                              Email <RedAsterisk />
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :value="employee.available_day_off ? employee.available_day_off : '0'"
                        label="Vacation Entitlement"
                        readonly
                      />
                      <v-text-field
                        :value="employee.vacation_days_taken ? employee.vacation_days_taken : '0'"
                        label="Vacation Entitlement Taken This Calendar Year"
                        readonly
                      />
                      <v-text-field
                        :value="employee.vacation_days_remaining ? employee.vacation_days_remaining : '0'"
                        label="Vacation Entitlement Remaining"
                        readonly
                      />
                      <span
                        v-if="!!employee.vacation_days_exceeded"
                        style="color: red"
                      >Exceeded allowed vacation entitlement by {{ employee.vacation_days_exceeded }}day(s) <span :class="(isDarkMode ? 'theme--dark ' : '') + 'pulsating'">!</span></span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col class="pb-0">
                          <span class="text-body-1">Type of Leave <RedAsterisk /></span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          class="pt-0 pb-0"
                        >
                          <div id="radio-group">
                            <v-radio-group
                              v-model="submitItemDto.leave_type_id"
                              :disabled="viewForm || approveForm"
                              :rules="[rules.required]"
                              row
                            >
                              <v-radio
                                v-for="leaveType in leaveTypes"
                                :key="leaveType.id"
                                color="primary"
                                :value="leaveType.id"
                                dense
                              >
                                <template #label>
                                  <v-chip
                                    class="white-chip"
                                    :color="leaveType.color"
                                  >
                                    {{ leaveType.name }}
                                  </v-chip>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="isBereavement"
                      >
                        <v-col class="pb-0">
                          <span class="text-body-1">Bereavement Type<RedAsterisk /></span>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="isBereavement"
                      >
                        <v-col
                          cols="12"
                          class="pt-0 pb-0"
                        >
                          <div id="radio-group">
                            <v-radio-group
                              v-model="bereavementType"
                              :disabled="viewForm || approveForm"
                              :rules="[rules.required]"
                              row
                            >
                              <v-radio
                                color="primary"
                                value="3"
                              >
                                <template #label>
                                  3 Days Off - Immediate Family
                                  <v-tooltip
                                    open-on-click
                                    top
                                    color="black"
                                  >
                                    <template #activator="{ on }">
                                      <v-icon
                                        color="primary"
                                        v-on="on"
                                      >
                                        mdi-information-outline
                                      </v-icon>
                                    </template>
                                    <span>Mother, Father, Grandparents, Kids, Brother, Sister, Spouse</span>
                                  </v-tooltip>
                                </template>
                              </v-radio>
                              <v-radio
                                color="primary"
                                value="1"
                              >
                                <template #label>
                                  1 Day Off - In-Laws
                                  <v-tooltip
                                    open-on-click
                                    top
                                    color="black"
                                  >
                                    <template #activator="{ on }">
                                      <v-icon
                                        color="primary"
                                        v-on="on"
                                      >
                                        mdi-information-outline
                                      </v-icon>
                                    </template>
                                    <span>Mother-in-law, Father-in-law, sister-in-law, brother-in-law (Not friends) </span>
                                  </v-tooltip>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-row
                            class="pl-3"
                          >
                            <div class="pr-2 pt-3" style="font-size: 1rem">
                              <span>Full Day</span>
                            </div>
                            <v-switch
                              v-model="isHalfDay"
                              :disabled="viewForm || approveForm || isBereavement"
                              hide-details
                              class="mt-0 pt-3"
                              label="Half Day"
                            />
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="4"
                          class="pb-0"
                        >
                          <v-menu
                            v-model="startDayMenu"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            offset-overflow
                            min-width="auto"
                            :close-on-content-click="false"
                          >
                            <template #activator="{ on, attrs }">
                              <v-text-field
                                :value="startDatePickerFormatter"
                                :disabled="employee.id === null || employee.id === undefined || viewForm || approveForm"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                :rules="[rules.required]"
                                v-on="on"
                              >
                                <template #label>
                                  First Day Off<RedAsterisk />
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="submitItemDto.start_date"
                              :min="minStartDate"
                              :max="maxCalendarDate"
                              @input="
                                () => {
                                  updateMinEndDate();
                                  startDayMenu = false;
                                }
                              "
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="4"
                          class="pb-0"
                        >
                          <v-menu
                            v-model="endDayMenu"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            offset-overflow
                            min-width="auto"
                            :close-on-content-click="false"
                          >
                            <template #activator="{ on, attrs }">
                              <v-text-field
                                :disabled="!submitItemDto.start_date || isHalfDay || isBereavement || viewForm || approveForm"
                                :value="endDatePickerFormatter"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                :rules="[rules.required]"
                                v-on="on"
                              >
                                <template #label>
                                  First Day Back to Work<RedAsterisk />
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="submitItemDto.end_date"
                              :min="minEndDate"
                              :max="maxCalendarDate"
                              @input="menu = false"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="4"
                          class="pb-0"
                        >
                          <v-text-field
                            :value="numberOfDaysRequested"
                            label="Days Off Requested"
                            readonly
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="isHalfDay">
                        <v-col
                          cols="6"
                        >
                          <time-entry
                            ref="startTime"
                            :disabled="viewForm || approveForm"
                            :input-time="startTime"
                            label="Start Time Off Work"
                            @time-selected="handleStartTimeSelected"
                            :required="true"
                            :rules="[rules.timeEntryRequired]"
                          />
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <time-entry
                            ref="endTime"
                            disabled
                            :input-time="endTime"
                            label="Back Time to Work"
                            hint="*Back-to-work time is precisely calculated as 4.5 hours from the start time off work"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="!(submitItemDto.id || viewForm || approveForm)">
                    <v-col>
                      <v-file-input
                        v-model="attachments"
                        capture="user"
                        outlined
                        small-chips
                        multiple
                        persistent-hint
                        :hint="isBereavement ? 'Attachment is required for proof of death' : ''"
                        show-size
                      >
                        <template slot="label">
                          Attachments <RedAsterisk v-if="isBereavement" />
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="auto"
                      class="pt-0 pb-0"
                    >
                      <v-checkbox
                        v-model="employeeAgrees"
                        color="primary"
                        :rules="[rules.required]"
                        :disabled="viewForm || approveForm"
                      >
                        <template #label>
                          An employee agrees to pay through payroll deduction any benefit fees owing during my absence<RedAsterisk />
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- <div
                    v-if="approveForm"
                    class="pa-5 mb-10 mt-5"
                    style="background-color: #E3F2FD; border-radius: 5px; font-weight:bold"
                  >
                    <v-row
                      justify="center"
                    >
                      <span>Choose Final Leave Type <RedAsterisk /></span>
                    </v-row>
                    <v-row
                      justify="center"
                    >
                      <v-radio-group
                        v-model="submitItemDto.approved_leave_type_id"
                        :rules="[rules.required]"
                        row
                      >
                        <v-radio
                          v-for="leaveType in leaveTypes"
                          :key="leaveType.id"
                          color="primary"
                          :value="leaveType.id"
                          dense
                        >
                          <template #label>
                            <v-chip
                              class="white-chip"
                              :color="leaveType.color"
                            >
                              {{ leaveType.name }}
                            </v-chip>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-row>
                  </div> -->
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions class="py-3">
              <v-spacer />
              <v-btn
                v-if="submitItemDto.id || viewForm || approveForm"
                color="green"
                @click="viewHistory"
              >
                <span style="color: #fff;">View History</span>
              </v-btn>
              <v-btn
                v-if="submitItemDto.id || viewForm || approveForm"
                color="purple"
                @click="viewAttachments"
              >
                <span style="color: #fff;">View Attachments</span>
              </v-btn>

              <v-btn
                v-if="submitItemDto.id || viewForm || approveForm"
                color="warning"
                @click="openNoteForm"
                class="mr-2"
              >
                <span style="color: #fff;">View Notes</span>
              </v-btn>
              <v-btn
                v-else
                color="warning"
                class="mr-2"
                @click="noteFormDialog = true"
              >
                {{ noteAdded() ? 'EDIT' : 'ADD'}} Note
                <v-icon
                  v-if="noteAdded()"
                  right
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-dialog
                v-model="noteFormDialog"
                width="500"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    New Note
                  </v-card-title>

                  <v-card-text>
                    <v-textarea
                      v-model="submitItemDto.note"
                      solo
                      label="Type here..."
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      text
                      @click="submitItemDto.note = ''"
                    >
                      Clear
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      @click="noteFormDialog = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="noteFormDialog = false"
                    >
                      Add Note
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn
                color="normal"
                @click="closeForm"
              >
                Close
              </v-btn>
              <v-btn
                v-if="submitItemDto.id && !viewForm && !approveForm"
                color="primary"
                @click="updateTimeOffRequest"
              >
                Update
              </v-btn>
              <v-btn
                v-else-if="!viewForm && !approveForm"
                color="primary"
                @click="submitTimeOffRequest"
              >
                Submit
              </v-btn>
              <v-btn
                v-if="approveForm"
                color="primary"
                @click="approveTimeOffRequest"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="approveForm"
                color="error"
                @click="denyTimeOffRequest"
              >
                Deny
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <!-- MOBILE VIEW -->
    <v-container
      v-else
    >
      <v-row>
        <v-dialog
          v-model="dialog"
          fullscreen
          :persistent="true"
        >
          <v-card
            class="d-flex flex-column justify-space-between"
          >
            <div>
              <v-card-title
                id="new-time-off-request-title"
                class="text-center"
              >
                {{ viewForm ? 'VIEW' : 'NEW' }} TIME OFF REQUEST
                <v-spacer />
                <v-btn
                  icon
                  large
                  class="white--text"
                  @click="closeForm"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-title
                class="text-h4 text-center mt-8"
                style="word-break: break-word; justify-content: center; text-align: center;"
              >
                {{ titles[step] }}
              </v-card-title>
            </div>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-card-text class="pt-0">
                <div v-if="step === 0">
                  <v-autocomplete
                    v-if="roles.includes(userRoleIdHrManager) || roles.includes(userRoleIdHr)"
                    v-model="employee"
                    :disabled="viewForm || approveForm"
                    no-data-text="Employee Not Found"
                    :items="employees"
                    :item-text="
                      (employee) => `${employee.first_name} ${employee.last_name}`
                    "
                    return-object
                    placeholder="Type Employee Name"
                    :rules="[(v) => !!v || 'Phone is required. Needed in case of Emergency']"
                  >
                    <template #label>
                      Employee Name<RedAsterisk />
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-model="employee.phone_number"
                    :disabled="viewForm || approveForm"
                    label="Phone"
                    messages="Need in case of Emergency"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Phone is required. Needed in case of Emergency']"
                  />
                  <v-text-field
                    v-model="employee.email"
                    :disabled="viewForm || approveForm"
                    label="Email"
                    messages="Need in case of Emergency"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Email is required. Needed in case of Emergency']"
                  />
                </div>
                <div v-else-if="step === 1">
                  <v-row
                    class="content-wrapper pa-0"
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                      class="pa-0"
                    >
                      <v-radio-group
                        v-model="submitItemDto.leave_type_id"
                        class="mobile-radio-group"
                        :disabled="viewForm || approveForm"
                        :rules="[rules.required]"
                        column
                      >
                        <v-radio
                          v-for="leaveType in leaveTypes"
                          :key="leaveType.id"
                          color="primary"
                          class="mobile-radio"
                          :value="leaveType.id"
                        >
                          <template #label>
                            <v-chip
                              class="mobile-white-chip"
                              :color="leaveType.color"
                            >
                              {{ leaveType.name }}
                            </v-chip>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="isBereavement"
                    class="content-wrapper pt-1"
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                      class="text-center"
                    >
                      <h2>Bereavement Type</h2>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pa-0"
                    >
                      <v-radio-group
                        v-model="bereavementType"
                        :disabled="viewForm || approveForm"
                        class="mobile-radio-group"
                        :rules="[rules.required]"
                        column
                      >
                        <v-radio
                          color="primary"
                          class="mobile-radio"
                          value="3"
                        >
                          <template #label>
                            3 Days Off - Immediate Family
                            <v-tooltip
                              open-on-click
                              top
                              color="black"
                            >
                              <template #activator="{ on }">
                                <v-icon
                                  color="primary"
                                  v-on="on"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span>Mother, Father, Grandparents, Kids, Brother, Sister, Spouse</span>
                            </v-tooltip>
                          </template>
                        </v-radio>
                        <v-radio
                          color="primary"
                          class="mobile-radio"
                          value="1"
                        >
                          <template #label>
                            1 Day Off - In-Laws
                            <v-tooltip
                              open-on-click
                              top
                              color="black"
                            >
                              <template #activator="{ on }">
                                <v-icon
                                  color="primary"
                                  v-on="on"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span>Mother-in-law, Father-in-law, sister-in-law, brother-in-law (Not friends) </span>
                            </v-tooltip>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="step === 2">
                  <v-row
                    class="content-wrapper"
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                    >
                      <v-btn
                        class="mb-6 mobile-btn"
                        x-large
                        block
                        color="info"
                        @click="nextStep"
                      >
                        Full Day
                      </v-btn>
                      <v-spacer />
                      <h1 style="text-align: center">
                        or
                      </h1>
                      <v-spacer />
                      <v-btn
                        class="mt-6 mobile-btn"
                        x-large
                        block
                        color="info"
                        @click="() => {
                          isHalfDay = true;
                          nextStep(4);
                        }"
                      >
                        Half Day
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="step === 3">
                  <v-row
                    class="content-wrapper"
                    align="center"
                    justify="center"
                  >
                    <v-col cols="auto" class="text-center py-0">
                      <h2>First Day Off</h2>
                      <v-dialog
                        ref="startDateDialog"
                        v-model="startDateModal"
                        :return-value.sync="submitItemDto.start_date"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="startDatePickerFormatter"
                            prepend-icon="mdi-calendar"
                            :disabled="viewForm || approveForm"
                            readonly
                            v-bind="attrs"
                            :rules="[rules.required]"
                            v-on="on"
                          >
                            <template #label>
                              Select Date<RedAsterisk />
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="submitItemDto.start_date"
                          :min="minStartDate"
                          scrollable
                          @input="
                            () => {
                              $refs.startDateDialog.save(submitItemDto.start_date)
                              updateMinEndDate();
                              startDateModal = false;
                            }
                          "
                        />
                      </v-dialog>
                    </v-col>
                    <v-col cols="auto" class="text-center py-0" v-if="!isBereavement">
                      <h2>Back to Work Date</h2>
                      <v-dialog
                        ref="endDateDialog"
                        v-model="endDateModal"
                        :return-value.sync="submitItemDto.end_date"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="endDatePickerFormatter"
                            label="Select Back to Work Date"
                            prepend-icon="mdi-calendar"
                            :disabled="viewForm || approveForm"
                            readonly
                            v-bind="attrs"
                            :rules="[rules.required]"
                            v-on="on"
                          >
                            <template #label>
                              Select Date<RedAsterisk />
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="submitItemDto.end_date"
                          :min="minEndDate"
                          scrollable
                          @input="
                            () => {
                              $refs.endDateDialog.save(submitItemDto.end_date)
                              endDateModal = false;
                            }
                          "
                        />
                      </v-dialog>
                    </v-col>

                    <v-col
                      v-else-if="isBereavement && submitItemDto.start_date"
                      cols="auto"
                      class="text-center d-flex flex-column"
                    >
                    <v-row>
                      <v-col class="text-center">
                        <h2>Back to Work Date</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-center">
                        <p>
                          <span class="py-3 px-4" style="font-size: 1rem; background-color: white; border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.5);">{{ endDatePickerFormatter }}</span>
                        </p>
                      </v-col>
                    </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      v-if="isPaid"
                      cols="11"
                      style="background-color: #EDEDED; border-radius: 10px;"
                      class="mb-2"
                    >
                      <v-card-title
                        class="pa-0"
                      >
                        <span>Vacation Entitlement: </span><v-spacer /><span>{{ employee.available_day_off ? employee.available_day_off : '0' }}</span>
                      </v-card-title>
                      <v-card-title
                        class="pa-0"
                      >
                        <span>Vacation Entitlement Taken: </span><v-spacer /><span>{{ employee.vacation_days_taken ? employee.vacation_days_taken : '0' }}</span>
                      </v-card-title>
                      <v-card-title
                        class="pa-0"
                        :style="numberOfDaysRequested > employee.vacation_days_remaining ? 'color: red;' : ''"
                      >
                        <span>Vacation Entitlement Remaining: </span><v-spacer /><span>{{ employee.vacation_days_remaining ? employee.vacation_days_remaining : '0' }}</span>
                      </v-card-title>
                    </v-col>
                    <v-col
                      cols="11"
                      style="background-color: #EDEDED; border-radius: 10px;"
                      class="mb-2"
                    >
                      <v-card-title
                        class="pa-0"
                        :style="numberOfDaysRequested > employee.vacation_days_remaining && isPaid ? 'color: red;' : ''"
                      >
                        <span>Days Off Requested: </span><v-spacer /><span>{{ numberOfDaysRequested ? numberOfDaysRequested : '0' }}</span>
                      </v-card-title>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="step === 4">
                  <v-row
                    class="content-wrapper"
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                      class="text-center"
                    >
                      <h2>Start Date</h2>
                    </v-col>
                    <v-col cols="auto" class="py-0">
                      <v-dialog
                        ref="startDateDialog"
                        v-model="startDateModal"
                        :return-value.sync="submitItemDto.start_date"
                        persistent
                        width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            background-color="white"
                            v-model="startDatePickerFormatter"
                            prepend-icon="mdi-calendar"
                            :disabled="viewForm || approveForm"
                            readonly
                            v-bind="attrs"
                            :rules="[rules.required]"
                            v-on="on"
                          >
                            <template #label>
                              Select Date<RedAsterisk />
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="submitItemDto.start_date"
                          :min="minStartDate"
                          scrollable
                          @input="
                            () => {
                              $refs.startDateDialog.save(submitItemDto.start_date)
                              updateMinEndDate();
                              startDateModal = false;
                              isHalfDay = true;
                            }
                          "
                        />
                      </v-dialog>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="text-center py-0"
                    >
                      <h2>Start Time Off Work</h2>
                    </v-col>
                    <v-col cols="auto" class="py-0">
                      <time-entry
                        ref="startTime"
                        :disabled="viewForm || approveForm"
                        :input-time="startTime"
                        mobile
                        @time-selected="handleStartTimeSelected"
                        background-color="white"
                        :required="true"
                        :rules="[rules.timeEntryRequired]"
                      />
                    </v-col>
                    <v-col
                      v-if="startTime"
                      cols="auto"
                      class="text-center"
                    >
                      <h2>Back Time to Work</h2>
                    </v-col>
                    <v-col cols="auto" v-if="startTime" class="text-center d-flex flex-column">
                      <p>
                        <span class="py-3 px-4" style="font-size: 1rem; background-color: white; border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.5);">{{ endTimeFormatter }}</span>
                      </p>
                      <p class="text-center mb-0">
                        *Back-to-work time is precisely calculated as 4.5 hours from the start time off work
                      </p>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      v-if="isPaid"
                      cols="11"
                      style="background-color: #EDEDED; border-radius: 10px;"
                      class="mb-2"
                    >
                      <v-card-title
                        class="pa-0"
                      >
                        <span>Vacation Entitlement Allowed: </span><v-spacer /><span>{{ employee.available_day_off ? employee.available_day_off : '0' }}</span>
                      </v-card-title>
                      <v-card-title
                        class="pa-0"
                      >
                        <span>Vacation Entitlement Taken: </span><v-spacer /><span>{{ employee.vacation_days_taken ? employee.vacation_days_taken : '0' }}</span>
                      </v-card-title>
                      <v-card-title
                        class="pa-0"
                        :style="numberOfDaysRequested > employee.vacation_days_remaining ? 'color: red;' : ''"
                      >
                        <span>Vacation Entitlement Remaining: </span><v-spacer /><span>{{ employee.vacation_days_remaining ? employee.vacation_days_remaining : '0' }}</span>
                      </v-card-title>
                    </v-col>
                    <v-col
                      cols="11"
                      style="background-color: #EDEDED; border-radius: 10px;"
                      class="mb-2"
                    >
                      <v-card-title
                        class="pa-0"
                        :style="numberOfDaysRequested > employee.vacation_days_remaining ? 'color: red;' : ''"
                      >
                        <span>Days Off Requested: </span><v-spacer /><span>{{ numberOfDaysRequested ? numberOfDaysRequested : '0' }}</span>
                      </v-card-title>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="step === 5">
                  <v-row
                    class="content-wrapper"
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                    >
                      <template
                        v-if="viewForm || approveForm"
                      >
                        <v-btn
                          class="mobile-btn mb-4"
                          x-large
                          block
                          color="info"
                          @click="viewAttachments"
                        >
                          View Attachments
                        </v-btn>
                        <v-btn
                          class="mobile-btn"
                          x-large
                          block
                          color="warning"
                          @click="openNoteForm"
                        >
                          View Notes
                        </v-btn>
                      </template>
                      <template
                        v-else
                      >
                        <v-col cols="12">
                          <div
                            v-for="file in attachments"
                            class="ma-1 file-chip"
                            :key="file.name"
                            color="primary"
                          >
                            <div class="py-2">
                              {{ file.name }}
                            </div>
                            <div
                              style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
                            >
                              <v-btn
                                icon
                                color="pink"
                                @click="removeAttachment(file.name)"
                              >
                                <v-icon style="font-size: 2rem; color: white;">mdi-close-circle</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <v-btn
                            class="mobile-btn"
                            x-large
                            block
                            color="info"
                            @click="triggerFileInput"
                          >
                            Attach File {{ attachments.length > 0 ? `(${attachments.length})` : ''}}
                          </v-btn>
                          <!-- Hidden file input -->
                          <input
                            ref="fileInput"
                            type="file"
                            style="display: none;"
                            multiple
                            @change="handleFileChange"
                          />
                        </v-col>
                        <p v-if="isBereavement"><RedAsterisk />Attachment is required for proof of death</p>
                        <v-spacer v-else />
                        <v-col cols="12" class="pb-0" v-if="noteAdded()">
                          <p class="py-3 px-4" style="font-size: 1rem; background-color: white; border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.5);">
                            Note: {{ submitItemDto.note }}
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            class="mobile-btn"
                            x-large
                            block
                            color="warning"
                            @click="noteFormDialog = true"
                          >
                            {{ noteAdded() ? 'EDIT' : 'ADD'}} Note
                            <v-icon
                              v-if="noteAdded()"
                              right
                            >
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          <v-dialog
                            v-model="noteFormDialog"
                            width="500"
                          >
                            <v-card>
                              <v-card-title class="text-h5">
                                New Note
                              </v-card-title>

                              <v-card-text>
                                <v-textarea
                                  v-model="submitItemDto.note"
                                  solo
                                  label="Type here..."
                                />
                              </v-card-text>
                              <v-card-actions>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="submitItemDto.note = ''"
                                >
                                  Clear
                                </v-btn>
                                <v-spacer />
                                <v-btn
                                  @click="noteFormDialog = false"
                                >
                                  Close
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  @click="noteFormDialog = false"
                                >
                                  Add Note
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </template>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="step === 6">
                  <v-row
                    class="content-wrapper"
                    justify="center"
                    align="center"
                  >
                    <v-row
                      justify="center"
                      align="center"
                    >
                      <v-col
                        cols="auto"
                      >
                        <v-card-title style="word-break: break-word; justify-content: center; text-align: center;">
                          An employee agrees to pay through payroll deduction any benefit fees owing during my absence
                        </v-card-title>
                      </v-col>
                      <v-col
                        cols="auto"
                      >
                        <v-checkbox
                          v-model="employeeAgrees"
                          :disabled="viewForm || approveForm"
                          color="primary"
                          class="large-checkbox"
                          :rules="[rules.required]"
                        >
                          <template #label>
                            I agree<RedAsterisk />
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      align="center"
                    >
                      <v-col cols="12" v-if="!viewForm">
                        <v-btn
                          class="mb-2 mobile-btn"
                          x-large
                          block
                          color="success"
                          @click="submitTimeOffRequest"
                        >
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </v-card-text>
            </v-form>
            <v-card-actions
            >
              <v-row>
                <v-col cols="4" v-if="step > 0"                     justify="center"
                    align="center" >
                  <v-btn
                    class="mb-2"
                    x-large
                    block
                    color="error"
                    @click="prevStep"
                  >
                    Back
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="4" v-if="step < titles.length - 1 && step !== 2"                     justify="center"
                    align="center" >
                  <v-btn
                    class="mb-2"
                    x-large
                    block
                    color="primary"
                    @click="nextStep"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <note-form ref="noteForm" />
    <attachment-form ref="attachmentForm" />
    <time-off-request-history-form ref="timeOffRequestHistoryDialog" />
    <confirm-form ref="confirmForm" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import TimeEntry from '@/components/elements/TimeEntry';
import AttachmentForm from '@/components/elements/AttachmentForm';
import NoteForm from '@/components/forms/notes/NoteForm';
import TimeOffRequestHistoryForm from '@/components/forms/timeOffs/TimeOffRequestHistoryForm';

export default {
  components: {
    TimeEntry,
    AttachmentForm,
    NoteForm,
    TimeOffRequestHistoryForm,
  },
  props: {
    viewForm: {
      type: Boolean,
      default: false,
    },
    approveForm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: null, // TODO refactor to use item instead of submitItemDto
    submitItemDto: {},
    defaultUser: {
      department: null,
      phone_number: null,
      email: null,
    },
    employee: {
      department: null,
    },
    defaultItemDto: {
      user_id: null,
      leave_type_id: null,
      start_date: null,
      end_date: null,
      // approved_leave_type_id: null,
      start_time: null,
      end_time: null,
      note: '',
    },

    employees: [],
    leaveTypes: [],
    roles: [],

    bereavementType: null,
    attachmentTypeIdTimeOff: 2,
    noteTypeIdTimeOff: 2,
    noVacationDaysRemaining: false,
    employeeAgrees: false,
    isHalfDay: false,
    dialog: false,
    valid: false,
    startDayMenu: false,
    endDayMenu: false,
    minEndDate: null,
    maxEndDate: null,
    startTimeMenu: false,
    endTimeMenu: false,
    startTime: '',
    endTime: '',
    isLoading: false,
    numberOfDaysRequested: 0,
    attachments: [],
    note: '',
    userRoleIdAdmin: 3,
    userRoleIdHr: 25,
    userRoleIdTimeOffUser: 27,
    userRoleIdTimeOffSupervisor: 28,
    userRoleIdHrManager: 29,
    userRoleIdTimeOffAccountant: 30,
    timeOffLeaveTypeIdPaid: 2,
    timeOffLeaveTypeIdBereavement: 3,
    timeOffRequestStatusIdCancelApproval: 6,

    // MOBILE VIEW DATA
    titles: ['Confirm Phone and Email', 'Select Leave Type', 'Select Date', 'Full Day Period', 'Half Day', 'Attach Documents or Note', 'Confirm'],
    step: 0,
    startDateModal: false,
    endDateModal: false,
    startTimeModal: false,
    endTimeModal: false,
    noteFormDialog: false,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
      user: (state) => state.user,
    }),
    ...mapGetters([]),
    minStartDate() {
      if(this.roles.includes(this.userRoleIdHrManager) || this.roles.includes(this.userRoleIdHr)) {
        // return moment(new Date().toLocaleDateString('en-US')).subtract(7, 'days').format('YYYY-MM-DD');
        return undefined;
      } else {
        // return moment(new Date().toLocaleDateString('en-US')).format('YYYY-MM-DD');
        return moment(new Date().toLocaleDateString('en-US')).subtract(7, 'days').format('YYYY-MM-DD');
      }
    },
    startDatePickerFormatter() {
      return this.submitItemDto.start_date
        ? moment(this.submitItemDto.start_date).format('YYYY-MMM-DD')
        : '';
    },
    endDatePickerFormatter() {
      return this.submitItemDto.end_date
        ? moment(this.submitItemDto.end_date).format('YYYY-MMM-DD')
        : '';
    },
    fullName() {
      return this.employee && this.employee.full_name ? this.employee.full_name : '';
    },
    departmentName() {
      return this.employee && this.employee.department ? this.employee.department.name : '';
    },
    employeeNumber() {
      return this.employee && this.employee.employee_number ? this.employee.employee_number : '';
    },
    phoneNumber() {
      return this.employee && this.employee.phone_number ? this.employee.phone_number : '';
    },
    email() {
      return this.employee && this.employee.email ? this.employee.email : '';
    },
    startTimeFormatter() {
      const placeholder = '2000-01-01T';
      return this.startTime
        ? moment(placeholder + this.startTime).format('hh:mm a')
        : '';
    },
    endTimeFormatter() {
      const placeholder = '2000-01-01T';
      return this.endTime
        ? moment(placeholder + this.endTime).format('hh:mm a')
        : '';
    },
    exceedsAllowedDays() {
      return this.numberOfDaysRequested > this.employee.vacation_days_remaining;
    },
    isBereavement() {
      return this.submitItemDto.leave_type_id === this.timeOffLeaveTypeIdBereavement;
    },
    isPaid() {
      return this.submitItemDto.leave_type_id === this.timeOffLeaveTypeIdPaid;
    },
    rules() {
      return {
        required: (value) => !!value || 'Required.',
        timeEntryRequired: (value) => value !== null && value !== undefined && value !== '' || 'Required.',
      };
    },
    maxCalendarDate() {
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      return `${nextYear}-12-31`;
    },
    isMobile() {
      if (window.innerWidth) {
        return window.innerWidth <= 768;
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
  },

  watch: {
    'submitItemDto.start_date': function (newStartDate) {
      if (this.viewForm || this.approveForm) return;
      if (this.isHalfDay) {
        this.submitItemDto.end_date = newStartDate;
      }
      if (newStartDate) {
        this.updateMinEndDate();
      }
      if (newStartDate && this.isBereavement) {
        this.updateBereavementEndDate(this.bereavementType);
      }
      if (newStartDate !== null && this.submitItemDto.end_date !== null && newStartDate === this.submitItemDto.end_date) {
        this.isHalfDay = true;
      }
    },
    'submitItemDto.end_date': function (newEndDate) {
      if (this.viewForm || this.approveForm) return;
      if (newEndDate !== null && this.submitItemDto.start_date !== null && newEndDate === this.submitItemDto.start_date) {
        this.isHalfDay = true;
      }
    },
    'submitItemDto.leave_type_id': function (leaveTypeId) {
      if (this.viewForm || this.approveForm) return;
      if (leaveTypeId === this.timeOffLeaveTypeIdBereavement) {
        this.submitItemDto.start_date = null;
        this.submitItemDto.end_date = null;
        this.submitItemDto.start_time = null;
        this.submitItemDto.end_time = null;
        this.numberOfDaysRequested = 0;
      }
      this.bereavementType = null;
    },
    bereavementType() {
      if (this.viewForm || this.approveForm) return;
      this.submitItemDto.start_date = null;
      this.submitItemDto.end_date = null;
      this.submitItemDto.start_time = null;
      this.submitItemDto.end_time = null;
      this.numberOfDaysRequested = 0;
    },
    isBereavement(newVal) {
      if (newVal) {
        // if isBereavement set swtich to full day
        this.isHalfDay = false
      }
    },
    isHalfDay(newVal) {
      if (newVal) {
        // If isHalfDay is true, set end_date same as start_date
        this.submitItemDto.end_date = this.submitItemDto.start_date;
      } else {
        this.submitItemDto.end_date = null;
      }
    },
    startTime(newStartTime) {
      // If startTime is selected, calculate endTime 4.5 hours later
      if (newStartTime) {
        this.endTime = this.addFourAndAHalfHours(newStartTime);
      } else {
        // If startTime is cleared, clear endTime
        this.endTime = '';
      }
    },
    startDatePickerFormatter() {
      this.getDaysOffRequested();
    },
    endDatePickerFormatter() {
      this.getDaysOffRequested();
    },
  },

  async created() {
    this.item = { ...this.defaultItemDto };
    this.roles = this.user.roles.map((x) => x.id);
    await this.init();
  },

  methods: {
    async init() {
      this.resetForm();
      this.getLeaveTypes();
      // if (this.employees.length <= 0 && (this.roles.includes(this.userRoleIdAdmin) || this.roles.includes(this.userRoleIdHr))) {
      //   await this.getEmployees();
      // } else {
      //   await this.getUser();
      // }
    },

    async get(id) {
      if (!id) return;
      this.$bus.$emit('loading');
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.timeOffRequest.read(submitModel);
      if (!res || !res.data || !res.data[0]) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Unable to retrieve time off request');
        return;
      }
      const firstIndex = 0;
      this.submitItemDto = JSON.parse(JSON.stringify(res.data[firstIndex]));

      const userSubmitModel = {
        filters: {
          id: this.submitItemDto.user.id,
          is_vacation_days: true,
        },
      };
      const userRes = await this.$api.user.read(userSubmitModel);
      if (!userRes || !userRes[0]) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Unable to retrieve user');
        return;
      }
      this.employee = userRes[firstIndex];
      this.employee.full_name = userRes[firstIndex].full_name;

      this.employeeAgrees = true;
      this.checkIsHalfDay();
      this.setTimeEntryTime();
      this.setBereavementType();
      this.$bus.$emit('loading-stop');
    },

    async getEmployees() {
      this.isLoading = true;
      const submitModel = {
        filters: {
          is_username: false,
          is_vacation_days: true,
        },
      };
      const res = await this.$api.user.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (res.length <= 0) {
        this.employees = [];
        return;
      }
      this.employees = res;
      this.isLoading = false;
    },

    async getUser() {
      this.isLoading = true;
      const submitModel = {
        filters: {
          id: this.user.id,
          is_vacation_days: true,
        },
      };
      const res = await this.$api.user.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode || res.length !== 1) {
        this.employee = null;
        return;
      }
      const firstIndex = 0;
      this.employee = res[firstIndex];
      this.isLoading = false;
    },

    async getLeaveTypes() {
      this.leaveTypes = await this.$api.timeOffLeaveType.read({});
    },

    async submitTimeOffRequest() {
      if (!this.$refs.form.validate()) return;
      if (!this.isValidRequest()) return;
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to submit this request?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      this.submitItemDto.user_id = this.employee.id;
      this.submitItemDto.user = this.employee;
      if (this.isHalfDay) {
        this.submitItemDto.start_time = this.startTime;
        this.submitItemDto.end_time = this.endTime;
      }
      const showErrorMessage = (error) => {
        const errorMessage = error && error.response && error.response.data && error.response.data.error ? error.response.data.error : null;
        this.$bus.$emit('showError', errorMessage);
      }
      const res = await this.$api.timeOffRequest.store(this.submitItemDto, null, {}, showErrorMessage);
      if (!res) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', res.error);
        return;
      }

      const attachmentRes = await this.submitAttachments(res);
      if (!attachmentRes) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Attachment(s) could not be saved');
        return;
      }

      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.closeForm();
    },

    async submitAttachments(recordId) {
      if (this.attachments.length <= 0) return true;
      const submitModel = {
        record_id: recordId,
        type_id: this.attachmentTypeIdTimeOff,
      };
      for (let i = 0; i < this.attachments.length; i++) {
        const formData = new FormData();
        formData.append('file', this.attachments[i]);
        formData.append('record_id', submitModel.record_id);
        formData.append('type_id', submitModel.type_id);
        // eslint-disable-next-line no-await-in-loop
        const res = await this.$api.attachment.store(formData);
        if (!res) {
          this.$bus.$emit('showError');
          return false;
        }
      }
      return true;
    },

    async updateTimeOffRequest() {
      if (!this.$refs.form.validate()) return;
      if (!this.isValidRequest()) return;
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to update this request?',
      });
      if (!confirm) return;
      this.submitItemDto.user_id = this.employee.id;
      if (this.isHalfDay) {
        this.submitItemDto.start_time = this.startTime;
        this.submitItemDto.end_time = this.endTime;
      }
      const res = await this.$api.timeOffRequest.update(this.submitItemDto);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.closeForm();
    },

    async approveTimeOffRequest() {
      if (!this.$refs.form.validate()) return;
      // const exceedesVacationDays = this.employee && this.employee.vacation_days_remaining !== null && this.submitItemDto && this.submitItemDto.leave_type_id === this.timeOffLeaveTypeIdPaid && this.employee.vacation_days_remaining < this.submitItemDto.days_requested
      // const confirm = await this.$refs.confirmForm.open({
      //   title: 'Are you sure that you want to approve this request?',
      //   message: exceedesVacationDays ? 'Days Requested for this Paid Time Off Request Exceeds Vacation Entitlement Remaining' : '',
      //   confirmationType: exceedesVacationDays ? 'warning' : '',
      // });
      const isCancellationRequest = this.submitItemDto && this.submitItemDto.status_id && this.submitItemDto.status_id === this.timeOffRequestStatusIdCancelApproval;
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to approve this ${isCancellationRequest ? 'cancellation ' : ''}request?`,
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.timeOffRequest.get(`approve/${this.submitItemDto.id}`);
      if (!res) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.closeForm();
    },

    async denyTimeOffRequest() {
      const isCancellationRequest = this.submitItemDto && this.submitItemDto.status_id && this.submitItemDto.status_id === this.timeOffRequestStatusIdCancelApproval;
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to deny this ${isCancellationRequest ? 'cancellation ' : ''}request?`,
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.timeOffRequest.get(`deny/${this.submitItemDto.id}`);
      if (!res) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.closeForm();
    },

    async open(id) {
      if (id) {
        this.item.id = id;
        await this.get(id);
      } else {
        this.submitItemDto = { ...this.defaultItemDto };
        if (this.roles.includes(this.userRoleIdHrManager) || this.roles.includes(this.userRoleIdHr)) {
          await this.getEmployees();
        } else if ((this.roles.includes(this.userRoleIdTimeOffAccountant) || this.roles.includes(this.userRoleIdTimeOffSupervisor) || this.roles.includes(this.userRoleIdTimeOffUser))) {
          await this.getUser();
        }
      }
      this.dialog = true;
    },

    async resetForm() {
      this.item.id = null;
      this.submitItemDto = JSON.parse(JSON.stringify(this.defaultItemDto));
      this.employee = JSON.parse(JSON.stringify(this.defaultUser));
      this.employeeAgrees = false;
      this.isHalfDay = false;
      this.startTime = '';
      this.endTime = '';
      this.attachments = [];
      this.notes = '';
      this.step = 0;
      this.numberOfDaysRequested = 0;
      this.bereavementType = null;
    },

    closeForm() {
      this.$refs.form.resetValidation(); // this can only be here. If it's in resetForm () it causes an error when the form is being mounted
      this.resetForm();
      this.dialog = false;
      this.$emit('close-form');
    },

    viewAttachments() {
      this.$refs.attachmentForm.open(this.item.id, this.attachmentTypeIdTimeOff);
    },

    openNoteForm() {
      this.$refs.noteForm.open(this.item.id, this.noteTypeIdTimeOff);
    },

    viewHistory() {
      this.$refs.timeOffRequestHistoryDialog.open(this.item.id);
    },

    checkIsHalfDay() {
      // if half day, set halfDay to true
      if (this.submitItemDto.start_date === this.submitItemDto.end_date && this.submitItemDto.start_time && this.submitItemDto.end_time) {
        this.isHalfDay = true;
      }
    },

    setTimeEntryTime() {
      if (this.submitItemDto.start_date === this.submitItemDto.end_date && this.submitItemDto.start_time && this.submitItemDto.end_time) {
        this.startTime = this.submitItemDto.start_time;
        this.endTime = this.submitItemDto.end_time;
      }
    },

    setBereavementType() {
      if (this.isBereavement) {
        this.bereavementType = this.submitItemDto.days_requested.toString();
      }
    },

    isValidRequest() {
      if (this.submitItemDto.start_date && this.submitItemDto.end_date && this.submitItemDto.start_date > this.submitItemDto.end_date) {
        this.$bus.$emit('showError', 'First Day Back to Work cannot be before First Day Off');
        return false;
      }
      // if (this.numberOfDaysRequested > this.employee.vacation_days_remaining && this.isPaid) {
      //   this.$bus.$emit('showError', 'Days off requested exceedes vacation days remaining');
      //   return false;
      // }
      if (this.isBereavement && this.attachments.length === 0 && !this.viewForm) {
        this.$bus.$emit('showError', 'Please attach proof of death');
        return false;
      }
      return true;
    },

    updateMinEndDate() {
      // this.minEndDate = this.submitItemDto.start_date;
      let date = new Date(this.submitItemDto.start_date);
      date.setDate(date.getDate() + 1);
      this.minEndDate = date.toISOString().split('T')[0];
    },

    async updateBereavementEndDate(days = null) {
      if(days) {
        const submitModel = {
          start_date: this.startDatePickerFormatter,
          days: days,
        };
        const res = await this.$api.timeOffRequest.store(submitModel, 'end-date');
        if (!res) {
          return;
        }
        this.submitItemDto.end_date = res
      }
    },

    async getDaysOffRequested() {
      if (!this.startDatePickerFormatter || !this.endDatePickerFormatter) {
        return;
      }
      const submitModel = {
        start_date: this.startDatePickerFormatter,
        end_date: this.endDatePickerFormatter,
      };
      const res = await this.$api.timeOffRequest.store(submitModel, 'days-off-requested');

      if (!res && res !== 0) {
        return;
      }

      this.numberOfDaysRequested = res;
    },

    handleStartTimeSelected(time) {
      this.startTime = time;
      this.endTime = this.addFourAndAHalfHours(time);
    },

    addFourAndAHalfHours(inputTime) {
      const timeComponents = inputTime.split(':');
      let hours = parseInt(timeComponents[0], 10);
      let minutes = parseInt(timeComponents[1], 10);
      const seconds = parseInt(timeComponents[2], 10);

      // Add four and a half hours
      hours += 4;
      minutes += 30;

      // Handle carryover
      if (minutes >= 60) {
        hours += 1;
        minutes -= 60;
      }
      if (hours >= 24) {
        hours -= 24;
      }

      // Format the result back to time string
      const resultTime = `${((hours < 10) ? '0' : '') + hours}:${
        (minutes < 10) ? '0' : ''}${minutes}:${
        (seconds < 10) ? '0' : ''}${seconds}`;
      return resultTime;
    },

    handleEndTimeSelected(time) {
      this.endTime = time;
    },

    // MOBILE VIEW METHODS
    nextStep(newStep = null) {
      if (!this.$refs.form.validate() && !this.viewForm) return;
      if (this.step === 1 && this.viewForm && this.isHalfDay) {
        this.step = 4
      } else if (this.step === 1 && this.viewForm && !this.isHalfDay) {
        this.step = 3
      } else if (this.step === 1 && this.isBereavement) {
        this.step = 3;
      } else if (this.step === 3) {
        if (this.numberOfDaysRequested > this.employee.vacation_days_remaining && this.isPaid && !this.viewForm) {
          this.$bus.$emit('showError', 'Days Off Requested Exceedes Vacation Entitlement Remaining');
          return;
        }
        this.step = 5;
      } else if (this.step === 5) {
        if (this.isBereavement && this.attachments.length === 0 && !this.viewForm) {
          this.$bus.$emit('showError', 'Please Attach Proof of Death');
          return;
        }
        this.step += 1;
      } else if (newStep === parseInt(newStep, 10)) {
        this.step = newStep;
      } else if (this.step < this.titles.length - 1) {
        this.step += 1;
      }
    },
    prevStep(step = null) {
      if ((this.step === 3 || this.step === 4) && this.viewForm) {
        this.step = 1;
      } else if (this.step === 3 && this.isBereavement) {
        this.step = 1;
      }else if (this.step === 5 && !this.isHalfDay) {
        this.step = 3;
      } else if (this.step === 4 && this.isHalfDay) {
        this.step = 2;
        this.isHalfDay = false;
      } else if (step === parseInt(step, 10)) {
        this.step = step;
      } else if (this.step > 0) {
        this.step -= 1;
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const { files } = event.target;
      if (files.length > 0) {
        files.forEach((file) => {
          this.attachments.push(file);
        })
      }
    },
    noteAdded() {
      const trimmedText = this.submitItemDto.note.trim();
      return trimmedText !== ''
    },
    removeAttachment(name){
      this.attachments = this.attachments.filter((file) => file.name !== name);
    },
  },
};
</script>

<style scoped>
/deep/.file-chip {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #1976d2;
  border-radius: 8px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
  display: flex;
  justify-content: space-between;
}
/deep/.content-wrapper {
  margin: .6rem;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding: 1rem;
}
/deep/.large-checkbox .v-input--selection-controls__ripple {
  width: 2rem;
  height: 2rem;
}
.large-checkbox {
  font-size: 1.4rem;
}

/deep/.large-checkbox .v-icon {
  font-size: 2rem;
}
/deep/.mobile-radio .v-input--selection-controls__input {
  height: 1.4rem;
  width: 1.4rem;
}

/deep/.mobile-radio .v-icon {
  font-size: 1.4rem; /* Adjust the size as desired */
}
.mobile-white-chip {
  height: 2rem;
  color: white !important;
  font-size: 1rem;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
/deep/.mobile-radio-group > .v-input__control > .v-input__slot {
  /* width: 45%; */
}
/deep/.mobile > .v-input__control > .v-input__slot > .v-text-field__slot > input{
  max-height: 60px;
}
/deep/.mobile > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label {
  line-height: unset;
  height: 80px;
  font-size: 1.4rem;
}
/deep/.mobile > .v-input__control > .v-text-field__details{
  height: 30px;
}
/deep/.mobile > .v-input__control > .v-text-field__details > .v-messages > .v-messages__wrapper > .v-messages__message{
  position: absolute;
  top: 10px;
  font-size: 1rem;
}
/deep/.mobile > .v-text-field input .user-details-text-field {
  font-size: 1rem !important;
}
/deep/.mobile-wrapper {
  background-color: #EDEDED;
}
/deep/.mobile-btn {
  font-size: 1.2rem;
}
/deep/.mobile-card {

}
/deep/.mobile-title {

}
/deep/#new-time-off-request-title {
  background-color: black;
  color: white;
}
/deep/#radio-group-wrapper {

}
/deep/#type-of-leave-wrapper {
  background-color: #F4F4F4;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.white-chip {
  height: 24px;
  color: white !important;
  font-size: 95%;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
/deep/#department-select-input > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-input__append-inner > .v-input__icon > .v-icon {
  display: none !important;
}
/deep/.red-hint > div.v-input__control > div.v-text-field__details > div.v-messages > div.v-messages__wrapper > div.v-messages__message {
  color: red;
  font-size: .9rem;
  margin-top: 1rem;
  height: 1rem;
}
/deep/.v-radio.v-radio--is-disabled.theme--light.v-item--active > .v-input--selection-controls__input > i.v-icon.notranslate.far.fa-dot-circle.theme--light.primary--text {
  /* not working */
  color: #1976d2 !important;
}
/deep/.v-radio.v-radio--is-disabled.theme--light > .v-label.theme--light {
  color: rgba(0, 0, 0, 0.87) !important;
}
/deep/.v-label.v-label--active.v-label--is-disabled.theme--light {
  color:rgba(0, 0, 0, 0.6) !important;
}
/deep/.v-label.v-label--active.v-label--is-disabled.theme--dark {
  color:rgba(255, 255, 255, 0.7) !important;
}
/deep/.v-input > .v-input__control > .v-input__slot > div > input {
  color: rgba(0, 0, 0, 0.87) !important;
}
/deep/.v-input.theme--dark > .v-input__control > .v-input__slot > div > input {
  color: #FFFFFF !important;
}
/deep/.v-input__slot > .v-label.v-label--is-disabled.theme--light {
  color:rgba(0, 0, 0, 0.87) !important;
}
/deep/.v-input__slot > .v-label.v-label--is-disabled.theme--dark {
  color:rgba(255, 255, 255) !important;
}
/deep/.v-label.v-label--is-disabled.theme--light {
  color:rgba(0, 0, 0, 0.6);
}
/deep/.v-label.v-label--is-disabled.theme--dark {
  color:rgba(255, 255, 255, 0.7) !important;
}
/deep/.theme--dark.v-select .v-select__selection--disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
/deep/.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
/deep/.pulsating {
  position: relative;
  left: 10px;
  font-weight: bold;
  font-size: 1rem;
  color: red;
  display: inline-block;
  width: 25px !important;
  height: 25px !important;
  text-align: center;
  border: 2px solid red;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1.5s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
/deep/.theme--dark.pulsating {
  font-weight: bold;
  font-size: 1rem;
  color: red;
  display: inline-block;
  width: 25px !important;
  height: 25px !important;
  text-align: center;
  border: 2px solid red;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  transform: scale(1);
  animation: pulse-white 1.5s infinite;
}
@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
</style>
