<template>
  <v-container
    fluid
  >
    <v-row align="center">
      <v-col
        class="pb-0"
        cols="auto"
      >
        <v-tabs
          v-model="selectedTab"
        >
          <v-tab>
            ACTIVE
          </v-tab>
          <v-tab>
            ARCHIVED
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        class="text-end pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="
            () => {
              $refs.consumptionForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          MATERIAL SUPPLY / RETURN SHEET
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="4"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJobId"
          label="Job"
          :items="jobs"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
          <template slot="label">
            Job
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="consumptionsHeaders"
            :items="consumptions"
            :footer-props="itemsTableFooterProps"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #[`item.id`]="{ item }">
              <a @click="openConsumption(item)">
                <text-highlight :queries="search">
                  {{ item.id }}
                </text-highlight>
              </a>
            </template>

            <template #[`item.sheet_date`]="{ item }">
              <text-highlight :queries="search">
                {{ item.sheet_date_formated }}
              </text-highlight>
            </template>
            <template
              #[`item.inventory_class`]="{ item }"
            >
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'grey'"
                text-color="white"
              >
                <text-highlight :queries="search">
                  {{ item && item.class ? item.class.name : 'All' }}
                </text-highlight>
              </v-chip>
            </template>
            <template #[`item.job`]="{ item }">
              <span>
                <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
              </span>
            </template>
            <template #[`item.address_simple`]="{ item }">
              <text-highlight :queries="search">
                {{ item.address_simple }}
              </text-highlight>
            </template>
            <template #[`item.records_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.records_number }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="success"
                outlined
                small
                @click="openConsumption(item)"
              >
                OPEN
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1 mr-1"
                color="primary"
                outlined
                small
                @click="openConsumptionForm(item)"
              >
                EDIT
              </v-btn>
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mt-1 mb-1"
                    elevation="0"
                    fab
                    outlined
                    x-small
                    v-bind="attrs"
                    tile
                    color="primary"
                    style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  class="pa-3"
                >
                  <v-list-item
                    @click="runWebhook(item.id, item.inventory_class_id)"
                  >
                    <v-list-item-title :style="`color: green; font-weight: 600;`">
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="pr-0 pl-4"
                          style="white-space: nowrap;"
                        >
                          <v-icon
                            style="font-size: 16px;"
                            color="green"
                          >
                            fas fa-file
                          </v-icon>
                        </v-col>
                        <v-col class="pt-4">
                          PDF
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="archive(item)"
                  >
                    <v-list-item-title :style="`color: purple; font-weight: 600;`">
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="pr-0"
                          style="white-space: nowrap;"
                        >
                          <v-icon
                            style="font-size: 18px;"
                            color="purple"
                          >
                            mdi-archive
                          </v-icon>
                        </v-col>
                        <v-col class="pl-2 pt-4">
                          {{ item.is_archived ? 'RESTORE' : 'ARCHIVE' }}
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate)"
                    :disabled="item.created_at.includes('2030-')"
                    @click="deleteConsumption(item)"
                  >
                    <v-list-item-title :style="`color: #FF5252; font-weight: 600;`">
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="pr-0"
                          style="white-space: nowrap;"
                        >
                          <v-icon
                            style="font-size: 19px;"
                            color="#FF5252"
                          >
                            mdi-delete
                          </v-icon>
                        </v-col>
                        <v-col class="pl-2 pt-4">
                          DELETE
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ConsumptionForm
      ref="consumptionForm"
      @consumption-creation-success="notifyConsumptionCreationSuccess"
      @consumption-creation-fail="notifyConsumptionCreationFail"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ConsumptionForm from '@/components/forms/supplyToLine/SupplyToLineForm';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  name: 'Consumptions',
  components: {
    ConsumptionForm,
  },
  filters: {},
  data: () => ({
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    consumptions: [],
    jobs: [],
    inventoryClassId: null,
    filterJobId: null,
    webhook: null,
    windowHeight: 1000,
    topSpaceHeight: 290,
    selectedTab: 0,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    total: 0,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
  }),
  computed:
  {
    consumptionsHeaders() {
      const headers = [];
      headers.push({
        text: 'Class', value: 'inventory_class', width: '5%',
      });
      headers.push({ text: 'Sheet#', value: 'id', width: '5%' });
      headers.push({ text: 'Job', value: 'job', width: '25%' });
      headers.push({ text: 'Job Address', value: 'address_simple', width: '20%' });
      headers.push({ text: 'Records#', value: 'records_number', width: '5%' });
      headers.push({ text: 'Sheet Date', value: 'sheet_date', width: '10%' });
      headers.push({ text: 'Updated On', value: 'updated_at', width: '10%' });
      headers.push({
        text: 'Actions', value: 'actions', width: '15%', align: 'end',
      });

      return headers;
    },
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      user: 'user',
      search: (state) => state.search,
      supplyToLinesState: (state) => state.supplyToLinesState,
    }),
  },
  watch: {
    inventoryClassId() {
      this.init();
    },
    filterJobId(value) {
      this.storeState('job_code', value);
      this.get();
    },
    selectedTab() {
      this.get();
    },
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },

  },
  created() {
    if (this.supplyToLinesState) {
      if (this.supplyToLinesState.pagination) { this.pagination = this.supplyToLinesState.pagination; }
      if (this.supplyToLinesState.job_code) { this.filterJobId = this.supplyToLinesState.job_code; }
    }
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
    this.getWebhook();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {
      this.get();
      this.getJobs();
    },

    storeState(property, value) {
      const state = this.supplyToLinesState;
      state[property] = value;
      this.$store.commit('storeSupplyToLinesState', state);
    },

    async get() {
      if (!this.user.id) return;
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.consumptions = [];
      this.total = 0;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
          user_id: this.user.id,
          job_id: this.filterJobId,
          is_archived: this.selectedTab,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.supplyToLine.readData(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.consumptions = this.pagination && this.pagination.itemsPerPage === -1 ? res : res.data;
      this.total = res.total;
    },

    async getJobs() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
          is_manual: 0,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      this.jobs = await this.$api.job.read(readModel);
    },

    async getWebhook() {
      const stlModuleId = 3;
      const readModel = {
        filters:
        {
          module_id: stlModuleId,
        },
      };
      const res = await this.$api.webhook.read(readModel);
      if (!res || !res[0]) return;
      const firstIndex = 0;
      this.webhook = res[firstIndex];
    },

    async deleteConsumption(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.supplyToLine.delete(item.id);
      if (res) {
        this.$bus.$emit('showSuccess');
      } else {
        this.$bus.$emit('showError');
      }
      this.get();
      this.$bus.$emit('loading');
    },

    async archive(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to archive this record?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.supplyToLine.get(`archive/${item.id}`);
      if (res) {
        this.$bus.$emit('showSuccess');
      } else {
        this.$bus.$emit('showError');
      }
      this.get();
      this.$bus.$emit('loading');
    },

    openConsumption(item) {
      this.$router.push(`/consumptions/${item.id}`);
    },

    openConsumptionForm(item) {
      this.$refs.consumptionForm.openDialog(item);
    },

    notifyConsumptionCreationSuccess() {
      this.$bus.$emit('showSuccess');
      this.get();
    },
    notifyConsumptionCreationFail() {
      this.$bus.$emit('showError');
    },

    async runWebhook(id, inventoryClassId) {
      this.$bus.$emit('loading');
      const stlModuleId = 3;
      const submitModel = { id, inventoryClassId, module_id: stlModuleId };
      const res = await this.$api.webhook.store(submitModel, 'run');
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }
      const pdf = await this.$api.report.download(res.id, 'pdf');
      if (!pdf) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${res.report_title}_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
        this.pagination = pagination;
        this.storeState('pagination', pagination);
      }
    },
  },
};
</script>
<style scoped></style>
