<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :max-width="600"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Job</span>
      <v-progress-linear
        v-if="itemLoading"
        indeterminate
        rounded
        height="4"
        color="primary"
      />
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="6"
          class="pb-0"
        >
          <inventory-class-select-element
            v-model="item.inventory_class_id"
            :is-add-all="false"
            :is-required="true"
          />
        </v-col>
        <v-col
          v-if="isSubClass"
          cols="6"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.sub_class_id"
            :items="subClasses"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Sub Class is required']"
          >
            <template slot="label">
              Sub Class<RedAsterisk />
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                v-if="item"
                small
                :color="item.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.name }}
              </v-chip>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <v-chip
                v-if="item"
                small
                :color="item.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="isProject">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.project_id"
            :items="projects"
            item-text="name"
            item-value="id"
            :clearable="true"
            :rules="[(v) => !!v || 'Project is required']"
          >
            <template slot="label">
              Project<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="isLocation">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.location_id"
            :items="locations"
            item-text="name"
            item-value="id"
          >
            <template slot="label">
              Location
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="isAddress">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.client_address_id"
            :items="addresses"
            item-value="id"
            item-text="address_simple"
            :rules="[(v) => !!v || 'Address is required']"
            :clearable="true"
          >
            <template slot="label">
              Address<RedAsterisk />
            </template>
            <template #append-outer>
              <v-btn
                class="mrp-btn-add"
                color="primary"
                outlined
                small
                @click="openClientAddressForm()"
              >
                <v-icon small>
                  fas fa-plus
                </v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="isWorkOrder">
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.code"
            :rules="[(v) => !!v || `Work Order# is required`, (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
          >
            <template slot="label">
              Work Order#<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.reference"
            :rules="[(v) => !!v || 'Reference is required']"
          >
            <template slot="label">
              Reference (Release/Floor)<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-else-if="isReference">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.reference"
            :rules="[(v) => !!v || 'Reference is required']"
          >
            <template slot="label">
              Reference (Release/Floor)<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="isShowPending">
        <v-col class="pt-0">
          <v-checkbox
            v-model="isPending"
            hide-details
            class="mt-0"
            label="Use Pending Code (Work Order doesn't exist yet.)"
          />
        </v-col>
      </v-row>
      <v-row v-if="isJobName">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-text-field v-model="item.name">
            <template slot="label">
              Job Name (Project Name)
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="isSupervisor">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.supervisor_id"
            :items="supervisors"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Supervisor is required']"
            required
          >
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <span>
                {{ item.first_name }} {{ item.last_name }}
              </span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <span>
                {{ item.first_name }} {{ item.last_name }}
              </span>
            </template>
            <template slot="label">
              Person Responsible (Supervisor, Foreman, Project Manager)<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <client-address-form
            ref="clientAddressForm"
            @success="successAddress"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import ClientAddressForm from '@/components/forms/clients/ClientAddressForm';
import { mapGetters } from 'vuex';

export default {
  components: {
    ClientAddressForm,
  },
  props: {
    isSupervisor: {
      type: Boolean,
      default: true,
    },
    isSubClass: {
      type: Boolean,
      default: false,
    },
    isShowPending: {
      type: Boolean,
      default: false,
    },
    isJobName: {
      type: Boolean,
      default: true,
    },
    isWorkOrder: {
      type: Boolean,
      default: true,
    },
    isAddress: {
      type: Boolean,
      default: true,
    },
    isProject: {
      type: Boolean,
      default: true,
    },
    isLocation: {
      type: Boolean,
      default: false,
    },
    isReference: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    item: null,
    itemId: 0,
    itemDto: {
      id: 0,
      project_id: null,
      client_address_id: null,
      supervisor_id: null,
      name: null,
      code: null,
      created_by: null,
      inventory_class_id: null,
      is_manual: 0,
      sub_class_id: null,
      reference: null,
    },
    supervisors: [],
    addresses: [],
    isJobCode: false,
    itemLoading: false,
    subClasses: [],
    projects: [],
    locations: [],
    isPending: false,
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
      'getAdminModuleValue',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_class_id': function (value) {
      if (!value) return;
      this.getAddresses();
    },
    isPending() {
      this.item.code = 'PENDING';
      this.item.sub_class_id = 1;
    },
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    init() {
      this.getForemen();
      this.getAddresses();
      this.getSubClasses();
      this.getProjects();
      this.getLocations();
    },

    async get(id) {
      if (!id) return;
      this.itemLoading = true;
      const submitModel = {
        filters: {
          id,
          is_manual: 0,
        },
      };
      const res = await this.$api.job.read(submitModel);
      if (!res || res.length < 1 || !res[0]) {
        this.itemLoading = false;
        return;
      }
      const firstIndex = 0;
      this.item = res[firstIndex];
      this.itemLoading = false;
    },

    async getProjects() {
      this.loading = true;
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.projects = [];
        return;
      }
      this.projects = res.data;
      this.loading = false;
    },

    async getAddresses() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.item.inventory_class_id,
        },
      };
      const res = await this.$api.address.readData(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.addresses = res;
      // select first value if client has only one address
      if (!this.item.client_address_id && this.addresses.length === 1) {
        const firstIndex = 0;
        const firstItem = this.addresses[firstIndex];
        if (!firstItem) return;
        this.item.client_address_id = firstItem.id;
      }
    },

    async getForemen() {
      this.supervisors = await this.$api.user.getListForemen();
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    async submit() {
      if (!this.item) return false;
      const userId = this.$store.getters.user.id;
      const submitModel = {
        id: this.item.id,
        client_address_id: this.item.client_address_id,
        supervisor_id: this.item.supervisor_id,
        name: this.item.name,
        code: this.item.code,
        created_by: userId,
        inventory_class_id: this.item.inventory_class_id,
        is_manual: this.item.is_manual,
        sub_class_id: this.item.sub_class_id,
        reference: this.item.reference,
        project_id: this.item.project_id,
        location_id: this.item.location_id,
      };
      let res;
      if (this.item.id) {
        res = await this.$api.job.update(submitModel);
      } else {
        res = await this.$api.job.store(submitModel);
      }
      if (res === true) {
        this.$emit('success', this.item.code);
        return res;
      }

      this.$bus.$emit('showError', res);
      return false;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.init();
      this.$refs.dialogBox.open();
    },

    openClientAddressForm() {
      const defaultClientId = 1000;
      this.$refs.clientAddressForm.open(defaultClientId);
    },

    async successAddress() {
      await this.getAddresses();
      const latestItemId = Math.max(...this.addresses.map((x) => x.id));
      if (latestItemId) { this.item.client_address_id = latestItemId; }
    },

    async getSubClasses() {
      this.subClasses = await this.$api.jobSubClass.get();
    },
  },
};
</script>
