<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Move'"
    :max-width="700"
  >
    <template slot="header">
      <span v-if="item">Tag Id - {{ item.tag_id }}</span>
    </template>
    <template slot="body">
      <v-row v-if="item">
        <v-col>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Job</span>
            </v-col>
            <v-col>
              <span style="font-weight: bold; font-size: 18px;">{{ item.job_code ? `${item.job_code}` : '' }}</span> - <span style="font-size: 18px;">{{ item.job_name ? item.job_name : item.job_name }}</span> <span style="color: #1976D2; font-size: 18px;">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Cut Width</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.cut_width }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Cut Height</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.cut_height }}</span>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="auto">
              <span style="font-size: 18px;">Move to Status</span>
            </v-col>
            <v-col>
              <v-radio-group
                v-model="selectedStatusId"
                hide-details
                dense
              >
                <v-radio
                  v-for="status in statuses"
                  :key="status.id"
                  :value="status.id"
                  class="pb-3"
                >
                  <template slot="label">
                    <v-chip
                      class="mt-1 mb-1"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="status ? status.color : 'white'"
                    >
                      {{ status ? status.name : '' }}
                    </v-chip>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="selectedStatusId === statusIdFinished">
        <v-col
          class="pb-0"
          cols="6"
        >
          <v-autocomplete
            v-model="locationId"
            :items="locations"
            item-text="name"
            item-value="id"
            outlined
            :disabled="true"
          >
            <template slot="label">
              Location<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="pb-0"
          cols="6"
        >
          <v-autocomplete
            v-model="inventoryLocationId"
            :items="inventoryLocations"
            item-text="name"
            item-value="id"
            outlined
            placeholder="Select shelf"
            :rules="[(v) => !!v || 'Shelf is required']"
          >
            <template slot="label">
              Shelf<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="ml-1 mt-1 mb-1"
            color="orange"
            outlined
            small
            @click="runWebhook(item)"
          >
            WINDOW QR CODE
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    statusIdMaterialAvailable: 2,
    statusIdCutting: 3,
    statusIdAssembling: 4,
    statusIdTandB: 5,
    statusIdGlazing: 6,
    statusIdFinished: 8,
    statatusIdShipping: 9,
    selectedStatusId: null,
    locationId: null,
    inventoryLocationId: null,
    statuses: [],
    inventoryLocations: [],
    locations: [],

  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.getStatuses();
  },
  methods: {

    async get(qrCode) {
      this.item = null;
      this.$bus.$emit('loading');
      const submitModel = {
        filters: {
          tag_id: qrCode,
          is_job: 1,
        },
      };
      const res = await this.$api.finishedGood.read(submitModel);

      if (!res || res.length === 0) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'Window not found');
        this.$refs.dialogBox.close();
        return;
      }
      const firstIndex = 0;
      this.item = res[firstIndex];
      this.selectedStatusId = this.item.status_id;
      this.getProdSchedule(this.item.production_schedule_id);
      this.$bus.$emit('loading');
    },

    async getStatuses() {
      const res = await this.$api.finishedGoodStatus.get();
      this.statuses = res.filter((x) => x.id === this.statusIdMaterialAvailable || x.id === this.statusIdCutting || x.id === this.statusIdAssembling || x.id === this.statusIdTandB || x.id === this.statusIdGlazing || x.id === this.statusIdFinished);
    },

    async submit() {
      const submitModel = {
        tag_id: this.item.tag_id,
        status_id: this.selectedStatusId,
        location_id: this.locationId,
        inventory_location_id: this.inventoryLocationId,
      };
      const res = await this.$api.finishedGood.store(submitModel, 'change-status');

      if (res.status === 510) {
        this.$bus.$emit('showError', res.message);
        return false;
      }

      if (!res) {
        this.$bus.$emit('showError', res);
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    async getInventoryLocations() {
      const readModel = {
        filters: {
          finished_good_status_id: this.statusIdFinished,
          location_id: this.locationId,
        },
      };
      this.inventoryLocations = await this.$api.inventoryLocation.read(readModel);
    },

    async getProdSchedule(prodScheduleId) {
      const submitModel = {
        filters: {
          id: prodScheduleId,
        },
      };
      const res = await this.$api.productionSchedule.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.items = [];
        this.allItems = [];
        this.totalItems = 0;
      }
      this.locationId = res.data[0].location_id;
      this.getInventoryLocations();
    },

    open(qrCode) {
      if (!qrCode) return;
      this.get(qrCode);
      this.getLocations();
      this.$refs.dialogBox.open();
    },

    async runWebhook(item) {
      this.$bus.$emit('loading');

      const webhookModuleIdWindowLabels = 52;
      const webhookData = {
        module_id: webhookModuleIdWindowLabels,
        tag_id: item.tag_id,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const label = item.tag_id.replace(/[\s.]/g, '-');
      const fileName = `${label}_Window_Label_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },
  },
};
</script>
