<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        v-if="isHrManager || isHr || isAccountant || isTimeOffSupervisor"
        cols="3"
      >
        <v-autocomplete
          v-model="filterUserId"
          label="Employee"
          :items="autoCompleteEmployees"
          hide-details="true"
          item-value="id"
          item-text="full_name"
          clearable
        />
      </v-col>
      <v-col
        cols="2"
      >
        <v-select
          v-model="filterLeaveTypeId"
          label="Leave Type"
          :items="leaveTypes"
          hide-details="true"
          item-value="id"
          item-text="name"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : ''"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="2"
      >
        <v-select
          v-model="filterStatusId"
          label="Status"
          :items="statuses"
          hide-details="true"
          item-value="id"
          item-text="name"
          clearable
          :disabled="disableFilterStatus"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : ''"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-spacer />
      <v-col
        v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdHr) || roles.includes(userRoleIdTimeOffUser) || roles.includes(userRoleIdTimeOffSupervisor)"
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          @click="openRequestForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Time Off Request
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          ref="parent"
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="timeOffs"
            :footer-props="itemsTableFooterProps"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            dense
            :loading="loading"
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @update:options="updateOptions"
          >
            <template #[`item.user_full_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.user_full_name ? item.user_full_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.user_employee_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.user_employee_number ? item.user_employee_number : '' }}
              </text-highlight>
            </template>
            <template #[`item.time_period`]="{ item }">
              <text-highlight :queries="search">
                {{ renderTimePeriod(item) }}
              </text-highlight>
            </template>
            <template #[`item.days_requested`]="{ item }">
              <text-highlight :queries="search">
                {{ item.days_requested ? item.days_requested : '' }}
              </text-highlight>
            </template>
            <template #[`item.leave_type`]="{ item }">
              <v-chip
                small
                :color="item && item.leave_type && item.leave_type.color ? item.leave_type.color : ''"
                text-color="white"
              >
                <text-highlight :queries="search">
                  {{ item && item.leave_type && item.leave_type.name ? item.leave_type.name : '' }}
                </text-highlight>
              </v-chip>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                small
                :color="item && item.status && item.status.color ? item.status.color : ''"
                text-color="white"
              >
                <text-highlight :queries="search">
                  {{ item && item.status && item.status.name ? item.status.name : '' }}
                </text-highlight>
              </v-chip>
            </template>
            <template #[`item.submitted_at_short`]="{ item }">
              <text-highlight :queries="search">
                {{ item.submitted_at_short ? item.submitted_at_short : '' }}
              </text-highlight>
            </template>
            <template #[`item.approved_at_short`]="{ item }">
              <text-highlight :queries="search">
                {{ item.approved_at_short ? item.approved_at_short : '' }}
              </text-highlight>
            </template>
            <template #[`item.approved_by_full_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.approved_by_full_name ? item.approved_by_full_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="warning"
                outlined
                small
                @click="openNoteForm(item)"
              >
                NOTES
              </v-btn>
              <v-btn
                v-if="renderApproveButton(item)"
                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="approveTimeOffForm(item)"
              >
                APPROVE/DENY
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="viewTimeOffForm(item)"
              >
                VIEW
              </v-btn>
              <v-btn
                v-if="renderCancelButton(item)"
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="cancelTimeOffRequest(item)"
              >
                CANCEL
              </v-btn>
              <!-- <v-btn
                v-if="renderDeleteButton(item)"
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteTimeOffRequest(item)"
              >
                DELETE
              </v-btn> -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <time-off-request-form
      ref="timeOffRequestForm"
      :view-form="viewForm"
      :approve-form="approveForm"
      @close-form="closeForm"
      @success="get"
    />
    <note-form ref="noteForm" />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TimeOffRequestForm from '@/components/forms/timeOffs/TimeOffRequestForm';
import PrimeDataTable from '@/components/PrimeDataTable/PrimeDataTable';
import NoteForm from '@/components/forms/notes/NoteForm';

export default {
  components: {
    TimeOffRequestForm,
    PrimeDataTable,
    NoteForm,
  },
  props: {

  },
  data: () => ({
    timeOffs: [],
    roles: [],
    users: [],
    leaveTypes: [],
    statuses: [],
    supervisees: [],

    timeOffUserOnly: false,
    userRoleIdAdmin: 3,
    userRoleIdHr: 25,
    userRoleIdTimeOffUser: 27,
    userRoleIdTimeOffSupervisor: 28,
    userRoleIdHrManager: 29,
    userRoleIdTimeOffAccountant: 30,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    topSpaceHeight: 235,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 50,
      page: 1,
      sortBy: 'time_off_requests.id',
      sortDesc: true,
    },
    total: 0,
    viewForm: false,
    approveForm: false,
    statusIdNew: 1,
    statusIdSubmitted: 2,
    statusIdApproved: 3,
    statusIdDenied: 4,
    statusIdCanceled: 5,
    statusIdCancelApproval: 6,
    statusIdReadyForSupervisor: 7,
    statusIdReadyForHr: 8,
    leaveTypeIdUnpaid: 1,
    leaveTypeIdPaid: 2,
    leaveTypeIdBereavement: 3,
    loading: true,
    filterUserId: null,
    filterLeaveTypeId: null,
    filterStatusId: null,
    disableFilterStatus: false,
    noteTypeIdTimeOff: 2,
    autoCompleteEmployees: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
      search: (state) => state.search,
      timeOffState: (state) => state.timeOffState,
    }),
    ...mapGetters([

    ]),
    headers() {
      const headers = [];

      headers.push({
        text: 'Employee',
        value: 'user_full_name',
        sortable: true,
      });

      if (this.isHr || this.isTimeOffSupervisor) {
        headers.push({
          text: 'Employee #',
          value: 'user_employee_number',
          sortable: false,
        });
      }

      if (this.isAccountant) {
        headers.push({
          text: 'Requested On',
          value: 'created_at_short',
          sortable: false,
        });
      }

      headers.push({
        text: 'Start Date',
        value: 'start_date',
        sortable: false,
      })

      headers.push({
        text: 'Back to Work Date',
        value: 'end_date',
        sortable: false,
      })

      headers.push({
        text: 'Time Period',
        value: 'time_period',
        sortable: false,
      })

      headers.push({
        text: 'Days Requested',
        value: 'days_requested',
        align: 'center',
        sortable: false,
      });


      headers.push({
        text: 'Type of Leave',
        value: 'leave_type',
        align: 'center',
        sortable: true,
        // sort: this.chipSort,
      });

      headers.push({
        text: 'Status',
        value: 'status',
        align: 'center',
        sortable: true,
        // sort: this.chipSort,
      });

      headers.push({
        text: 'Submitted On',
        value: 'submitted_at_short',
        sortable: false,
      });

      if (!this.isAccountant) {
        headers.push({
          text: 'Approved On',
          value: 'approved_at_short',
          sortable: false,
        });
      }

      if (!this.isAccountant) {
        headers.push({
          text: 'Approved By',
          value: 'approved_by_full_name',
          sortable: false,
        });
      }

      headers.push({
        text: 'Actions', value: 'actions', width: '25%', align: 'end', sortable: false,
      });

      return headers;
    },

    isHr() {
      return this.roles.includes(this.userRoleIdHr);
    },

    isAccountant() {
      return this.roles.includes(this.userRoleIdTimeOffAccountant);
    },

    isTimeOffSupervisor() {
      return this.roles.includes(this.userRoleIdTimeOffSupervisor);
    },

    isTimeOffUser() {
      return this.roles.includes(this.userRoleIdTimeOffUser);
    },

    isHrManager() {
      return this.roles.includes(this.userRoleIdHrManager);
    },

  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
    async filterUserId(value) {
      this.storeState('filterUserId', value);
      await this.get();
    },
    filterLeaveTypeId(value) {
      this.storeState('filterLeaveTypeId', value);
      this.get();
    },
    filterStatusId(value) {
      this.storeState('filterStatusId', value);
      this.get();
    },
  },
  async created() {
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
    if (this.isTimeOffSupervisor) {
      const supervisees = this.user.supervisees.map((x) => x.id);
      supervisees.push(this.user.id);
      this.timeOffSupervisorUsers = supervisees;
    }
    await this.init();
    if (this.timeOffState) {
      if (this.timeOffState.filterUserId &&
        (
          this.roles.includes(this.userRoleIdTimeOffSupervisor) ||
          this.roles.includes(this.userRoleIdTimeOffAccountant) ||
          this.roles.includes(this.userRoleIdHr) ||
          this.roles.includes(this.userRoleIdHrManager))
        )
      {
        this.filterUserId = this.timeOffState.filterUserId;
      } else {
        this.filterUserId = null;
      }
      if (this.timeOffState.filterLeaveTypeId) {
        this.filterLeaveTypeId = this.timeOffState.filterLeaveTypeId;
      }
      if (this.timeOffState.filterStatusId) {
        this.filterStatusId = this.timeOffState.filterStatusId;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {

    async init() {
      await this.get();
      this.getLeaveTypes();
      this.getStatuses();
      await this.getUsers();
      this.autoCompleteEmployees = await this.getAutoCompleteEmployees();
    },

    async getAutoCompleteEmployees() {
      if (this.isHr || this.isAccountant || this.isHrManager) {
        return this.users;
      }
      if (this.isTimeOffSupervisor) {
        const users = this.users.filter((user) => this.timeOffSupervisorUsers.includes(user.id));
        return users;
      }
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;

      const submitModel = {
        filters: {
          user_id: this.filterUserId,
          leave_type_id: this.filterLeaveTypeId,
          status_id: this.filterStatusId,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.timeOffRequest.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.$bus.$emit('showError');
        this.timeOffs = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      this.timeOffs = res.data;
      this.total = res.total;
      this.loading = false;
    },

    async getUsers() {
      const submitModel = {
        filters: {
          is_username: false,
        },
      };
      const res = await this.$api.user.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (res.length <= 0) {
        this.employees = [];
        return;
      }
      this.users = res;
    },

    async getLeaveTypes() {
      this.leaveTypes = await this.$api.timeOffLeaveType.get();
    },

    async getStatuses() {
      this.statuses = await this.$api.timeOffStatus.get();
    },

    openRequestForm() {
      this.viewForm = false;
      this.approveForm = false;
      this.$refs.timeOffRequestForm.open();
    },

    openEditForm(item) {
      this.$refs.timeOffRequestForm.open(item.id);
    },

    viewTimeOffForm(item) {
      this.viewForm = true;
      this.$refs.timeOffRequestForm.open(item.id);
    },

    approveTimeOffForm(item) {
      this.approveForm = true;
      this.$refs.timeOffRequestForm.open(item.id);
    },

    closeForm() {
      this.viewForm = false;
      this.approveForm = false;
    },

    // async deleteTimeOffRequest(data) {
    //   const confirm = await this.$refs.confirmForm.open({
    //     title: 'Are you sure that you want to delete this record?',
    //   });
    //   if (!confirm) return;
    //   const res = await this.$api.timeOffRequest.softDelete(data.id);
    //   if (!res) {
    //     this.$bus.$emit('showError');
    //     return;
    //   }
    //   this.$bus.$emit('showSuccess');
    //   this.get();
    // },

    async submitTimeOffForm(data) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to submit this record to an Accountant for approval?',
      });
      if (!confirm) return;
      const res = await this.$api.timeOffRequest.get(`submit-for-approval/${data.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    openNoteForm(data) {
      this.$refs.noteForm.open(data.id, this.noteTypeIdTimeOff);
    },

    async cancelTimeOffRequest(data) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to cancel this record? Unapproved records will be deleted.`,
      });
      if (!confirm) return;
      const res = await this.$api.timeOffRequest.get(`cancel/${data.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    removeNumbersAfterDecimal(num) {
    // Convert number to string and split by decimal point
      const parts = num.toString().split('.');
      // Check if the decimal part is "0"
      if (parts[1] === '0') {
        // Return only the integer part
        return parts[0];
      }
      // Return the original number as it is
      return num.toString();
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
    },

    storeState(property, value) {
      const state = this.timeOffState;
      state[property] = value;
      this.$store.commit('storeTimeOffState', state);
    },

    renderApproveButton(timeOffRequest) {
      if (timeOffRequest.user.id === this.user.id) {
        //don't render if it's their own time off request unless you're admin
        return false
      }
      // if (this.isHr && timeOffRequest.user.roles.some(role => role.id === this.userRoleIdHr) && timeOffRequest.user.roles.some(role => role.id === this.userRoleIdTimeOffSupervisor)) {
      if (this.isHr && (timeOffRequest.user.roles.some(role => role.id === this.userRoleIdHrManager) || timeOffRequest.user.roles.some(role => role.id === this.userRoleIdHr))) {
        //because regular HR has all access, we need to restrict them from approving/denying Super HR's requests
        //currently we're checking if we're HR and the time off request is from the Super HR
        //another way can be checking if the current logged in user has the same ID as the requester's supervior id
        //but we'd need to grab additional information from each time off request which takes time and space
        return false;
      }
      return (this.isTimeOffSupervisor || this.isHr || this.isHrManager) && (timeOffRequest.status_id === this.statusIdSubmitted || timeOffRequest.status_id === this.statusIdReadyForSupervisor || timeOffRequest.status_id === this.statusIdReadyForHr || timeOffRequest.status_id === this.statusIdCancelApproval)
    },

    renderSubmitButton(timeOffRequest) {
      return (this.isHr || this.isTimeOffUser) && (timeOffRequest.status_id === this.statusIdNew || timeOffRequest.status_id === this.statusIdDenied);
    },

    renderCancelButton(timeOffRequest) {
      if (this.isAccountant) {
        return false;
      }
      if (timeOffRequest.user.id === this.user.id) {
        return timeOffRequest.status_id === this.statusIdReadyForSupervisor || timeOffRequest.status_id === this.statusIdReadyForHr || timeOffRequest.status_id === this.statusIdApproved ;
      }
      return false;
    },

    // renderDeleteButton(timeOffRequest) {
    //   if (timeOffRequest.user.id === this.user.id) {
    //     //don't render if it's their own time off request unless you're admin
    //     return false
    //   }
    //   if (this.isHr && (timeOffRequest.user.roles.some(role => role.id === this.userRoleIdHrManager) || timeOffRequest.user.roles.some(role => role.id === this.userRoleIdHr))) {
    //     return false
    //   }
    //   return this.isHr || this.isHrManager;
    // },

    // renderPeriod(item) {
    //   if (!item.start_date && !item.end_date) return '';
    //   if (item.start_date === item.end_date && item.start_time && item.end_time) {
    //     return `Date: ${item.start_date} - Start Time: ${item.start_time} - End Time: ${item.end_time}`
    //   } else {
    //     return `Start Date: ${item.start_date} - Back to Work Date: ${item.end_date}`
    //   }
    // },

    renderTimePeriod(item) {
      if (!item.start_time && !item.end_time) return '-';
      return `Start Time: ${item.start_time} - End Time: ${item.end_time}`
    },

    // chipSort(a, b) {
    //   return a.name.localeCompare(b.name)
    // },

    updateOptions(newOptions) {
      this.pagination = newOptions;
    },

  },
};
</script>

<style scoped>

</style>
